<template>
  <div class="homeBg">
    <form
      @submit.prevent="login"
      @keyup.enter="handleKeyPress"
      class="login-form text-center"
    >
      <div class="form-group">
        <label for="username">USERNAME</label>
        <input type="text" autocomplete="username" v-model="username" required />
      </div>

      <div class="form-group">
        <label for="password">PASSWORD</label>
        <input type="password" autocomplete="current-password" v-model="password" required />
      </div>

      <b-button v-if="!busy" variant="outline-white" @click="login">
        LOGIN
      </b-button>
      <b-spinner v-else variant="light" />
      <p v-if="loginMessage" class="text-danger text-center mt-2">
        {{ loginMessage }}
      </p>
    </form>
    <div class="logo">
      <b-img class="w-100" src="/logo.png" />
    </div>
  </div>
</template>

<script>
import { BImg, BButton, BSpinner } from 'bootstrap-vue';
import { mapState } from 'vuex';

export default {
  name: 'LoginPage',
  components: { BImg, BButton, BSpinner },
  data() {
    return {
      username: process.env.VUE_APP_USERNAME || '',
      password: process.env.VUE_APP_PASSWORD || '',
    };
  },
  computed: {
    ...mapState(['authentication']),

    loginMessage () {
      return this.authentication.error;
    },

    busy () {
      return this.authentication.status == 'connecting';
    },
  },
  mounted () {
    if (this.authentication.status == 'online') {
      this.redirect();
    }
  },
  watch: {
    'authentication.status' (value, oldValue) {
      if (value == 'online') {
        this.redirect();
      } else if (value == 'offline') {
        if (oldValue == 'connecting') {
          // this.loginMessage = 'Connection lost during authentication';
        }
      }
    },
  },
  methods: {
    login() {
      if (this.busy) {
        return;
      }

      this.$auth.login(this.username, this.password);
    },

    handleKeyPress() {
      this.login();
    },

    redirect () {
      if (this.$route.query.redirectTo) {
        this.$router.push({
          path: this.$route.query.redirectTo,
          query: this.$route.query.query ? JSON.parse(this.$route.query.query) : undefined,
        });
      } else {
        this.$router.push({ name: 'site-overview' });
      }
    },
  },
};
</script>

<style scoped>
.login-form {
  left: 15%;
  top: 20%;
  position: absolute;
}

.form-group {
  display: flex;
  margin-bottom: 15px;
  align-items: baseline;
}

label {
  color: var(--white);
  width: 150px;
}

input {
  width: 100%;
  background-color: var(--dark-grey);
  border: none;
  border-radius: 6px;
  color: var(--light-grey);
  padding: 8px;

  &:focus {
    outline: none;
  }
}

.logo {
  left: 50%;
  top: 60%;
  width: 40%;
  position: absolute;
  filter: drop-shadow(-15px -15px 3px rgb(0, 0, 0));
}

.homeBg {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    150deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 24%,
    rgba(38, 38, 78, 1) 100%
  );
}
</style>
