<template>
  <Plotly
    :data="graphData"
    :layout="graphLayout"
  ></Plotly>
</template>

<script>
import { Plotly } from 'vue-plotly';
import { getXYValues, populateXYDefaultValues } from '@/helper/utils';

export default {
  name: 'HeatmapGraph',

  components: {
    Plotly,
  },

  props: { apiData: Array, smoothingRadius: Number, layoutData: Object },

  data() {
    return {
      heatmapData: {
        name: 'Heatmap',
        z: [],
        x: [],
        y: [],
        type: 'heatmap',
        // zsmooth: 'best',
        colorscale: [
          [0.0, '#000000'],
          [0.15, '#1C4D61'],
          [0.25, '#009900'],
          [0.4, '#BCBC00'],
          [0.6, '#CD0000'],
          [1.0, '#800080'],
        ],
      },
      graphLayout: {
        xaxis: {
          tickvals: [],
          zeroline: false,
        },
        yaxis: {
          tickvals: [],
          autorange: 'reversed',
          scaleanchor: 'x',
          scaleratio: 1,
          zeroline: false,
        },

        showlegend: false,

        margin: {
          t: 30,
          l: 0,
          r: 0,
          b: 0,
        },

        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
      },
    };
  },

  computed: {
    layoutDimensions () {
      const layout = this.layoutData;
      if (!layout) {
        return { width: 40, height: 40 };
      }
      let walls = layout.entities.filter(e => e.type == 'wall');
      if (walls && walls.length) {
        let maxX=0, maxY=0;
        for (let wall of walls) {
          let pos = wall.position.split(',');
          let x = Math.abs(+pos[0]);
          let y = Math.abs(+pos[1]);
          if (x > maxX) maxX = x;
          if (y > maxY) maxY = y;
        }
        return {
          width: 2 * maxX + 1,
          height: 2 * maxY + 1,
        };
      } else {
        return {width: +layout.grid.width, height: +layout.grid.length};
      }
    },

    xTicks() {
      return Math.round(this.layoutDimensions.width / this.pixelSize);
    },
    yTicks() {
      return Math.round(this.layoutDimensions.height / this.pixelSize);
    },

    pixelSize() {
      return this.smoothingRadius > 0 ? 1 / (this.smoothingRadius + 7) : 0.5;
    },

    xValues() {
      return populateXYDefaultValues(-this.xTicks / 2, this.xTicks / 2 - 1, 1);
    },

    yValues() {
      return populateXYDefaultValues(-this.yTicks / 2, this.yTicks / 2 - 1, 1);
    },

    zValues() {
      return Array(this.yTicks)
        .fill()
        .map(() => Array(this.xTicks).fill(0));
    },

    xyPairs() {
      let points = [];
      for (let eventType of this.apiData) {
        points = points.concat(eventType.points);
      }
      const xRealValues = getXYValues(points, 'x');
      const yRealValues = getXYValues(points, 'y');

      return xRealValues.map((x, index) => [
        parseInt(x / this.pixelSize),
        parseInt(yRealValues[index] / this.pixelSize),
      ]);
    },

    graphData() {
      const entities = !this.layoutData ? [] : this.layoutData.entities.map(e => {
        return {
          id: e.id,
          type: e.type,
          position: JSON.parse(`[${e.position}]`),
          rotation: JSON.parse(`[${e.rotation}]`),
        };
      });

      let walls = entities.filter(e => e.type == 'wall');
      if (walls.length > 0) {
        walls.push(walls[0]); // Close the loop
      }

      let chairs = entities.filter(e => e.type.indexOf('chair') >= 0);
      // let chairs = entities.filter(e => e.type.indexOf('chair') >= 0);

      let wallData = {
        name: 'Walls',
        x: walls.map(e => e.position[0] / this.pixelSize),
        y: walls.map(e => e.position[1] / this.pixelSize),
        type: 'scatter',
        mode: 'lines',
        line: {
          color: '#CDCDCD',
          width: 1,
        },
        hoverinfo: 'none',
      };

      let data = [
        this.heatmapData,
        wallData,
      ];

      for (let chair of chairs) {
        const size = 0.35;
        let x1 = (chair.position[0] - size) / this.pixelSize;
        let x2 = (chair.position[0] + size) / this.pixelSize;
        let y1 = (chair.position[1] - size) / this.pixelSize;
        let y2 = (chair.position[1] + size) / this.pixelSize;
        data.push({
          name: chair.name,
          x: [x1, x2, x2, x1, x1],
          y: [y1, y1, y2, y2, y1],
          type: 'scatter',
          mode: 'lines',
          line: {
            color: '#CDCDCD',
            width: 1,
          },
          hoverinfo: 'none',
        });
      }

      return data;
    },
  },

  watch: {
    smoothingRadius: 'applySmoothing',
  },

  mounted() {
    this.refreshHeatmap();
    this.applySmoothing();
  },

  methods: {
    refreshHeatmap() {
      const zCountValues = this.countZValues(this.zValues, this.xyPairs);
      this.$set(this.heatmapData, 'x', this.xValues);
      this.$set(this.heatmapData, 'y', this.yValues);
      this.$set(this.heatmapData, 'z', zCountValues);
    },
    countZValues(zValues, xyPairs) {
      xyPairs.forEach(([x, y]) => {
        const xPosition = x + Math.floor(zValues[0].length / 2);
        const yPosition = y + Math.floor(zValues.length / 2);

        if (
          xPosition >= 0 &&
          xPosition < zValues[0].length &&
          yPosition >= 0 &&
          yPosition < zValues.length
        ) {
          zValues[yPosition][xPosition]++;
        }
      });

      return zValues;
    },
    applySmoothing() {
      const samplingLevel = this.smoothingRadius;
      const grid = this.countZValues(this.zValues, this.xyPairs);

      this.$set(this.heatmapData, 'x', this.xValues);
      this.$set(this.heatmapData, 'y', this.yValues);

      if (samplingLevel <= 0) {
        this.$set(this.heatmapData, 'z', grid);
        return;
      }

      const y_divisions = grid.length;
      const x_divisions = grid[0].length;

      const sampleGrid = Array(y_divisions)
        .fill(0)
        .map(() => Array(x_divisions).fill(0));

      for (let i = 0; i < grid.length; i++) {
        for (let j = 0; j < grid[i].length; j++) {
          // if (grid[i][j] !== 0) {
          let sum = 0;
          let sampleValueCount = 0;
          for (let m = -samplingLevel; m <= samplingLevel; m++) {
            for (let n = -samplingLevel; n <= samplingLevel; n++) {
              if (
                Math.abs(m) + Math.abs(n) <= samplingLevel &&
                i + m >= 0 &&
                i + m < grid.length &&
                j + n >= 0 &&
                j + n < grid[i + m].length
              ) {
                sum += grid[i + m][j + n];
                sampleValueCount++;
              }
            }
            // }
            sampleGrid[i][j] = sum / sampleValueCount;
          }
        }
      }
      this.$set(this.heatmapData, 'z', sampleGrid);
    },
  },
};
</script>
