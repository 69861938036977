<template>
  <div v-if="apiData && apiData.content" class="pie-chart m-4">
    <Pie :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);
import { Parser } from 'expr-eval';
import { Pie } from 'vue-chartjs';

export default {
  name: 'DashboardPieChart',
  components: {
    Pie,
  },
  props: {
    values: Object,
    colors: Array,
    apiData: Object,
    showPercentage: Boolean,
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            labels: {
              usePointStyle: true,
              color: '#c3c1c1',
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const value = context.raw || 0;
                if (this.showPercentage) {
                  return `${value}%`;
                } else {
                  return value;
                }
              },
            },
          },
        },
      },
    };
  },

  methods: {
    formatPercentage(value) {
      return (value * 100).toFixed(2);
    },

    evaluateExpression(value) {
      if (value.startsWith('#') && value.endsWith('#')) {
        const expression = value.slice(1, -1);
        const parser = new Parser();

        let result;
        try {
          result = parser.evaluate(expression, this.apiData.content);
        } catch (error) {
          return '-';
        }

        if (this.showPercentage) {
          return this.formatPercentage(result);
        } else {
          return result;
        }
      }
      return value;
    },

    generateChartData() {
      if (!this.apiData || !this.apiData.content) {
        return;
      }

      const allLabels = Object.keys(this.values);
      const allData = allLabels.map((label) =>
        this.evaluateExpression(this.values[label]),
      );

      const filteredLabels = [];
      const filteredData = [];
      allData.forEach((dataPoint, index) => {
        if (dataPoint > 0) {
          filteredLabels.push(allLabels[index]);
          filteredData.push(dataPoint);
        }
      });

      this.chartData = {
        labels: filteredLabels,
        datasets: [
          {
            data: filteredData,
            backgroundColor: this.colors,
            borderWidth: 0,
          },
        ],
      };
    },
  },

  watch: {
    apiData: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.content) {
          this.generateChartData();
        }
      },
    },
  },
};
</script>

<style scoped>
.pie-chart {
  max-width: 280px;
}
</style>
