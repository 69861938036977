import Vue from 'vue';
import VueRouter from 'vue-router';

// See https://v3.router.vuejs.org/guide/ for documentation

Vue.use(VueRouter);

import LoginPage from './pages/login/LoginPage.vue';
import SiteOverview from './pages/login/SitePickerPage.vue';
import SystemMetricsPage from './pages/site/SystemMetricsPage.vue';
import DashboardPage from './pages/site/DashboardPage.vue';
import MonitoringPage from './pages/site/MonitoringPage.vue';
import AdminPage from './pages/site/AdminPage.vue';
import EventLogPage from './pages/site/EventLogPage.vue';
import InventoryPage from './pages/site/InventoryPage.vue';
import OneMetric from './pages/details/SystemMetricDetails.vue';
import OneDevice from './pages/details/DeviceDetails.vue';
import OneDeviceSession from './pages/details/DeviceSessionDetails.vue';
import OneUser from './pages/details/UserDetails.vue';
import SitePageContainer from './pages/SitePageContainer.vue';
import SettingsPage from './pages/login/SettingsPage.vue';

// try to solve error 'Avoided redundant navigation to current location' with below codes
// https://stackoverflow.com/a/64480426
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

let router = new VueRouter({
  routes: [
    {
      path: '/login',
      component: LoginPage,
      name: 'login',
    },
    {
      path: '/settings',
      component: SettingsPage,
      name: 'settings',
    },
    {
      path: '/',
      component: SiteOverview,
      name: 'site-overview',
    },
    {
      path: '/:projectName/:siteName',
      component: SitePageContainer,
      children: [
        {
          path: 'dashboard',
          component: DashboardPage,
          name: 'dashboard',
        },
        {
          path: 'system-metrics',
          component: SystemMetricsPage,
          name: 'system-metrics',
        },
        {
          path: 'system-metrics/:metricName',
          component: OneMetric,
          name: 'one-metric',
        },
        {
          path: 'monitoring',
          component: MonitoringPage,
          name: 'monitoring',
        },
        {
          path: 'monitoring/:sessionId',
          component: MonitoringPage,
          name: 'monitoring-session',
        },
        {
          path: 'monitoring/:sessionId/:userId',
          component: OneUser,
          name: 'one-user',
        },
        {
          path: 'inventory',
          component: InventoryPage,
          name: 'inventory',
        },
        {
          path: 'inventory/:deviceId',
          component: OneDevice,
          name: 'one-device',
        },
        {
          path: 'inventory/:deviceId/session/:sessionId',
          component: OneDeviceSession,
          name: 'one-device-session',
        },
        {
          path: 'event-log',
          component: EventLogPage,
          name: 'event-log',
        },
        {
          path: 'admin',
          component: AdminPage,
          name: 'admin',
        },
      ],
    },
  ],
  mode: 'history',
});

export default router;
