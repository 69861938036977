import Vue from 'vue';
import App from './App.vue';
import store from './plugins/store.js';

import './plugins/synapse.js';
import './plugins/bootstrap-vue.js';
import './style/theme.scss';
import './style/fonts.scss';
import './style/vue-select.scss'

import router from './router.js';

new Vue({
  render: (h) => h(App),
  store,
  router: router,
}).$mount('#app');
