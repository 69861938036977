<template>
  <div class="w-100 h-100 p-3 d-flex flex-column">
    <header>
      <div class="header-container">
        <h4 class="heading">
          <router-link :to="{ name: 'monitoring-session' }">
            &lt; Spectator {{ experienceLocalId }}
          </router-link>
        </h4>
      </div>
    </header>

    <b-spinner
      v-if="fetchingData"
      variant="light"
      class="mx-auto mt-5"
      style="width: 10rem; height: 10rem"
    />

    <div
      v-else-if="experienceData != null && firstSession && lastSession"
      class="flex-grow-1 mt-3"
    >
      <ExperienceTimeline
        v-model="currentTime"
        :startDate="new Date(firstSession.startDate)"
        :endDate="new Date(lastSession.endDate)"
        :timeZone="timeZone"
        :events="importantEvents"
        class="experience-timeline"
      />

      <section class="d-flex justify-content-around mt-3">
        <div class="flex-grow-2" style="min-width: 380px; height: 600px">
          <span class="text-subheader">Data for this client</span>
          <table class="details-table">
            <tr>
              <th>Attendance Date</th>
              <td>
                {{
                  new Date(firstSession.startDate).toLocaleDateString('en-US', {
                    timeZone,
                  })
                }}
              </td>
            </tr>
            <tr>
              <th>Start time</th>
              <td>
                {{
                  new Date(firstSession.startDate).toLocaleTimeString('en-US', {
                    timeZone,
                  })
                }}
              </td>
            </tr>
            <tr>
              <th>Exit time</th>
              <td>
                {{
                  new Date(lastSession.endDate).toLocaleTimeString('en-US', {
                    timeZone,
                  })
                }}
              </td>
            </tr>
            <tr>
              <th>Reason for exit</th>
              <td>{{ lastSession.endReason }}</td>
            </tr>
            <tr>
              <th>Group(s) joined</th>
              <td>{{ groups.toString() }}</td>
            </tr>
            <tr>
              <th>Device(s) used</th>
              <td class="d-flex">
                <div
                  v-for="session in experienceData.user.sessions"
                  :key="session.device.localId"
                  class="clickable"
                  @click="clickOneDeviceSession(session)"
                >
                  {{ session.device.name }}
                </div>
              </td>
            </tr>
          </table>

          <CommentInput
            class="mt-3"
            :notesHeader="notesHeader"
            :resourceId="experienceId"
            resourceType="experience"
          />
        </div>

        <MapView
          v-if="experienceData.layout != null"
          ref="map"
          :layoutData="experienceData.layout"
          displayMode="fit"
          class="flex-grow-1 map ml-2"
          style="max-height: 594px"
          :anchorY="0"
          :dataRate="10"
          @ready="refreshMapData"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue';
import ExperienceTimeline from '@/components/monitoring/ExperienceTimeline.vue';
import MapView from '@/components/monitoring/MapView.vue';
import CommentInput from '@/components/CommentInput.vue';

export default {
  name: 'UserDetails',
  components: {
    ExperienceTimeline,
    MapView,
    BSpinner,
    CommentInput,
  },
  data() {
    return {
      serverSessionLocalId: this.$route.params.sessionId,
      experienceLocalId: this.$route.params.userId,
      fetchingData: false,
      experienceData: null,
      importantEvents: [],
      currentTime: null,
      notesHeader: 'Notes (visible to others):',
      // Events with a lower severity rating than this will not be displayed
      minimumEventSeverity: 1,
    };
  },

  mounted() {
    this.fetchingData = true;
    this.fetchExperienceData();
  },

  methods: {
    async fetchExperienceData() {
      this.experienceData = null;
      this.importantEvents = [];
      this.fetchingData = true;
      return this.$ynapse
        .GET('/api/v1/experience', {
          siteId: this.$provider.selection.site.siteId,
          serverSessionLocalId: this.serverSessionLocalId,
          experienceLocalId: this.experienceLocalId,
        })
        .then((res) => {
          this.fetchingData = false;
          this.experienceData = res.data;
          this.collectImportantEvents();
        });
    },

    collectImportantEvents() {
      const eventTypes = this.$provider.cache.eventTypesMap;
      this.importantEvents = [];
      if (!this.experienceData) {
        return;
      }
      for (let ev of this.experienceData.systemEvents) {
        let typeInfo = eventTypes[ev.type];
        if (typeInfo.severity >= this.minimumEventSeverity) {
          this.importantEvents.push(
            Object.assign({}, ev, {
              type: typeInfo.name,
              severity: typeInfo.severity,
              source: typeInfo.source,
            }),
          );
        }
      }
      for (let session of this.experienceData.user.sessions) {
        for (let ev of session.events) {
          let typeInfo = eventTypes[ev.type];
          if (typeInfo.severity >= this.minimumEventSeverity) {
            this.importantEvents.push(
              Object.assign({}, ev, {
                user: this.experienceData.user.name,
                type: typeInfo.name,
                severity: typeInfo.severity,
                source: typeInfo.source,
              }),
            );
          }
        }
      }
      this.importantEvents.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      });
    },

    lerp(a, b, t) {
      return a + (b - a) * t;
    },

    getUserMapData(user, time) {
      if (this.userPath.length == 0 || time < this.userPath[0].time) {
        return null;
      }
      let obj = {
        id: user.name,
        color: '#22bbf2',
      };
      for (let i = 0; i < this.userPath.length - 1; i++) {
        let start = this.userPath[i];
        let end = this.userPath[i + 1];
        if (start.time <= time && time < end.time) {
          let t = (time - start.time) / (end.time - start.time);
          obj.x = this.lerp(start.x, end.x, t);
          obj.y = this.lerp(start.y, end.y, t);
          obj.angle = this.lerp(start.angle, end.angle, t);
          return obj;
        }
      }
      return null;
    },

    refreshMapData() {
      if (!this.$refs['map']) {
        // Not ready yet
        return;
      }
      let spectators = [];
      if (this.experienceData) {
        let obj = this.getUserMapData(
          this.experienceData.user,
          this.currentTime,
        );
        if (obj) {
          spectators.push(obj);
        }
      }
      this.$refs['map'].setSpectators(spectators);
      this.$refs['map'].setPathPoints(
        this.userPath.filter((p) => p.time <= this.currentTime),
        this.userPath.filter((p) => p.time > this.currentTime),
      );
    },

    clickOneDeviceSession(session) {
      this.$provider.selectDeviceSession(session.deviceSession[0]);

      this.$router.push({
        name: 'one-device-session',
        params: {
          deviceId: session.device.localId,
          sessionId: session.deviceSession[0].localId,
        },
      });
    },
  },

  watch: {
    currentTime: 'refreshMapData',
  },

  computed: {
    timeZone() {
      return this.$provider.selection.site.timeZone;
    },

    firstSession() {
      if (this.experienceData) {
        return this.experienceData.user.sessions[0];
      }
      return null;
    },

    lastSession() {
      if (this.experienceData) {
        return this.experienceData.user.sessions[
          this.experienceData.user.sessions.length - 1
        ];
      }
      return null;
    },

    devices() {
      let devices = [];
      if (this.experienceData) {
        for (let session of this.experienceData.user.sessions) {
          if (session.device) {
            devices.push(session.device);
          }
        }
      }
      return devices;
    },

    groups() {
      let groups = [];
      if (this.experienceData) {
        for (let session of this.experienceData.user.sessions) {
          if (session.group) {
            groups.push(session.group);
          }
        }
      }
      return groups;
    },

    userPath() {
      let path = [];
      for (let session of this.experienceData.user.sessions) {
        // TEMPORARY: Get event position, instead of path position
        for (let ev of session.events) {
          let startTime = new Date(ev.time).getTime();
          let startPos = JSON.parse(ev.details).position;
          let startRot = JSON.parse(ev.details).rotation;
          if (startPos && startRot) {
            path.push({
              time: startTime,
              x: +startPos[0],
              y: +startPos[1],
              angle: +startRot[1],
            });
          }
        }
      }
      return path;
    },

    experienceId() {
      return this.experienceData?.user.id ? this.experienceData?.user.id : null;
    },
  },
};
</script>

<style scoped>
.details-table {
  font-size: 12px;
  width: 100%;
}

.map {
  overflow: hidden;
}

.clickable {
  color: var(--link);
  text-decoration: underline;
  margin-right: 4px;

  &:hover {
    color: var(--blue);
    cursor: pointer;
  }
}

.experience-timeline {
  margin-right: 24px;
}
</style>
