<template>
  <div class="local-time">
    <div class="local-time-text">Local time</div>
    <synapse-timer type="clock" :timeZone="timeZone" />
  </div>
</template>

<script>
export default {
  name: 'LocalTime',
  props: {
    timeZone: String,
  },
};
</script>

<style scoped>
.local-time {
  font-size: 14px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.local-time-text {
  font-size: 11px;
  color: var(--secondary);
}
</style>
