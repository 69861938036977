<template>
  <div class="margin-right">
    <div v-if="userSessions">
      <header>
        <div class="subheading">
          <router-link :to="{ name: 'one-device' }">
            &lt;
            <span v-if="selectedDeviceData">
              {{ selectedDeviceData.type }}
              {{ selectedDeviceData.device.name }}
              ({{ deviceLocalId }})
            </span>
            <span v-else> Device {{ deviceLocalId }} </span>
          </router-link>
        </div>
        <div class="header-container">
          <h2 class="heading">{{ formattedDate }}</h2>
          <div v-if="userSessions.length > 0">
            {{ spectators.length > 1 ? 'Users:' : 'User' }}
            <router-link
              v-for="(spectator, index) in spectators"
              :key="spectator.name + index"
              :to="{
                name: 'one-user',
                params: {
                  sessionId: spectator.serverSession
                    ? spectator.serverSession.localId
                    : '',
                  userId: spectator.name,
                },
              }"
            >
              {{ spectator.name }}
            </router-link>
          </div>
        </div>
      </header>

      <MetricAndEventSelectInput
        class="w-100 px-3"
        :metricAndEventOptions="metricAndEventOptions"
        :metricAndEventPlaceholder="metricAndEventPlaceholder"
      />

      <DeviceChart
        v-if="selectedDeviceSession"
        class="px-5"
        :selectedMetricOrEvent="selectedMetricOrEvent"
        :selectedDeviceSession="selectedDeviceSession"
        :isLegendDisplayed="isLegendDisplayed"
        :isTooltipExternal="isTooltipExternal"
        :chartHeight="chartHeight"
      />
    </div>

    <div v-else class="loader">
      <b-spinner variant="light" style="width: 10rem; height: 10rem" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { BSpinner } from 'bootstrap-vue';

import MetricAndEventSelectInput from '@/components/device/MetricAndEventSelectInput.vue';
import DeviceChart from '@/components/device/DeviceChart.vue';

export default {
  name: 'DeviceSessionDetails',
  components: {
    MetricAndEventSelectInput,
    DeviceChart,
    BSpinner,
  },

  data() {
    return {
      userSessions: null,
      deviceLocalId: '',
      metricAndEventOptions: [],
      metricAndEventPlaceholder: 'Search event type or metric',
      isLegendDisplayed: true,
      isTooltipExternal: false,
      chartHeight: '480px',
    };
  },

  computed: {
    ...mapState(['selectionsInOneDevice']),

    selectedSite() {
      return this.$provider.selection.site;
    },

    selectedDeviceSession() {
      return this.$provider.selection.deviceSession;
    },

    selectedDeviceData() {
      return this.$provider.selection.deviceData;
    },

    formattedDate() {
      if (!this.selectedDeviceSession) {
        return null;
      }
      const startDate = new Date(
        this.selectedDeviceSession.startDate,
      ).toLocaleString('en-US', { timeZone: this.selectedSite.timeZone });
      const endDate = new Date(
        this.selectedDeviceSession.endDate,
      ).toLocaleString('en-US', { timeZone: this.selectedSite.timeZone });

      return `${startDate} - ${endDate}`;
    },

    selectedMetricOrEvent() {
      return this.selectionsInOneDevice.selections || [];
    },

    spectators() {
      let spectators = [];
      for (let session of this.userSessions) {
        spectators.push({
          name: session.experience.localId,
          startDate: session.startDate,
          endDate: session.endDate,
          endReason: session.endReason,
          group: session.group,
          serverSession: session.serverSession,
        });
      }
      return spectators;
    },
  },

  mounted() {
    this.initializeComponent();
  },

  methods: {
    async initializeComponent() {
      this.deviceLocalId = this.$route.params.deviceId;
      this.sessionLocalId = this.$route.params.sessionId;

      if (this.deviceLocalId && this.sessionLocalId) {
        await this.fetchData();
      } else if (this.selectedDeviceSession) {
        await this.fetchUsers(this.selectedDeviceSession);
      } else {
        console.error('Device ID or Session ID is missing.');
      }
    },

    fetchData() {
      this.$provider
        .fetchAndSelectDevice(this.deviceLocalId)
        .then(() => {
          return this.$provider.selectDeviceSession({
            localId: this.sessionLocalId,
          });
        })
        .then((session) => {
          return this.fetchUsers(session);
        });
    },

    fetchUsers(session) {
      return this.$ynapse
        .GET('/api/v1/get-users-for-device', {
          deviceId: session.deviceId,
          startDate: session.startDate,
          endDate: session.endDate,
        })
        .then((res) => {
          this.userSessions = res.data;
        })
        .catch((error) => {
          this.userSessions = [];
          console.log('Error to fetch users in fetchUsers function', error);
        });
    },
  },

  watch: {
    $route: {
      handler() {
        this.initializeComponent();
      },
    },
  },
};
</script>

<style scoped>
/* TEMP fix for news feed demo  */
.margin-right {
  margin-right: 16px;
}

header {
  padding: 20px;
}

.subheading {
  font-size: 14px;
}

.header-container {
  align-items: center;
  min-width: 600px;
}

.loader {
  position: absolute;
  top: 30%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>
