<template>
  <table v-if="apiData && apiData.content" class="mb-4 text-center">
    <thead>
      <tr v-if="headers && headers.length === 1">
        <th :colspan="2">
          {{ headers[0] }}
        </th>
      </tr>
      <tr v-else>
        <th v-for="header in headers" :key="header">
          {{ header }}
        </th>
      </tr>
    </thead>

    <tbody>
      <template>
        <tr
          v-for="(row, rowIndex) in rows"
          :key="rowIndex"
          :style="{ color: getRowColor(rowIndex) }"
        >
          <td v-for="(cell, cellIndex) in row" :key="cellIndex">
            {{ evaluateExpression(cell) }}
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { Parser } from 'expr-eval';

export default {
  name: 'DashboardTable',
  props: {
    title: String,
    headers: Array,
    rows: Array,
    colors: Array,
    apiData: Object,
  },
  methods: {
    evaluateExpression(cell) {
      if (cell.startsWith('#') && cell.endsWith('#')) {
        const expression = cell.slice(1, -1);
        const parser = new Parser();

        let result;
        try {
          result = parser.evaluate(expression, this.apiData.content);
        } catch (error) {
          return '-';
        }

        if (expression.includes('videoViewed')) {
          result = this.formatNumber(result);
        } else if (expression.includes('viewingTime')) {
          result = this.formatTime(result);
        } else if (expression.includes('/')) {
          result = this.formatPercentage(result);
        }
        return result;
      }
      return cell;
    },

    formatNumber(value) {
      return value.toFixed(2);
    },

    formatTime(value) {
      const totalSeconds = Math.floor(value * 86400);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
    },

    formatPercentage(value) {
      return (value * 100).toFixed(2) + '%';
    },

    getRowColor(rowIndex) {
      return this.colors && this.colors.length > rowIndex
        ? this.colors[rowIndex]
        : 'inherit';
    },
  },
};
</script>
