// event log page
export const API_ALL_DEVICE_NAMES = '/api/v1/devices/names';
export const API_EVENT_LOG_GRAPH_MODE = '/api/v1/events/graph-mode';
export const API_EVENT_LOG_TABLE_MODE = '/api/v1/events/table-mode';
export const API_EVENT_LOG_HEATMAP_MODE = '/api/v1/events/heatmap-mode';

//  dashboard page
export const API_ONE_METRIC = '/api/v1/metric-statuses/one-metric';
export const API_EXPERIENCE_DISRUPTIONS =
  '/api/v1/events/experience-disruptions';

// inventory page
export const API_ONE_DEVICE = '/api/v1/device/info';
export const API_ALL_DEVICE_SESSIONS = '/api/v1/device/sessions';
export const API_ALL_METRIC_STATUSES_BY_DEVICE_SESSION =
  '/api/v1/metric-statuses/group-by-device-sessions';

export const API_ALL_EVENTS_BY_DEVICE_SESSION =
  '/api/v1/events/group-by-device-session';
