<template>
  <div class="w-100">
    <router-view v-if="ready"></router-view>
    <div v-else-if="online" class="w-100 text-center">
      <b-spinner
        variant="light"
        class="mt-5"
        style="width: 10rem; height: 10rem"
      />
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import { mapState } from 'vuex';

export default {
  components: { BSpinner, },

  computed: {
    ...mapState(['authentication']),

    selectedSite () {
      return this.$provider.selection.site;
    },

    online () {
      return this.authentication.status == 'online';
    },

    ready () {
      return this.$provider.siteDataReady;
    },
  },

  watch: {
    'authentication.status' (value) {
      if (value == 'online') {
        this.synchronizeCache();
      }
    },
  },

  mounted () {
    if (this.$ynapse.connectivity.isOnline()) {
      this.synchronizeCache();
    }
  },

  methods: {
    synchronizeCache () {
      if (!this.selectedSite) {
        if (this.$provider.cache.sites) {
          this.selectSiteFromRoute();
        } else {
          this.$provider.fetchSites().then(() => {
            this.selectSiteFromRoute();
          });
        }
      }
    },
    selectSiteFromRoute () {
      let routeParams = this.$route.params;
      // Select the site based on the current route
      if (routeParams['projectName'] && routeParams['siteName']) {
        this.$provider.selectSite(
          routeParams['projectName'],
          routeParams['siteName']
        );
      }
    }
  }
}
</script>
