<template>
  <div class="w-100">
    <header class="header">
      <img
        class="img-fluid logo"
        src="/PHI-STUDIO_EN-Logo-RGB-blanc-HR.png"
        alt="Logo"
      />
      <div class="synapse">SYNAPSE</div>
      <UserInfo />
    </header>
    <SitePickerOverlay />
  </div>
</template>

<script>
import UserInfo from '@/components/navbar/UserInfo.vue';
import SitePickerOverlay from '@/components/site-overview/SitePickerOverlay.vue';

export default {
  name: 'SiteOverview',

  props: {
    title: {
      type: String,
      default: 'Choose a Server',
    },
  },

  components: {
    UserInfo,
    SitePickerOverlay,
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  color: var(--light-grey);
  border-bottom: 1px solid var(--light-grey);
  width: 100%;
}

.logo {
  width: 100px;
  height: auto;
  margin-left: 4px;
}

.synapse {
  font-size: 20px;
}

/* @media only screen and (min-width: 320px) and (max-width: 767px) {
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    padding: 12px;
    color: var(--light-grey);
    border-bottom: 1px solid var(--light-grey);
  }
}

@media only screen and (min-width: 1024px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    padding: 12px;
    color: var(--light-grey);
    border-bottom: 1px solid var(--light-grey);
  }
} */
</style>
