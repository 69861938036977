<template>
  <div>
    <b-alert :show="alertMessage != null" dismissible fade @dismissed="alertMessage = null">
      {{ alertMessage }}
    </b-alert>
    <form @submit.prevent="update" @keyup.enter="handleKeyPress" class="password-form text-left">
      <h4>{{ title }}</h4>

      <div class="form-group" v-for="(field, index) in passwordFields" :key="index">
        <label :for="field.model">{{ field.label }}</label>
        <input
          :id="field.model"
          :type="field.show ? 'text' : 'password'"
          v-model="field.value"
          required
          :name="field.model"
          :autocomplete="getAutocompleteAttribute(field.model)"
          @input="clearFieldError(field)"
        />
        <font-awesome-icon
          :icon="field.show ? ['far', 'eye-slash'] : ['far', 'eye']"
          @click="togglePasswordVisibility(field)"
          class="password-toggle"
        />

        <p v-if="field.error" class="text-danger hint-message">
          {{ field.error }}
        </p>

        <p v-if="validationHints[field.model + 'Hint']" class="text-danger hint-message">
          {{ validationHints[field.model + 'Hint'] }}
        </p>
      </div>

      <b-button variant="outline-white" class="mt-3" type="submit"> Update </b-button>

      <div class="mt-3">
        <div
          v-for="(rule, index) in validationHints.newPasswordRules"
          :key="index"
          :class="rule.met ? 'text-info' : 'text-white'"
        >
          <span v-if="index === 0">{{ rule.hint }}</span>
          <span v-else>• {{ rule.hint }}</span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { BButton, BAlert } from 'bootstrap-vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

library.add(faEye, faEyeSlash);

export default {
  name: 'ChangePassword',
  components: { BButton, FontAwesomeIcon, BAlert },
  props: {
    title: {
      type: String,
      default: 'Password',
    },
  },
  data() {
    return {
      passwordFields: [
        {
          label: 'Old password',
          model: 'oldPassword',
          value: '',
          show: false,
          error: '',
        },
        {
          label: 'New password',
          model: 'newPassword',
          value: '',
          show: false,
          error: '',
        },
        {
          label: 'Confirm new password',
          model: 'confirmPassword',
          value: '',
          show: false,
          error: '',
        },
      ],
      alertMessage: null,
    };
  },
  computed: {
    oldPassword() {
      return this.passwordFields.find((field) => field.model === 'oldPassword').value;
    },
    newPassword() {
      return this.passwordFields.find((field) => field.model === 'newPassword').value;
    },
    confirmPassword() {
      return this.passwordFields.find((field) => field.model === 'confirmPassword').value;
    },
    validationHints() {
      const hints = {
        oldPasswordHint: '',
        newPasswordHint: '',
        confirmPasswordHint: '',
        newPasswordRules: [{ hint: 'New password must include:', met: true }],
        isNewPasswordValid: this.isPasswordValid(this.newPassword),
      };

      hints.newPasswordRules.push(...this.getPasswordValidationRules(this.newPassword));

      if (this.confirmPassword && this.confirmPassword !== this.newPassword) {
        hints.confirmPasswordHint = 'Passwords do not match';
      }

      if (this.oldPassword && this.oldPassword === this.newPassword) {
        hints.newPasswordHint = 'New password must be different';
      }

      return hints;
    },
  },

  methods: {
    handleKeyPress() {
      this.update();
    },
    clearFieldError(field) {
      field.error = '';
    },
    togglePasswordVisibility(field) {
      field.show = !field.show;
    },

    isPasswordValid(password) {
      return (
        password.length >= 8 &&
        /[A-Z]/.test(password) &&
        /[a-z]/.test(password) &&
        /\d/.test(password) &&
        /[!@#$%^&*(),.?":{}|<>]/.test(password)
      );
    },

    getAutocompleteAttribute(model) {
      if (model === 'oldPassword') return 'current-password';
      if (model === 'newPassword') return 'new-password';
      if (model === 'confirmPassword') return 'new-password';
      return '';
    },

    getPasswordValidationRules(password) {
      return [
        { hint: 'At least 8 characters', met: password.length >= 8 },
        { hint: 'An uppercase letter', met: /[A-Z]/.test(password) },
        { hint: 'A lowercase letter', met: /[a-z]/.test(password) },
        { hint: 'A number', met: /\d/.test(password) },
        {
          hint: 'A special character',
          met: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        },
      ];
    },

    async update() {
      const { oldPasswordHint, newPasswordHint, confirmPasswordHint, isNewPasswordValid } = this.validationHints;

      if (
        !this.oldPassword ||
        !this.newPassword ||
        !this.confirmPassword ||
        oldPasswordHint ||
        newPasswordHint ||
        confirmPasswordHint ||
        !isNewPasswordValid
      ) {
        return;
      }

      try {
        const res = await this.$ynapse.POST('/api/v1/update-password', {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        });

        this.passwordFields.forEach((field) => {
          field.value = '';
          field.show = false;
          field.error = '';
        });

        if (res.status === 200) {
          this.alertMessage = res.data;
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const oldPasswordField = this.passwordFields.find((field) => field.model === 'oldPassword');
          oldPasswordField.error = error.response.data.message || "Old password isn't valid";
        }
      }
    },
  },
};
</script>

<style scoped>
.password-form {
  left: 5%;
  top: 8rem;
  position: absolute;
}

.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
}

label {
  color: var(--white);
  width: 300px;
  align-items: left;
}

input {
  width: 100%;
  background-color: var(--dark-grey);
  border: none;
  border-radius: 6px;
  color: var(--light-grey);
  padding: 8px;
  box-sizing: border-box;
}

input:focus {
  outline: none;
}

.hint-message {
  font-size: 0.8rem;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

.new-password-hint {
  font-size: 0.9rem;
  white-space: pre;
  text-align: left;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 45px;
  cursor: pointer;
}
</style>
