<template>
  <nav class="sidebar-menu" v-if="selectedSite">
    <ul>
      <li v-for="(route, index) in routes" :key="index">
        <router-link :to="route.link" :class="route.class">{{
          route.label
        }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'SidebarMenu',

  computed: {
    selectedSite() {
      return this.$provider.selection.site;
    },
    routes() {
      const routeConfig = [
        {
          name: 'dashboard',
          label: 'Dashboard',
          classNames: ['dashboard'],
        },
        {
          name: 'monitoring',
          label: 'Monitoring',
          classNames: ['monitoring', 'monitoring-session', 'one-user'],
        },
        {
          name: 'inventory',
          label: 'Inventory',
          classNames: ['inventory', 'one-device', 'one-device-session'],
        },
        { name: 'event-log', label: 'Event log', classNames: ['event-log'] },
        {
          name: 'system-metrics',
          label: 'System metrics',
          classNames: ['system-metrics', 'one-metric'],
        },
        // { name: 'admin', label: 'Admin', classNames: ['admin'] },
      ];
      return routeConfig.map((route) => ({
        label: route.label,
        link: this.routerLink(route.name),
        class: this.routerClass(route.classNames),
      }));
    },
  },

  methods: {
    routerLink(name) {
      return {
        name,
      };
    },

    routerClass(className) {
      return {
        active: className.includes(this.$route.name),
      };
    },
  },
};
</script>

<style scoped>
.sidebar-menu {
  border-right: 1px solid var(--dark-grey);
  min-width: 200px;
}

.sidebar-menu ul {
  list-style: none;
  padding: 0;
}

.sidebar-menu ul li {
  padding: 18px 30px;
}

.sidebar-menu a {
  color: var(--light-grey);
  text-decoration: none;
}

.sidebar-menu .active {
  color: var(--white);
}
</style>
