<template>
  <div :class="[messageClasses, { blink: isLoading }, typeClass]" style="color: #2094fa">
    <slot>{{ message }}</slot>
  </div>
</template>

<script>
export default {
  name: 'HintMessage',
  props: {
    message: {
      type: String,
      default: '',
    },
    isLoading: Boolean,
    type: {
      type: String,
      default: '',
      validator: (value) => ['no-data', 'error', 'info', ''].includes(value),
    },
  },
  computed: {
    messageClasses() {
      return {
        'text-center': true,
        'mt-3': true,
      };
    },
    typeClass() {
      return {
        'no-data': this.type === 'no-data',
      };
    },
  },
};
</script>

<style scoped>
@keyframes blink {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.blink {
  animation: blink 4.2s infinite;
}
</style>
