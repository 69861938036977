var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"margin-right"},[(_vm.userSessions)?_c('div',[_c('header',[_c('div',{staticClass:"subheading"},[_c('router-link',{attrs:{"to":{ name: 'one-device' }}},[_vm._v(" < "),(_vm.selectedDeviceData)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedDeviceData.type)+" "+_vm._s(_vm.selectedDeviceData.device.name)+" ("+_vm._s(_vm.deviceLocalId)+") ")]):_c('span',[_vm._v(" Device "+_vm._s(_vm.deviceLocalId)+" ")])])],1),_c('div',{staticClass:"header-container"},[_c('h2',{staticClass:"heading"},[_vm._v(_vm._s(_vm.formattedDate))]),(_vm.userSessions.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.spectators.length > 1 ? 'Users:' : 'User')+" "),_vm._l((_vm.spectators),function(spectator,index){return _c('router-link',{key:spectator.name + index,attrs:{"to":{
              name: 'one-user',
              params: {
                sessionId: spectator.serverSession
                  ? spectator.serverSession.localId
                  : '',
                userId: spectator.name,
              },
            }}},[_vm._v(" "+_vm._s(spectator.name)+" ")])})],2):_vm._e()])]),_c('MetricAndEventSelectInput',{staticClass:"w-100 px-3",attrs:{"metricAndEventOptions":_vm.metricAndEventOptions,"metricAndEventPlaceholder":_vm.metricAndEventPlaceholder}}),(_vm.selectedDeviceSession)?_c('DeviceChart',{staticClass:"px-5",attrs:{"selectedMetricOrEvent":_vm.selectedMetricOrEvent,"selectedDeviceSession":_vm.selectedDeviceSession,"isLegendDisplayed":_vm.isLegendDisplayed,"isTooltipExternal":_vm.isTooltipExternal,"chartHeight":_vm.chartHeight}}):_vm._e()],1):_c('div',{staticClass:"loader"},[_c('b-spinner',{staticStyle:{"width":"10rem","height":"10rem"},attrs:{"variant":"light"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }