<template>
  <div>
    <label>Smoothing radius: {{ smoothingRadius }}</label>
    <input
      type="range"
      :min="minValue"
      :max="maxValue"
      step="0.01"
      :value="smoothingRadius"
      @input="handleInput"
    />
  </div>
</template>

<script>
export default {
  name: 'SmoothingRange',
  props: ['value'],
  model: { prop: 'value', event: 'change' },
  computed: {
    smoothingRadius: {
      get() {
        return parseInt(this.value) || 0;
      },
      set(newValue) {
        this.$emit('change', parseInt(newValue));
      },
    },
    minValue() {
      return 0;
    },
    maxValue() {
      return 10;
    },
  },
  methods: {
    handleInput(event) {
      this.smoothingRadius = event.target.value;
    },
  },
};
</script>
