<template>
  <div class="d-flex justify-content-middle">
    <v-select
      v-if="serverSessions.length > 1"
      class="mr-2"
      :options="serverSessions"
      :value="activeSession"
      :clearable="false"
      @input="(val) => $emit('change', val)"
      label="_id"
      placeholder="Select a session from this date"
      style="min-width: 250px; height: 2em"
    >
      <template #option="session">
        <span
          >{{ new Date(session.startDate).toLocaleTimeString('en-US', {timeZone}) }} -
          {{ new Date(session.endDate).toLocaleTimeString('en-US', {timeZone}) }}</span
        >
      </template>
      <template #selected-option="session">
        <span
          >{{ new Date(session.startDate).toLocaleTimeString('en-US', {timeZone}) }} -
          {{ new Date(session.endDate).toLocaleTimeString('en-US', {timeZone}) }}</span
        >
      </template>
    </v-select>
    <DateTimePicker :label="label" type="date" v-model="targetDate" />
  </div>
</template>

<script>
import DateTimePicker from '@/components/event-log/DateTimePicker.vue';
import vSelect from 'vue-select';

export default {
  components: {
    DateTimePicker,
    vSelect,
  },
  model: {
    prop: 'activeSession',
    event: 'change',
  },
  props: {
    siteId: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Jump to date',
    },
    timeZone: String,
    activeSession: Object,
  },
  data() {
    return {
      targetDate: null,
      serverSessions: [],
      fetchingSessions: false,
      mustRefetchSessions: false,
    };
  },

  mounted () {
    if (this.activeSession) {
      this.targetDate = this.activeSession.startDate;
    }
  },

  methods: {
    fetchServerSessions() {
      this.serverSessions = [];
      this.$emit('change', null);
      this.fetchingSessions = true;
      this.$ynapse
        .GET('/api/v1/serverSessions', {
          siteId: this.siteId,
          date: this.targetDate,
        })
        .then((res) => {
          this.fetchingSessions = false;
          if (this.mustRefetchSessions) {
            this.mustRefetchSessions = false;
            this.fetchServerSessions();
          } else if (this.targetDate != null) {
            this.serverSessions = res.data;
            if (this.serverSessions.length == 1) {
              this.$emit('change', this.serverSessions[0]);
            }
          }
        });
    },
  },

  watch: {
    targetDate (value) {
      if (this.activeSession && value == this.activeSession.startDate) {
        return;
      }
      if (this.fetchingSessions) {
        this.mustRefetchSessions = value != null;
        return;
      }
      this.fetchServerSessions();
    },
  },
};
</script>

<style scoped>

</style>
