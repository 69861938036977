<template>
  <div class="p-3">
    <div class="event-log-options">
      <div class="topRow">
        <RadioPicker class="option" label="Mode" v-model="selectedMode" :options="modeOptions" />
        <RadioPicker
          class="option"
          label="GroupBy"
          v-model="selectedGroupBy"
          :options="groupByOptions"
          :isDisabled="disableGroupOptions"
        />
      </div>
      <div class="time">
        <DateTimePicker class="option" label="From" v-model="fromTime" />
        <DateTimePicker class="option" label="To" v-model="toTime" />
      </div>
      <SelectInput class="option width-grow" selectType="Event" v-model="eventTypes" :options="eventOptions" />
      <div class="filter">
        <SelectInput
          class="option width-grow"
          ref="selectDeviceInput"
          selectType="Device"
          v-model="deviceFilter"
          :options="deviceOptions"
        />
        <UserSearch
          class="option width-grow"
          ref="userSearch"
          searchType="Spectator"
          v-model="userFilter"
          @tags-input="handleTagsInput"
          @current-input="handleCurrentInput"
        />
        <FilterSearch class="option width-grow" label="Filter" v-model="detailsFilter" />
      </div>
      <b-button class="option" variant="dark" @click="handleSubmit"> Go </b-button>
      <b-button class="option" variant="dark" @click="exportCSV">Export CSV</b-button>
    </div>

    <HintModal ref="hintModal" :okOnly="true"></HintModal>

    <div class="event-log-result">
      <div v-if="eventCount" class="w-100 text-center">Got {{ eventCount }} events</div>

      <HintMessage
        v-if="showRequiredFieldsHint || showLoadingMessage || showNoDataHint || showNoLayoutHint"
        :message="
          showLoadingMessage
            ? 'loading'
            : showRequiredFieldsHint
            ? 'Required fields: from, to, and at least one event type'
            : showNoDataHint
            ? 'No data matched the selected criteria'
            : 'Failed to get layout when fetching data.'
        "
        :isLoading="showLoadingMessage"
      />

      <Bar v-else-if="selectedMode === 'graph'" class="barChart" :data="chart" :options="options" />

      <EventTable
        v-else-if="selectedMode === 'table'"
        :apiData="apiData"
        :itemsPerPage="itemsPerPage"
        :currentPage="currentPage"
        :totalPages="totalTablePages"
        :sortBy="sortBy"
        :sortOrder="sortOrder"
        :timeZone="timeZone"
        @loadPage="loadPage"
        @sortByColumn="sortByColumn"
        class="mt-3"
      />

      <div v-else-if="selectedMode === 'heatmap'">
        <div class="d-flex">
          <SmoothingRange v-model="heatmapSmoothing" />
          <span v-if="showLayoutOverlapHint" class="text-warning ml-auto"> Conflicting layouts detected </span>
        </div>
        <HeatmapGraph :apiData="apiData" :layoutData="heatmapLayoutData" :smoothingRadius="heatmapSmoothing" />
      </div>
    </div>
  </div>
</template>

<script>
import { fromZonedTime, toZonedTime, format } from 'date-fns-tz';
import { BButton } from 'bootstrap-vue';
import DateTimePicker from '@/components/event-log/DateTimePicker.vue';
import RadioPicker from '@/components/event-log/RadioPicker.vue';
import SelectInput from '@/components/event-log/SelectInput.vue';
import UserSearch from '@/components/event-log/UserSearch.vue';
import FilterSearch from '@/components/event-log/FilterSearch.vue';
import HintMessage from '@/components/HintMessage.vue';
import EventTable from '@/components/event-log/EventTable.vue';
import HeatmapGraph from '@/components/event-log/HeatmapGraph.vue';
import SmoothingRange from '@/components/event-log/SmoothingRange.vue';
import HintModal from '@/components/HintModal.vue';

import {
  getDataByDays,
  getDataByHours,
  getDataByDevices,
  getDataByUsers,
  convertTimestampToMinutes,
} from '@/helper/utils';

import { API_EVENT_LOG_GRAPH_MODE, API_EVENT_LOG_TABLE_MODE, API_EVENT_LOG_HEATMAP_MODE } from '../../helper/api';

import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, zoomPlugin);

export default {
  name: 'EventLogPage',
  components: {
    DateTimePicker,
    RadioPicker,
    SelectInput,
    UserSearch,
    FilterSearch,
    BButton,
    Bar,
    HintMessage,
    EventTable,
    HeatmapGraph,
    SmoothingRange,
    HintModal,
  },
  data() {
    return {
      // Content
      modeOptions: [
        { text: 'Graph', value: 'graph' },
        { text: 'Table', value: 'table' },
        { text: 'Heatmap', value: 'heatmap' },
      ],
      groupByOptions: [
        { text: 'None', value: 'none' },
        { text: 'Device', value: 'device' },
        { text: 'Spectator', value: 'spectator' },
        { text: 'TimeOfDay', value: 'timeOfTheDay' },
      ],

      // Mode selection
      selectedMode: 'graph',
      selectedGroupBy: 'none',
      disableGroupOptions: false,

      // Search filters
      fromTime: null,
      toTime: null,
      eventTypes: [],
      deviceFilter: [],
      userFilter: [],
      currentUserInput: '',
      detailsFilter: [],

      // Table settings
      itemsPerPage: 30,
      currentPage: 1,
      sortBy: 'time',
      sortOrder: 'asc',

      // Heatmap settings
      heatmapSmoothing: 1,

      // Response data
      apiData: [],
      csvExportData: [],
      heatmapLayoutData: null,

      // Hint displays
      showLoadingMessage: false,
      showRequiredFieldsHint: true,
      showNoDataHint: false,
      showNoLayoutHint: false,
      showLayoutOverlapHint: false,
      showPreparingCSV: false,

      // Chart settings
      leftTimestamp: '',
      rightTimestamp: '',
      minuteGap: 0,
      chart: {},
      zoomRefreshTimer: null,
      options: {
        plugins: {
          title: {
            display: true,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
            },
            zoom: {
              wheel: {
                enabled: true,
                speed: 0.05,
              },
              pinch: {
                enabled: true,
              },
              mode: 'x',
            },
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            // ticks: {
            // determine the total number showing in the x axis
            // maxTicksLimit: 10,
            // },
          },
          y: {
            stacked: true,
            ticks: {
              precision: 0,
            },
          },
        },
        animation: false,
        onClick: (e) => {
          const points = e.chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);
          if (points.length) {
            const firstPoint = points[0];
            const label = e.chart.data.labels[firstPoint.index];
            this.updateLabelClicked(label);
          }
        },
      },
    };
  },

  computed: {
    timeZone() {
      return this.$provider.selection.site.timeZone;
    },

    eventOptions() {
      if (!this.$provider.cache.eventTypes) {
        return [];
      }
      let events = this.$provider.cache.eventTypes.slice();
      events.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      return events;
    },

    deviceOptions() {
      if (!this.$provider.cache.devices) {
        return [];
      }
      let devices = this.$provider.cache.devices.slice();
      devices.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      return devices;
    },

    processedDetailsFilter() {
      return this.detailsFilter
        .filter((entry) => entry.isValid)
        .map((entry) => {
          let obj = {
            field: entry.field,
            operator: entry.operator,
          };
          if (entry.value == '' || entry.value == null) {
            obj.value = null;
          } else {
            try {
              obj.value = JSON.parse(entry.value);
            } catch (e) {
              // No problem, just keep handling it as a string
              obj.value = entry.value;
            }
          }
          return obj;
        });
    },

    eventCount() {
      if (!this.apiData || this.apiData.length == 0) {
        return null;
      }

      let count = 0;
      switch (this.selectedMode) {
        case 'graph':
          for (let eventType of this.apiData) {
            for (let ev of eventType.events) {
              count += ev.timestamps.length;
            }
          }
          return count;
        case 'table':
          return this.apiData[0].totalItems;
        case 'heatmap':
          for (let eventType of this.apiData) {
            count += eventType.points.length;
          }
          return count;
        default:
          return null;
      }
    },

    totalTablePages() {
      if (this.selectedMode != 'table') {
        return null;
      }
      return Math.ceil(this.eventCount / this.itemsPerPage);
    },
  },

  created() {
    if (this.$route.query) {
      const query = this.$route.query;
      // Restore settings from query
      if (query.mode) {
        this.selectedMode = query.mode;
      }
      if (query.group) {
        this.selectedGroupBy = query.group;
      }
      if (query.from) {
        this.fromTime = new Date(query.from);
      }
      if (query.to) {
        this.toTime = new Date(query.to);
      }
      // FIXME: eventOptions and deviceOptions are empty
      if (query.types) {
        this.eventTypes = this.eventOptions.filter((ev) => {
          return Array.isArray(query.types) ? query.types.indexOf(ev.name) >= 0 : query.types == ev.name;
        });
      }
      if (query.devices) {
        this.deviceFilter = this.deviceOptions.filter((device) => {
          return Array.isArray(query.devices)
            ? query.devices.indexOf(device.localId) >= 0
            : query.devices == device.localId;
        });
      }
      if (query.users) {
        this.userFilter = Array.isArray(query.users) ? query.users : [query.users];
      }
      if (query.filter) {
        if (Array.isArray(query.filter)) {
          this.detailsFilter = query.filter.map((condition) => {
            let obj = JSON.parse(condition);
            obj.isValid = true;
            return obj;
          });
        } else {
          let obj = JSON.parse(query.filter);
          obj.isValid = true;
          this.detailsFilter = [obj];
        }
      }
    }
  },

  methods: {
    handleZoomComplete({ chart }) {
      if (this.zoomRefreshTimer) {
        clearTimeout(this.zoomRefreshTimer);
      }
      this.zoomRefreshTimer = setTimeout(() => {
        this.zoomRefreshTimer = null;
        const minRange = chart.scales['x'].min;
        const maxRange = chart.scales['x'].max;

        if (this.selectedGroupBy === 'timeOfTheDay') {
          const leftLabel = chart.config.data.labels[minRange];
          const rightLabel = chart.config.data.labels[maxRange];

          const leftInMinutes = convertTimestampToMinutes(leftLabel);
          const rightInMinutes = convertTimestampToMinutes(rightLabel);

          const gap = rightInMinutes - leftInMinutes;

          this.$set(this, 'minuteGap', gap);
          this.$set(this, 'leftTimestamp', leftLabel);
          this.$set(this, 'rightTimestamp', rightLabel);
        } else {
          const t1 = minRange / chart.config.data.labels.length;
          const t2 = maxRange / chart.config.data.labels.length;

          const minDate = this.fromTime.getTime() + t1 * (this.toTime - this.fromTime);
          const maxDate = this.fromTime.getTime() + t2 * (this.toTime - this.fromTime);

          const gap = (maxDate - minDate) / 60000;

          this.$set(this, 'minuteGap', gap);
          this.$set(this, 'leftTimestamp', minDate);
          this.$set(this, 'rightTimestamp', maxDate);
        }
        this.$set(this, 'chart', chart);
        this.generateChartData();
      }, 250);
    },

    generateChartData() {
      if (this.selectedMode === 'graph') {
        if (this.selectedGroupBy === 'none' || this.selectedGroupBy === 'timeOfTheDay') {
          let interval = 5;
          let results;

          if (this.minuteGap) {
            const maxDesiredNumberOfBars = 120;
            let intervals =
              this.selectedGroupBy === 'timeOfTheDay'
                ? [1, 5, 10, 15, 30, 60]
                : [1, 5, 10, 15, 30, 60, 180, 360, 720, 1440, 10080];

            for (let value of intervals) {
              interval = value;
              if (this.minuteGap / interval < maxDesiredNumberOfBars) {
                break;
              }
            }
          }

          results =
            this.selectedGroupBy === 'timeOfTheDay'
              ? getDataByHours(this.apiData, this.$provider.cache.eventTypesMap, interval, toZonedTime, this.timeZone)
              : getDataByDays(
                  this.apiData,
                  this.$provider.cache.eventTypesMap,
                  interval,
                  fromZonedTime(this.fromTime, this.timeZone),
                  fromZonedTime(this.toTime, this.timeZone),
                  toZonedTime,
                  this.timeZone,
                );

          const { datasets, labels } = results;

          if (this.leftTimestamp != null && this.rightTimestamp != null) {
            let newMinRange = 0,
              newMaxRange = 0;
            if (this.selectedGroupBy === 'timeOfTheDay') {
              const leftInMinutes = convertTimestampToMinutes(this.leftTimestamp);
              const rightInMinutes = convertTimestampToMinutes(this.rightTimestamp);

              for (let label of results.labels) {
                const time = convertTimestampToMinutes(label);
                if (time < leftInMinutes) {
                  newMinRange++;
                }
                if (time < rightInMinutes) {
                  newMaxRange++;
                }
              }
            } else {
              let time = new Date(this.fromTime);
              let i = 0;
              while (time < this.toTime) {
                if (time <= this.leftTimestamp) {
                  newMinRange = i;
                }
                if (time <= this.rightTimestamp) {
                  newMaxRange = i;
                }
                time.setMinutes(time.getMinutes() + interval);
                i++;
              }
            }

            this.chart.zoomScale('x', { min: newMinRange, max: newMaxRange }, 'default');
          }

          this.chart = { datasets, labels };
        }

        if (this.selectedGroupBy === 'device') {
          const { datasets, labels } = getDataByDevices(
            this.apiData,
            this.$provider.cache.eventTypesMap,
            this.deviceFilter,
            this.$provider.cache.devicesMap,
          );

          this.chart = { datasets, labels };
        }

        if (this.selectedGroupBy === 'spectator') {
          const { datasets, labels } = getDataByUsers(
            this.apiData,
            this.$provider.cache.eventTypesMap,
            this.userFilter,
          );

          this.chart = { datasets, labels };
        }
      }
    },

    async fetchGraphData() {
      const fromTime = fromZonedTime(this.fromTime, this.timeZone);
      const toTime = fromZonedTime(this.toTime, this.timeZone);
      const eventTypeIds = this.eventTypes.map((e) => e._id);
      const deviceIds = this.deviceFilter.map((d) => d._id);
      const userIds = this.userFilter || [];
      const detailsFilter = this.processedDetailsFilter;
      const siteId = this.$provider.selection.site.siteId;

      try {
        const res = await this.$ynapse.GET(API_EVENT_LOG_GRAPH_MODE, {
          startDate: fromTime,
          endDate: toTime,
          eventTypeId: eventTypeIds.join(','),
          deviceId: deviceIds.join(','),
          userName: userIds.join(','),
          detailsFilter: JSON.stringify(detailsFilter),
          siteId: siteId,
        });
        if (res.data.length === 0) {
          this.showNoDataHint = true;
        } else {
          const gap = (this.toTime - this.fromTime) / 60000;
          this.minuteGap = gap;
          this.apiData = res.data;
          this.showNoLayoutHint = false;
          this.generateChartData();
        }
        return res.data;
      } catch (error) {
        console.error('Error fetching Event Log For Graph Mode data:', error);
        throw error;
      }
    },

    async loadPage(direction) {
      if (direction === 'last') {
        this.currentPage = this.totalTablePages;
      } else if (direction === 'next') {
        this.currentPage++;
      } else if (direction === 'prev' && this.currentPage > 1) {
        this.currentPage--;
      } else if (direction === 'first') {
        this.currentPage = 1;
      }

      await this.fetchTableData();
    },

    async sortByColumn(column) {
      if (this.sortBy === column) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortBy = column;
        this.sortOrder = 'asc';
      }

      await this.fetchTableData();
    },

    async fetchTableData() {
      const fromTime = fromZonedTime(this.fromTime, this.timeZone);
      const toTime = fromZonedTime(this.toTime, this.timeZone);
      const eventTypeIds = this.eventTypes.map((e) => e._id);
      const deviceIds = this.deviceFilter.map((d) => d._id);
      const userIds = this.userFilter || [];
      const detailsFilter = this.processedDetailsFilter;
      const itemsPerPage = this.itemsPerPage;
      const currentPage = this.currentPage;
      const sortBy = this.sortBy;
      const sortOrder = this.sortOrder;
      const siteId = this.$provider.selection.site.siteId;

      try {
        const res = await this.$ynapse.GET(API_EVENT_LOG_TABLE_MODE, {
          startDate: fromTime,
          endDate: toTime,
          eventTypeId: eventTypeIds.join(','),
          deviceId: deviceIds.join(','),
          userName: userIds.join(','),
          detailsFilter: JSON.stringify(detailsFilter),
          page: currentPage,
          itemsPerPage: itemsPerPage,
          sortBy: sortBy,
          sortOrder: sortOrder,
          siteId: siteId,
        });
        this.$emit('updateEventData', res);
        this.apiData = res.data;

        return res.data;
      } catch (error) {
        console.error('Error fetching Event Log For Table Mode data:', error);
        throw error;
      }
    },

    async fetchHeatmapData() {
      const fromTime = fromZonedTime(this.fromTime, this.timeZone);
      const toTime = fromZonedTime(this.toTime, this.timeZone);
      const eventTypeIds = this.eventTypes.map((e) => e._id);
      const deviceIds = this.deviceFilter.map((d) => d._id);
      const userIds = this.userFilter || [];
      const detailsFilter = this.processedDetailsFilter;
      const siteId = this.$provider.selection.site.siteId;

      try {
        const res = await this.$ynapse.GET(API_EVENT_LOG_HEATMAP_MODE, {
          startDate: fromTime,
          endDate: toTime,
          eventTypeId: eventTypeIds.join(','),
          deviceId: deviceIds.join(','),
          userName: userIds.join(','),
          detailsFilter: JSON.stringify(detailsFilter),
          siteId: siteId,
        });

        this.apiData = res.data.events;
        this.heatmapLayoutData = res.data.layout;
        this.showLayoutOverlapHint = res.data.layoutWarning;
        this.showNoLayoutHint = false;

        if (res.data.events.length == 0) {
          this.showNoDataHint = true;
        }
        return res.data;
      } catch (error) {
        this.showNoLayoutHint = true;
        console.error('Error fetching Event Log For Heatmap Mode data:', error);
        throw error;
      }
    },

    handleTagsInput(tags) {
      this.userFilter = tags;
    },

    handleCurrentInput(input) {
      this.currentUserInput = input;
    },

    validateFields() {
      const hasFrom = this.fromTime && this.toTime && this.eventTypes.length > 0;
      return hasFrom;
    },

    updateQuery() {
      const query = {
        mode: this.selectedMode,
        group: this.selectedGroupBy || undefined,
        from: this.fromTime ? this.fromTime.toISOString() : undefined,
        to: this.toTime ? this.toTime.toISOString() : undefined,
        types: this.eventTypes.map((type) => type.name),
        devices: this.deviceFilter.map((device) => device.localId),
        users: this.userFilter,
        filter: this.processedDetailsFilter.map((condition) => JSON.stringify(condition)),
      };

      this.$router.replace({ query }).catch(() => {});
    },

    resetData() {
      this.apiData = [];
      this.heatmapLayoutData = null;
      this.leftTimestamp = null;
      this.rightTimestamp = null;

      this.showLayoutOverlapHint = false;

      this.currentPage = 1;
      this.sortBy = 'time';
      this.sortOrder = 'asc';
    },

    async handleSubmit() {
      try {
        this.showRequiredFieldsHint = false;
        this.showNoDataHint = false;
        this.showLoadingMessage = true;
        this.resetData();

        if (!this.validateFields()) {
          this.showRequiredFieldsHint = true;
          return;
        }

        const trimmedInput = this.currentUserInput.trim();
        if (trimmedInput !== '' && !this.userFilter.includes(trimmedInput)) {
          this.userFilter.push(trimmedInput);
        }

        if (this.selectedMode === 'graph') {
          await this.fetchGraphData();
        }

        if (this.selectedMode === 'table') {
          await this.fetchTableData();
        }

        if (this.selectedMode === 'heatmap') {
          await this.fetchHeatmapData();
        }
      } catch (error) {
        console.error('Error fetching event log data:', error);
      } finally {
        this.showLoadingMessage = false;
      }
    },

    updateLabelClicked(label) {
      if (!this.userFilter.includes(label) && this.selectedGroupBy === 'spectator') {
        this.$refs.userSearch.tags.push(label);
      }

      if (this.selectedGroupBy === 'device') {
        const allDevices = this.$refs.selectDeviceInput.processedOptions;
        const labelNotExist = !this.deviceFilter.some((d) => d.name === label);
        const deviceToAdd = allDevices.find((d) => d.name === label);

        if (labelNotExist && deviceToAdd) {
          this.deviceFilter.push(deviceToAdd);
        }
      }
    },

    async fetchEventsCSV() {
      const fromTime = fromZonedTime(this.fromTime, this.timeZone);
      const toTime = fromZonedTime(this.toTime, this.timeZone);
      const eventTypeIds = this.eventTypes?.map((e) => e._id);
      const deviceIds = this.deviceFilter?.map((d) => d._id) || [];
      const userIds = this.userFilter || [];
      const detailsFilter = this.processedDetailsFilter;
      const siteId = this.$provider.selection.site.siteId;

      try {
        const res = await this.$ynapse.GET('/api/v1/events/export-csv', {
          startDate: fromTime,
          endDate: toTime,
          eventTypeId: eventTypeIds.join(','),
          deviceId: deviceIds.join(','),
          userName: userIds.join(','),
          detailsFilter: JSON.stringify(detailsFilter),
          siteId: siteId,
        });

        this.csvExportData = res.data;
        return res.data;
      } catch (error) {
        console.error('Error fetching Event Log For Table Mode data:', error);
        throw error;
      }
    },

    matchEventAndDeviceName(data) {
      const eventTypesMap = this.$provider.cache.eventTypesMap || {};
      const devicesMap = this.$provider.cache.devicesMap || {};

      return data.map((row) => {
        const eventTypeName = eventTypesMap[row.eventTypeId]?.name || '';
        const deviceName = devicesMap[row.deviceId]?.name || '';

        return {
          ...row,
          eventTypeName,
          deviceName,
        };
      });
    },

    exportCSV() {
      if (!this.validateFields()) {
        this.showRequiredFieldsHint = true;
        return;
      }

      // const fileName = format(
      //   new Date().toLocaleString(),
      //   'yyyy/MM/dd h:mm:ss a',
      // );

      this.$refs.hintModal.open(`Preparing CSV file...`, false);

      this.fetchEventsCSV()
        .then((apiData) => {
          if (apiData.length === 0) {
            this.$refs.hintModal.open('No data for CSV file');
            return;
          }

          const matchedData = this.matchEventAndDeviceName(apiData);

          matchedData.sort((a, b) => {
            return new Date(a.timestamp) - new Date(b.timestamp);
          });

          let headers = ['Local date', 'Local time', 'Event type', 'Device name', 'User name'];
          let parsedDetailsHeaders = new Set();

          matchedData.forEach((row) => {
            if (row.parsedDetails) {
              Object.keys(row.parsedDetails).forEach((key) => {
                if (key === 'date') {
                  return;
                }
                if (key === 'position' || key === 'rotation') {
                  parsedDetailsHeaders.add(`${key}X`);
                  parsedDetailsHeaders.add(`${key}Y`);
                  parsedDetailsHeaders.add(`${key}Z`);
                } else if (typeof row.parsedDetails[key] === 'object' && !Array.isArray(row.parsedDetails[key])) {
                  Object.keys(row.parsedDetails[key]).forEach((subKey) => {
                    parsedDetailsHeaders.add(`${key}.${subKey}`);
                  });
                } else {
                  parsedDetailsHeaders.add(key);
                }
              });
            }
          });

          parsedDetailsHeaders.forEach((field) => headers.push(field));
          let csv = headers.join(',') + '\n';

          matchedData.forEach((row) => {
            const zonedDate = toZonedTime(row.timestamp, this.timeZone);
            const localDate = format(zonedDate, 'dd/MM/yyyy');
            const localTime = format(zonedDate, 'h:mm:ss a');
            const eventType = row.eventTypeName || '';
            const deviceName = row.deviceName || '';
            const userName = row.userName || '';

            let csvRow = `${localDate},${localTime},${eventType},${deviceName},${userName}`;

            parsedDetailsHeaders.forEach((field) => {
              let value = '';
              if (field.includes('.')) {
                const [mainKey, subKey] = field.split('.');
                value = row.parsedDetails && row.parsedDetails[mainKey] ? row.parsedDetails[mainKey][subKey] : '';
              } else if (
                // FIX: if user leaves message ends with x, y, z, it will break
                field.endsWith('X') ||
                field.endsWith('Y') ||
                field.endsWith('Z')
              ) {
                const baseField = field.slice(0, -1).toLowerCase();
                const index = field.endsWith('X') ? 0 : field.endsWith('Y') ? 1 : 2;
                value =
                  row.parsedDetails && Array.isArray(row.parsedDetails[baseField])
                    ? row.parsedDetails[baseField][index]
                    : '';
              } else {
                value = row.parsedDetails ? row.parsedDetails[field] : '';
              }
              csvRow += `,${value !== undefined ? value : ''}`;
            });

            csv += csvRow + '\n';
          });

          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          anchor.download = `events.csv`;

          anchor.click();

          this.$refs.hintModal.open(`CSV file Downloaded successfully!`);
        })
        .catch((err) => {
          console.error('Error fetching CSV data', err);
        });
    },
  },

  watch: {
    selectedGroupBy(newVal) {
      this.updateQuery();
      this.resetData();
      this.showRequiredFieldsHint = true;

      if (newVal === 'none' || newVal === 'timeOfTheDay') {
        this.options = {
          ...this.options,
          plugins: {
            ...this.options.plugins,
            zoom: {
              ...this.options.plugins.zoom,
              zoom: {
                ...this.options.plugins.zoom.zoom,
                onZoomComplete: this.handleZoomComplete,
              },
            },
          },
        };
      }
    },

    selectedMode(newVal) {
      this.updateQuery();
      this.resetData();
      this.showRequiredFieldsHint = true;

      if (newVal === 'table' || newVal === 'heatmap') {
        this.disableGroupOptions = true;
      } else {
        this.disableGroupOptions = false;
      }
    },

    fromTime(value) {
      this.updateQuery();
      this.showRequiredFieldsHint = true;
      if (value && !this.toTime) {
        this.toTime = toZonedTime(new Date(), this.timeZone);
      }
    },

    toTime() {
      this.updateQuery();
      this.showRequiredFieldsHint = true;
    },

    eventTypes() {
      this.updateQuery();
      this.showRequiredFieldsHint = true;
    },

    deviceFilter() {
      this.updateQuery();
      this.showRequiredFieldsHint = true;
    },

    userFilter() {
      this.updateQuery();
      this.showRequiredFieldsHint = true;
    },

    detailsFilter() {
      this.updateQuery();
      this.showRequiredFieldsHint = true;
    },
  },
};
</script>

<style scoped>
.event-log-result {
  width: 100%;
}

.barChart {
  width: 85%;
  margin: auto;
}

@media (max-width: 767px) {
  .event-log-options {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .option:not(:first-child) {
    margin-top: 10px;
  }

  .option:not(:last-child) {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .event-log-options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* max-width: 800px; */
  }

  .time {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .option {
    margin-right: 10px;
    margin-bottom: 10px;
    align-items: center;
  }

  .option:last-child {
    margin-left: 5px;
  }

  .option:nth-child(2n) {
    margin-right: 0;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .event-log-options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .topRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
  }

  .time {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
  }

  .filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
  }

  .option:last-child {
    margin-left: 5px;
  }

  .option:nth-child(2n) {
    margin-right: 10px;
  }

  .option {
    flex: 0 0 calc(5% - 10px); /* Set width to 5% minus margin */
    margin-right: 10px;
    margin-bottom: 10px;
    align-items: baseline;
  }

  .width-grow {
    flex-grow: 1;
  }
}

@media (min-width: 1441px) and (max-width: 2560px) {
  .event-log-options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .topRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
  }

  .time {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
  }

  .filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
  }

  .option:last-child {
    margin-left: 5px;
  }

  .option:nth-child(2n) {
    margin-right: 10px;
  }

  .option {
    flex: 0 0 calc(5% - 10px); /* Set width to 5% minus margin */
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .width-grow {
    flex-grow: 1;
    min-width: 280px;
  }
}
</style>
