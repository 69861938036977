<template>
  <div class="table-wrapper w-100 h-100 px-3 pt-3">
    <b-alert :show="alertMessage != null" dismissible fade @dismissed="alertMessage = null">
      {{ alertMessage }}
    </b-alert>

    <div class="d-flex mb-3 align-items-center">
      <b-button variant="primary" :disabled="selectedDevices.length == 0" @click="showExportModal = true"
        >Export data</b-button
      >

      <div v-if="isLoading" class="loading-wrapper">
        <div class="loader">
          <b-spinner variant="light" style="width: 2rem; height: 2rem" />
        </div>
      </div>
      <DeviceDataExportModal
        :showModal.sync="showExportModal"
        :selectedDevices="selectedDevices"
        @exportData="exportData"
      />

      <SelectInput
        v-if="miscFields.length"
        v-model="enabledMiscFields"
        class="flex-grow-1 mr-2"
        :options="miscFields"
        placeholder="Extra fields to display"
      />
      <b-form-checkbox v-model="hideUnused" class="ml-auto text-content">Hide unused devices</b-form-checkbox>
    </div>
    <table class="w-100">
      <tbody>
        <tr class="text-center table-head">
          <th class="clickable" @click.prevent.stop="toggleAllSelection">
            <b-form-checkbox
              class="clickable"
              :checked="selectedDevices.length > 0"
              :indeterminate="selectedDevices.length > 0 && selectedDevices.length < sortedInventory.length"
              >Select</b-form-checkbox
            >
          </th>
          <th class="clickable" @click="setSortField('name')">
            Identity<span v-if="sortingField == 'name'"> {{ sortingReversed ? '↑' : '↓' }}</span>
          </th>
          <th class="clickable" @click="setSortField('mac')">
            MAC<span v-if="sortingField == 'mac'"> {{ sortingReversed ? '↑' : '↓' }}</span>
          </th>
          <th class="clickable" @click="setSortField('runPct')">
            Success rate<span v-if="sortingField == 'runPct'"> {{ sortingReversed ? '↑' : '↓' }}</span>
          </th>
          <th class="clickable" @click="setSortField('created')">
            Created on<span v-if="sortingField == 'created'"> {{ sortingReversed ? '↑' : '↓' }}</span>
          </th>
          <th class="clickable" @click="setSortField('lastSeen')">
            Status<span v-if="sortingField == 'lastSeen'"> {{ sortingReversed ? '↑' : '↓' }}</span>
          </th>
          <th v-for="key in enabledMiscFields" :key="key" class="clickable" @click="setSortField(`misc/${key}`)">
            {{ key }}<span v-if="sortingField == `misc/${key}`"> {{ sortingReversed ? '↑' : '↓' }}</span>
          </th>
        </tr>
        <tr
          v-for="(device, index) in sortedInventory"
          :key="`${device.id}_${device.name}_${device.isOnline}_${device.misc.lastSeen}`"
          :class="`clickable table-text table-row-${index % 2} ${device.runs <= 0 ? 'table-fade' : ''}`"
          @click="navigateToDevice(device.id)"
        >
          <td @click.prevent.stop="toggleSelectDevice(device.id)">
            <b-form-checkbox class="text-center" :checked="selectedDevices.includes(device.id)" />
          </td>
          <td>
            <img
              style="height: 2rem"
              :src="`/devices/${device.type.toLowerCase()}.png`"
              onerror="this.onerror = null; this.remove();"
            />
            {{ device.type }} {{ device.name }} ({{ device.id }})
          </td>
          <td>{{ device.mac }}</td>
          <td>
            {{ isNaN(device.runPct) ? 'unknown' : (device.runPct * 100).toFixed(2) + '%' }}
          </td>
          <td>
            {{ new Date(device.firstDate).toLocaleString('en-US', { timeZone }) }}
          </td>
          <td v-if="device.misc.isOnline">Online</td>
          <td v-else-if="device.misc.lastSeen">
            Last seen:
            {{ new Date(device.misc.lastSeen).toLocaleString('en-US', { timeZone }) }}
          </td>
          <td v-else>Unknown</td>
          <td v-for="key in enabledMiscFields" :key="key">
            {{ device.misc[key] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { SynapseWatcher } from 'synapse-vue';
import { BFormCheckbox, BButton, BSpinner, BAlert } from 'bootstrap-vue';
import SelectInput from '@/components/event-log/SelectInput.vue';
import DeviceDataExportModal from '@/components/device/DeviceDataExportModal.vue';
import {
  generateCSV,
  downloadCSV,
  formatDetails,
  createDeviceMap,
  createMapFromArray,
  assignNames,
  extractMetricAndEventTypeIds,
} from '@/helper/utils';
import { fromZonedTime, toZonedTime, format } from 'date-fns-tz';

export default {
  name: 'InventoryPage',
  components: {
    SelectInput,
    BFormCheckbox,
    BButton,
    DeviceDataExportModal,
    BSpinner,
    BAlert,
  },
  mixins: [
    SynapseWatcher({
      routeDynamic: 'inventoryRoute',
      dataName: 'liveDevices',
      isArray: true,
      entryIDKey: 'id',
      sorting: {
        key: 'name',
      },
    }),
  ],
  data() {
    return {
      sortingField: 'name',
      sortingReversed: false,
      enabledMiscFields: [],
      hideUnused: true,
      showExportModal: false,
      selectedDevices: [],
      isLoading: false,
      alertMessage: null,
    };
  },
  computed: {
    selectedSite() {
      return this.$provider.selection.site;
    },

    timeZone() {
      return this.$provider.selection.site.timeZone;
    },

    inventoryRoute() {
      if (this.selectedSite && this.selectedSite.servers.length) {
        return `/sites/${this.selectedSite.servers[0].id}/inventory`
      }
      return null;
    },

    miscFields() {
      let fields = [];
      for (let device of this.liveDevices) {
        if (device.misc) {
          for (let key in device.misc) {
            if (key != 'lastSeen' && key != 'isOnline' && fields.indexOf(key) < 0) {
              fields.push(key);
            }
          }
        }
      }
      return fields;
    },

    cachedDevices() {
      if (this.$provider.cache.devices) {
        return this.$provider.cache.devices.map((device) => ({
          id: device.localId,
          type: device.type,
          name: device.name,
          mac: device.macAddress,
          runs: device.stats.totalRuns,
          runPct: device.stats.runsWithoutIncident,
          firstDate: device.firstDate,
          misc: {},
        }));
      }
      return [];
    },

    sortedInventory() {
      let array = this.liveDevices.length ? this.liveDevices.slice() : this.cachedDevices;
      if (this.hideUnused) {
        array = array.filter((device) => device.runs > 0);
      }
      let sortWithMiscData = this.sortingField.startsWith('misc/');
      let sortingField = sortWithMiscData ? this.sortingField.substring(5) : this.sortingField;
      array.sort((a, b) => {
        let val = 0;
        let aa = sortWithMiscData ? a.misc : a;
        let bb = sortWithMiscData ? b.misc : b;
        if (aa[sortingField] != bb[sortingField]) {
          if (!isNaN(aa[sortingField]) && !isNaN(bb[sortingField])) {
            val = aa[sortingField] - bb[sortingField];
          } else {
            val = (aa[sortingField] || '') > (bb[sortingField] || '') ? 1 : -1;
          }
        } else if (a.type != b.type) {
          val = a.type > b.type ? 1 : -1;
        } else if (a.name != b.name) {
          val = a.name > b.name ? 1 : -1;
        }
        if (this.sortingReversed) {
          val = -val;
        }
        return val;
      });
      return array;
    },
  },
  watch: {
    hideUnused() {
      // Clear selection because content changed
      this.selectedDevices = [];
    },
  },
  methods: {
    setSortField(field) {
      if (this.sortingField == field) {
        this.sortingReversed = !this.sortingReversed;
      } else {
        this.sortingField = field;
        this.sortingReversed = false;
      }
    },

    navigateToDevice(deviceId) {
      this.$router.push({
        name: 'one-device',
        params: { deviceId },
      });
    },

    toggleAllSelection() {
      this.selectedDevices = this.sortedInventory
        .map((device) => device.id)
        .filter((id) => {
          return !this.selectedDevices.includes(id);
        });
    },

    toggleSelectDevice(deviceId) {
      let index = this.selectedDevices.indexOf(deviceId);
      if (index < 0) {
        this.selectedDevices.push(deviceId);
      } else {
        this.selectedDevices.splice(index, 1);
      }
    },

    getDeviceIds() {
      return this.selectedDevices.map((localId) => {
        const device = this.$provider.cache.devicesLocalMap[localId];
        return device._id;
      });
    },

    async fetchDeviceExportData(startDate, endDate, metricIds, eventTypeIds, deviceIds) {
      const startDateUTC = fromZonedTime(startDate, this.timeZone);
      const endDateUTC = fromZonedTime(endDate, this.timeZone);

      return await this.$ynapse.GET('/api/v1/inventory/devices/export-csv', {
        startDate: startDateUTC,
        endDate: endDateUTC,
        metricIds,
        eventTypeIds,
        deviceIds,
        siteId: this.selectedSite.siteId,
      });
    },

    generateCsvRows(events, metrics) {
      return [
        ...events.map((event) => [
          `${event.deviceTypeName} ${event.deviceName}`,
          'Event',
          event.eventTypeName,
          format(toZonedTime(event.timestamp, this.timeZone), 'yyyy/MM/dd HH:mm:ss'),
          '',
          formatDetails(JSON.parse(event.details)).replace(/"/g, '""'),
        ]),
        ...metrics.map((metric) => [
          `${metric.deviceTypeName} ${metric.deviceName}`,
          'Metric',
          metric.metricName,
          format(toZonedTime(metric.startDate, this.timeZone), 'yyyy/MM/dd HH:mm:ss'),
          format(toZonedTime(metric.endDate, this.timeZone), 'yyyy/MM/dd HH:mm:ss'),
          metric.value,
        ]),
      ];
    },

    downloadCsv(rows) {
      const headers = ['Device', 'Data type', 'Data name', 'Start time', 'End time', 'Value'];
      const csvUrl = generateCSV(rows, headers);
      downloadCSV(csvUrl, `${this.selectedSite.siteName}_Devices_exported_data.csv`);
    },

    async exportData({ startDate, endDate, selectedMetricAndEvents }) {
      this.isLoading = true;
      try {
        const deviceIds = this.getDeviceIds();
        const { metricIds, eventTypeIds } = extractMetricAndEventTypeIds(selectedMetricAndEvents);
        const res = await this.fetchDeviceExportData(startDate, endDate, metricIds, eventTypeIds, deviceIds);

        const noDataMessage = "There's no data matched the selected criteria";
        if (res.data.message === noDataMessage) {
          this.alertMessage = noDataMessage;
          return;
        }

        const { events, metrics } = res.data;

        const deviceMap = createDeviceMap(this.$provider.cache.devices);
        const metricMap = createMapFromArray(
          this.$provider.cache.metrics,
          (metric) => metric._id,
          (metric) => metric.name,
        );
        const eventTypeMap = createMapFromArray(
          this.$provider.cache.eventTypes,
          (eventType) => eventType._id,
          (eventType) => eventType.name,
        );

        assignNames(events, deviceMap, eventTypeMap, 'eventTypeId', 'eventTypeName');
        assignNames(metrics, deviceMap, metricMap, 'metricId', 'metricName');

        const rows = this.generateCsvRows(events, metrics);
        this.downloadCsv(rows);
      } catch (error) {
        console.error('Error:', error);
        if (error.message === 'Network Error') {
          this.alertMessage = 'Oops, something went wrong';
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.table-text {
  font-size: 0.75rem;
  line-height: 1rem;
}

.table-head {
  background-color: var(--container);
}

.table-row-0 {
  background-color: var(--black);
}

.table-row-1 {
  background-color: var(--panel);
}

.clickable {
  cursor: pointer;
}

.table-fade {
  opacity: 50%;
}

.clickable:hover {
  background-color: var(--borders);
}

.loading-wrapper {
  background-color: #00000088;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  text-align: center;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>
