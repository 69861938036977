<template>
  <div @click.stop.capture="$emit('toggle')">
    <b-dropdown
      :text="selectedSite.siteName"
      variant="black"
      class="location-select"
      lazy
    />
  </div>
</template>

<script>
import { BDropdown } from 'bootstrap-vue'

export default {
  name: 'SelectedSite',
  components: {
    BDropdown,
  },
  props: {
    selectedSite: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped>
.location-select {
  font-size: 20px;
}
</style>
