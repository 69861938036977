<template>
  <div class="w-100">
    <header class="header">
      <img class="img-fluid logo" src="/PHI-STUDIO_EN-Logo-RGB-blanc-HR.png" alt="Logo" />
      <div class="synapse">SYNAPSE</div>
      <UserInfo />
    </header>
    <div class="back-to-site" @click="backToSite">> Back to Site Selection</div>
    <ChangePassword />
  </div>
</template>

<script>
import UserInfo from '@/components/navbar/UserInfo.vue';
import ChangePassword from '@/components/settings/ChangePassword.vue';

export default {
  name: 'SettingsPage',

  components: {
    UserInfo,
    ChangePassword,
  },

  methods: {
    backToSite() {
      this.$router.push({ name: 'site-overview' });
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  color: var(--light-grey);
  border-bottom: 1px solid var(--light-grey);
  width: 100%;
}

.logo {
  width: 100px;
  height: auto;
  margin-left: 4px;
}

.synapse {
  font-size: 20px;
}

.back-to-site {
  cursor: pointer;
  color: var(--link);
  left: 5%;
  top: 5rem;
  position: absolute;
}

.back-to-site:hover {
  color: white;
}
</style>
