<template>
  <div class="date-time-picker">
    <label class="label mr-2">{{ label }} </label>
    <date-picker
      v-model="dateTime"
      :type="type"
      :placeholder="computedPlaceholder"
      :format="type == 'datetime' ? 'YYYY-MM-DD HH:mm' : ''"
      :disabled="disabled"
    ></date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import '@/style/datepicker.scss';

export default {
  name: 'DateTimePicker',
  components: {
    DatePicker,
  },
  props: {
    label: String,
    type: {
      type: String,
      default: 'datetime',
    },
    value: {
      type: [String, Date],
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    dateTime: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    computedPlaceholder() {
      return (
        this.placeholder ||
        `Select ${this.type == 'datetime' ? 'time' : this.type}`
      );
    },
  },
};
</script>

<style scoped>
.date-time-picker {
  display: flex;
  align-items: baseline;
  font-size: 14px;
  padding: 0px;
}
</style>
