import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const persistedState = createPersistedState({
  paths: ['selectionsInOneDevice', 'authentication.token'],
});

export default new Vuex.Store({
  plugins: [persistedState],
  state: {
    authentication: {
      status: null, // offline, connecting, online
      token: null,
      error: null,
    },
    selectionsInOneDevice: {},
  },
  mutations: {
    setAuthToken(state, token) {
      state.authentication.token = token;
    },
    setAuthOffline(state, error) {
      state.authentication.status = 'offline';
      state.authentication.error = error;
    },
    setAuthConnecting(state) {
      state.authentication.status = 'connecting';
      state.authentication.error = null;
    },
    setAuthOnline(state) {
      state.authentication.status = 'online';
      state.authentication.error = null;
    },
    updateSelectionsInOneDevice(state, payload) {
      state.selectionsInOneDevice = { ...payload };
    },
  },
});
