<template>
  <div class="w-100">
    <section class="header-container">
      <h4>
        <router-link :to="{ name: 'inventory' }">
          &lt;
          <span v-if="oneDeviceData">
            {{ oneDeviceData.type }}
            {{ oneDeviceData.device.name }}
            ({{ deviceLocalId }})
          </span>
          <span v-else> Device {{ deviceLocalId }} </span>
        </router-link>
      </h4>
      <DateTimeFilter @date-range-updated="handleDateRangeSelected" @date-option-updated="handleDateOptionSelected" />
    </section>

    <section class="d-flex justify-content-between">
      <OneDeviceInfo :deviceData="oneDeviceData" :timeZone="timeZone" />
      <CommentInput :notesHeader="notesHeader" :resourceId="deviceId" resourceType="device" class="comment-input" />
    </section>

    <section class="device-session">
      <div class="text-subheader d-flex justify-content-between mb-3">
        <div v-if="this.startDate !== null && this.endDate !== null">
          {{ allDeviceSessions.length }} sessions in
          {{ getReadableLabel(selectedDateOption) }}
        </div>
        <b-button variant="dark" @click="showExportModal = true">Export CSV</b-button>

        <div v-if="isLoading" class="loading-wrapper">
          <div class="loader">
            <b-spinner variant="light" style="width: 2rem; height: 2rem" />
          </div>
        </div>

        <DeviceDataExportModal
          :showModal.sync="showExportModal"
          :selectedDevices="selectedDevices"
          @exportData="exportData"
        />
      </div>
      <MetricAndEventSelectInput
        class="mb-3"
        :metricAndEventOptions="metricAndEventOptions"
        :metricAndEventPlaceholder="metricAndEventPlaceholder"
        :marginRight="'0px'"
      />

      <div
        v-if="
          (selectedDateOption === 'dateRange' && startDate === null) ||
          (selectedDateOption === 'dateRange' && endDate === null)
        "
      >
        <HintMessage v-if="showRequiredFieldsHintDetails" message="Required fields: from, to" />
      </div>

      <section
        v-else
        :class="{
          'd-flex': allDeviceSessions.length <= 9,
        }"
      >
        <div v-for="deviceSession in allDeviceSessions" :key="deviceSession._id" class="chart-container">
          <div v-if="allDeviceSessions.length < 10" class="text-center">
            <DeviceChart
              :selectedMetricOrEvent="selectedMetricOrEvent"
              :selectedDeviceSession="deviceSession"
              :isLegendDisplayed="isLegendDisplayed"
              :isTooltipExternal="isTooltipExternal"
              :minHeight="'120px'"
            />
            <b-button class="clickable" @click="clickOneDeviceSession(deviceSession)">
              {{ new Date(deviceSession.startDate).toLocaleDateString('en-US', { timeZone }) }}:
              {{ new Date(deviceSession.startDate).toLocaleTimeString('en-US', { timeZone }) }}
              -
              {{ new Date(deviceSession.endDate).toLocaleTimeString('en-US', { timeZone }) }}
            </b-button>
          </div>
        </div>

        <div v-if="allDeviceSessions.length > 9">
          <div v-for="(groupedSession, date) in groupedDeviceSessions" :key="date" class="grouped-session">
            <div class="session">
              <div class="session-date">{{ date }}</div>
              <div v-for="deviceSession in groupedSession" :key="deviceSession._id">
                <b-button class="clickable btn-fixed-width" @click="clickOneDeviceSession(deviceSession)">
                  {{ new Date(deviceSession.startDate).toLocaleDateString('en-US', { timeZone }) }}:
                  {{ new Date(deviceSession.startDate).toLocaleTimeString('en-US', { timeZone }) }}
                  -
                  {{ new Date(deviceSession.endDate).toLocaleTimeString('en-US', { timeZone }) }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { BButton, BSpinner } from 'bootstrap-vue';
import DateTimeFilter from '@/components/device/DateTimeFilter.vue';
import OneDeviceInfo from '@/components/device/OneDeviceInfo.vue';
import CommentInput from '@/components/CommentInput.vue';
import MetricAndEventSelectInput from '@/components/device/MetricAndEventSelectInput.vue';
import DeviceChart from '@/components/device/DeviceChart.vue';
import HintMessage from '@/components/HintMessage.vue';
import DeviceDataExportModal from '@/components/device/DeviceDataExportModal.vue';
import {
  generateCSV,
  downloadCSV,
  formatDetails,
  createDeviceMap,
  createMapFromArray,
  assignNames,
  extractMetricAndEventTypeIds,
} from '@/helper/utils';
import { fromZonedTime, toZonedTime, format } from 'date-fns-tz';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default {
  name: 'DeviceDetails',
  components: {
    BButton,
    DateTimeFilter,
    OneDeviceInfo,
    CommentInput,
    MetricAndEventSelectInput,
    DeviceChart,
    HintMessage,
    DeviceDataExportModal,
    BSpinner,
  },

  data() {
    return {
      startDate: null,
      endDate: null,
      deviceLocalId: null,
      metricAndEventOptions: [],
      metricAndEventPlaceholder: 'Search event type or metric',
      selectedDateOption: 'last5days',
      isLegendDisplayed: false,
      isTooltipExternal: true,
      showRequiredFieldsHintDetails: true,
      notesHeader: 'Notes (visible to others):',
      showExportModal: false,
      isLoading: false,
      selectedDevices: [this.$route?.params?.deviceId],
    };
  },

  computed: {
    ...mapState(['selectionsInOneDevice']),

    selectedSite() {
      return this.$provider.selection.site;
    },

    timeZone() {
      return this.$provider.selection.site.timeZone;
    },

    oneDeviceData() {
      return this.$provider.selection.deviceData;
    },

    deviceId() {
      return this.oneDeviceData && this.oneDeviceData.device ? this.oneDeviceData.device._id : null;
    },

    allDeviceSessions() {
      return this.$provider.cache.deviceSessions || [];
    },

    selectedMetricOrEvent() {
      return this.selectionsInOneDevice.selections || [];
    },

    groupedDeviceSessions() {
      const groups = {};
      this.allDeviceSessions.forEach((deviceSession) => {
        const dateKey = new Date(deviceSession.startDate).toLocaleDateString('en-US', { timeZone: this.timeZone });
        if (!groups[dateKey]) {
          groups[dateKey] = [];
        }
        groups[dateKey].push(deviceSession);
      });
      return groups;
    },
  },

  watch: {
    $route() {
      this.deviceLocalId = this.$route.params.deviceId;
    },

    deviceLocalId(value) {
      if (value) {
        this.$provider.fetchAndSelectDevice(value).then(() => {
          if (this.startDate && this.endDate) {
            this.$provider.fetchDeviceSessions(this.startDate, this.endDate);
          }
        });
      }
    },
  },

  mounted() {
    this.deviceLocalId = this.$route.params.deviceId;
  },

  methods: {
    handleDateRangeSelected(dateRange) {
      if (dateRange.from && dateRange.to) {
        this.startDate = dateRange.from;
        this.endDate = dateRange.to;

        if (this.oneDeviceData) {
          this.$provider.fetchDeviceSessions(this.startDate, this.endDate);
        }
      }
    },

    handleDateOptionSelected(option) {
      this.selectedDateOption = option;
    },

    getReadableLabel(option) {
      const optionLabels = {
        lastHour: 'last hour',
        today: 'today',
        yesterday: 'yesterday',
        last2days: 'last 2 days',
        last5days: 'last 5 days',
      };

      if (option in optionLabels) {
        return optionLabels[option];
      } else {
        const formattedStartDate = new Date(this.startDate).toDateString();
        const formattedEndDate = new Date(this.endDate).toDateString();
        return `${formattedStartDate} -${formattedEndDate}`;
      }
    },

    clickOneDeviceSession(deviceSession) {
      this.$provider.selectDeviceSession(deviceSession);

      // no router-link wraps one device session, so it need to be redirect here.
      this.$router.push({
        name: 'one-device-session',
        params: {
          sessionId: deviceSession.localId,
        },
      });
    },

    async fetchDeviceExportData(startDate, endDate, metricIds, eventTypeIds, deviceIds) {
      const startDateUTC = fromZonedTime(startDate, this.timeZone);
      const endDateUTC = fromZonedTime(endDate, this.timeZone);

      return await this.$ynapse.GET('/api/v1/inventory/devices/export-csv', {
        startDate: startDateUTC,
        endDate: endDateUTC,
        metricIds,
        eventTypeIds,
        deviceIds,
        siteId: this.selectedSite.siteId,
      });
    },

    generateCsvRows(events, metrics) {
      return [
        ...events.map((event) => [
          `${event.deviceTypeName} ${event.deviceName}`,
          'Event',
          event.eventTypeName,
          format(toZonedTime(event.timestamp, this.timeZone), 'yyyy/MM/dd HH:mm:ss'),
          '',
          formatDetails(JSON.parse(event.details)).replace(/"/g, '""'),
        ]),
        ...metrics.map((metric) => [
          `${metric.deviceTypeName} ${metric.deviceName}`,
          'Metric',
          metric.metricName,
          format(toZonedTime(metric.startDate, this.timeZone), 'yyyy/MM/dd HH:mm:ss'),
          format(toZonedTime(metric.endDate, this.timeZone), 'yyyy/MM/dd HH:mm:ss'),
          metric.value,
        ]),
      ];
    },

    downloadCsv(rows) {
      const headers = ['Device', 'Data type', 'Data name', 'Start time', 'End time', 'Value'];
      const csvUrl = generateCSV(rows, headers);
      downloadCSV(csvUrl, `${this.selectedSite.siteName}_Devices_exported_data.csv`);
    },

    async exportData({ startDate, endDate, selectedMetricAndEvents }) {
      this.isLoading = true;
      this.selectedDevices = [this.deviceLocalId];

      try {
        const deviceIds = [this.oneDeviceData.device._id];
        const { metricIds, eventTypeIds } = extractMetricAndEventTypeIds(selectedMetricAndEvents);
        const res = await this.fetchDeviceExportData(startDate, endDate, metricIds, eventTypeIds, deviceIds);

        const noDataMessage = "There's no data matched the selected criteria";
        if (res.data.message === noDataMessage) {
          this.alertMessage = noDataMessage;
          return;
        }

        const { events, metrics } = res.data;

        const deviceMap = createDeviceMap(this.$provider.cache.devices);
        const metricMap = createMapFromArray(
          this.$provider.cache.metrics,
          (metric) => metric._id,
          (metric) => metric.name,
        );
        const eventTypeMap = createMapFromArray(
          this.$provider.cache.eventTypes,
          (eventType) => eventType._id,
          (eventType) => eventType.name,
        );

        assignNames(events, deviceMap, eventTypeMap, 'eventTypeId', 'eventTypeName');
        assignNames(metrics, deviceMap, metricMap, 'metricId', 'metricName');

        const rows = this.generateCsvRows(events, metrics);
        this.downloadCsv(rows);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  min-width: 600px;
  padding: 20px 20px 20px 22px;
}

.comment-input {
  padding-right: 30px;
}

.device-session {
  padding: 30px 30px 30px 22px;
  color: var(--light-grey);
}

.clickable {
  background-color: var(--black);
  color: var(--link);
  border-color: var(--link);
  border-radius: 10px;
  font-size: 12px;

  &:hover {
    background-color: var(--panel);
    color: var(--white);
    border-color: var(--white);
    cursor: pointer;
  }
}

.chart-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
}

.grouped-session {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}

.grouped-session:last-child {
  margin-right: 0;
}

.session-date {
  margin-bottom: 5px;
}

.btn-fixed-width {
  min-width: 240px;
  margin-bottom: 10px;
}

.loading-wrapper {
  background-color: #00000088;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  text-align: center;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>
