<template>
  <div class="wrapper d-flex flex-column justify-content-center" @click="handleClick">
    <div class="text-detail d-flex justify-content-between align-items-center w-100">
      <div class="site mr-2">{{ siteName }}</div>
      <synapse-timer v-if="displaySites" class="no-break" type="clock" :timeZone="timeZone" />
      <div v-else>Archive</div>
    </div>
    <b-container v-if="displaySites" class="mt-2">
      <b-row class="d-flex align-items-center text-detail" v-for="server of siteServers" :key="server.id">
        <b-col class="px-0">
          <div class="text-right text-detail mr-2">{{ server.name }}</div>
        </b-col>
        <b-col class="px-0">
          <synapse-metric :route="`/sites/${server.id}/status`" dot displayMode="text"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {BContainer, BRow, BCol} from 'bootstrap-vue'

export default {
  name: 'SiteSelection',
  components: {BContainer, BRow, BCol},
  props: {
    projectName: String,
    siteName: String,
    siteId: String,
    siteServers: Array,
    timeZone: String,
  },
  computed: {
    displaySites() {
      return this.siteServers && this.siteServers.length > 0;
    },
  },
  methods: {
    handleClick() {
      const selectedSite = {
        siteId: this.siteId,
        siteName: this.siteName,
        projectName: this.projectName,
        timeZone: this.timeZone,
      };
      this.$emit('site-selected', selectedSite);
    },
  },
};
</script>

<style scoped>
.wrapper {
  background-color: var(--container);
  border-radius: 10px;
  padding: 18px;
  font-size: 12px;
  margin-right: 7px;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    box-shadow: 5px 5px 10px var(--light-grey);
    cursor: pointer;
  }
}

.no-break {
  white-space: nowrap;
  overflow: hidden;
}

.site {
  font-size: 18px;
  color: var(--header);
}
</style>
