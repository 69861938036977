<template>
  <b-modal
    v-model="modalVisible"
    title="Export Device CSV"
    size="lg"
    :header-bg-variant="headerBgVariant"
    :header-text-variant="headerTextVariant"
    :body-bg-variant="bodyBgVariant"
    :body-text-variant="bodyTextVariant"
    :footer-text-variant="footerTextVariant"
    :footer-bg-variant="footerBgVariant"
  >
    <b-container fluid>
      <p class="small-text text-primary">
        Please select start and end dates, along with at least one event or
        metric, to export device data.
      </p>

      <div class="time-picker-container">
        <DateTimePicker class="small-text" label="Start" v-model="startDate" />
        <DateTimePicker
          class="small-text to-time"
          label="End"
          v-model="endDate"
        />
      </div>

      <b-row class="mb-2">
        <MetricAndEventSelectInput
          selectType="Types"
          :key="resetKey"
          class="m-3"
          @selection-changed="updateSelectedMetricAndEvents"
        />
      </b-row>

      <b-form-group label="Selected Device(s)" class="small-text">
        <ul>
          <li v-for="device in formattedSelectedDevices" :key="device">
            {{ device }}
          </li>
          <div v-if="remainingDeviceCount > 0">
            ...and {{ remainingDeviceCount }} more devices
          </div>
        </ul>
      </b-form-group>
    </b-container>

    <template #modal-footer>
      <b-button variant="secondary" @click="modalVisible = false"
        >Cancel</b-button
      >
      <b-button
        variant="primary"
        :disabled="!startDate || !endDate || !selectedMetricAndEvents.length"
        @click="confirmExport"
      >
        Export
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BButton, BContainer, BRow, BFormGroup } from 'bootstrap-vue';
import DateTimePicker from '@/components/event-log/DateTimePicker.vue';
import MetricAndEventSelectInput from '@/components/device/MetricAndEventSelectInput.vue';

export default {
  name: 'DeviceDataExportModal',
  components: {
    DateTimePicker,
    MetricAndEventSelectInput,
    BModal,
    BButton,
    BContainer,
    BRow,
    BFormGroup,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    selectedDevices: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      startDate: null,
      endDate: null,
      selectedMetricAndEvents: [],
      resetKey: 0,
      headerBgVariant: 'black',
      headerTextVariant: 'light',
      bodyBgVariant: 'black',
      bodyTextVariant: 'light',
      footerBgVariant: 'black',
      footerTextVariant: 'light',
    };
  },

  computed: {
    modalVisible: {
      get() {
        return this.showModal;
      },
      set(value) {
        this.$emit('update:showModal', value);
      },
    },

    formattedSelectedDevices() {
      const limit = 10;
      const devices = this.selectedDevices.map((localId) => {
        const device = this.$provider.cache.devicesLocalMap[localId];
        if (device) {
          return `${device.type} ${device.name} (${device.localId})`;
        }
        return localId;
      });
      return devices.slice(0, limit);
    },
    remainingDeviceCount() {
      const limit = 10;
      return Math.max(0, this.selectedDevices.length - limit);
    },
  },

  methods: {
    updateSelectedMetricAndEvents(selections) {
      this.selectedMetricAndEvents = selections;
    },

    confirmExport() {
      this.$emit('exportData', {
        startDate: this.startDate,
        endDate: this.endDate,
        selectedMetricAndEvents: this.selectedMetricAndEvents,
      });
      this.modalVisible = false;
    },
  },
};
</script>

<style scoped>
.small-text {
  font-size: 14px;
}

.time-picker-container {
  display: flex;
}

.to-time {
  margin-left: 20px;
}

@media (max-width: 1000px) {
  .time-picker-container {
    display: block;
    text-align: left;
  }

  .time-picker-container .to-time {
    margin: 12px 0 0 0;
  }
}
</style>
