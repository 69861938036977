import Vue from 'vue';
import Synapse from 'synapse-vue';

const host = {
  name: window.location.hostname,
  httpPort: window.location.port,
  wsPort: 8841,
  isSecure: window.location.protocol == 'https:',
};

const isLocal = host.name == 'localhost';
if (isLocal) {
  // Local dev mode, use a specific address for the backend (edit this as you need)
  Object.assign(host, {
    name: '127.0.0.1', // Synapse backend: 172.16.20.50
    httpPort: 8524,
    wsPort: 8841,
    isSecure: false,
  });
}

Vue.use(Synapse, {
  host,
  enableErrorHandler: !isLocal,
  manualConnection: true,
  auth: 'custom',
});
