<template>
  <div id="canvas-container">
    <div id="canvas"></div>
  </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill'
const PIXI = require('pixi.js');

function clamp(val) { return val > 1 ? 1 : (val < 0 ? 0 : val); }

export default {
  props: {
    resolution: {
      type: Number,
      default: 800,
    },
    margin: {
      type: Number,
      default: 10,
    },
    displayMode: {
      default: 'default',
      validator(value) {
        return ['default', 'fit', 'fill', 'center'].includes(value)
      }
    },
    anchorX: {
      type: Number,
      default: 0.5,
    },
    anchorY: {
      type: Number,
      default: 0.5,
    },
    layoutData: {
      type: Object,
      required: true,
    },
    dataRate: {
      type: Number,
      default: 10,
    },
    highlightID: String,
  },
  data () {
    return {
      spectators: [],
      triangles: {},

      pixiApp: null,
      resizeObserver: null,

      zoomLevel: 200,
      rendererDimensions: { x: 0, y: 0, width: this.resolution, height: this.resolution },
      containerDimensions: { width: this.resolution, height: this.resolution },

      shortcuts: null,
    }
  },
  computed: {
    mapScale () {
      // Meters to pixels ratio (1 meter = <mapScale> pixels), using a "fit" algorithm on the renderer
      if (!this.layoutDimensions.width || !this.layoutDimensions.height) {
        return 1;
      }
      // This is the available room we have to render the map
      let renderSize = {
        width: Math.max(this.rendererDimensions.width - 2 * this.margin, 0),
        height: Math.max(this.rendererDimensions.height - 2 * this.margin, 0),
      };
      // This is how much the canvas is already being scaled by the resize observer
      let canvasScale = {
        x: this.rendererDimensions.width / this.canvasSize.width,
        y: this.rendererDimensions.height / this.canvasSize.height,
      }
      // TODO: Take layout angle into account
      const widthRatio = (renderSize.width / this.layoutDimensions.width) / canvasScale.x;
      const heightRatio = (renderSize.height / this.layoutDimensions.height) / canvasScale.y;
      const useWidth = widthRatio < heightRatio;
      const scale = useWidth ? widthRatio : heightRatio;
      return scale;
    },

    layoutDimensions () {
      const layout = this.layoutData;
      let walls = layout.entities.filter(e => e.type == 'wall');
      if (walls && walls.length) {
        let maxX=0, maxY=0;
        for (let wall of walls) {
          let pos = wall.position.split(',');
          let x = Math.abs(+pos[0]);
          let y = Math.abs(+pos[1]);
          if (x > maxX) maxX = x;
          if (y > maxY) maxY = y;
        }
        return {
          width: 2 * maxX,
          height: 2 * maxY,
        };
      } else {
        return {width: +layout.grid.width, height: +layout.grid.length};
      }
    },

    canvasSize () {
      if (this.layoutDimensions.width > this.layoutDimensions.height) {
        return {
          width: this.resolution,
          height: this.resolution * (this.layoutDimensions.height / this.layoutDimensions.width),
        };
      } else {
        return {
          width: this.resolution * (this.layoutDimensions.width / this.layoutDimensions.height),
          height: this.resolution,
        };
      }
    },
  },
  watch: {
    rendererDimensions (obj) {
      if (this.pixiApp) {
        this.resizeRenderer(obj);
      }
    },
    mapScale () {
      if (this.pixiApp) {
        this.drawLayout(this.layoutData);
        this.drawProps(this.layoutData.entities);
      }
    },
  },
  mounted () {
    let canvasContainer = document.body.querySelector('#canvas-container');
    const app = new PIXI.Application({
      width: this.canvasSize.width,
      height: this.canvasSize.height,
      //resizeTo: canvasContainer,
      antialias: true,
      backgroundAlpha: 0,
    });

    document.body.querySelector('#canvas').appendChild(app.view);

    const root = new PIXI.Container();               // Root container

    const hudContainer = new PIXI.Container();       // Static objects
    const worldRotationOffset = new PIXI.Container();// Offset for world objects
    const worldContainer = new PIXI.Container();     // Movable objects

    const mapContainer = new PIXI.Container();       // Contains the walls and other static objects tied to the layout
    const mapGraphics = new PIXI.Graphics();         // Walls and room layout
    const propGraphics = new PIXI.Graphics();        // Chairs and such
    const pathGraphics = new PIXI.Graphics();        // Path points rendered over the map

    const entitiesContainer = new PIXI.Container();  // Contains moving entities that should be displayed above everything else

    this.shortcuts = {
      mapContainer,
      hudContainer,
      worldRotationOffset,
      worldContainer,
      entitiesContainer,
      mapGraphics,
      propGraphics,
      pathGraphics,
    };

    for (let id in this.shortcuts) {
      this.shortcuts[id].pivot.set(0.5, 0.5);
    }

    root.addChild(worldRotationOffset);
    root.addChild(hudContainer);
    worldRotationOffset.addChild(worldContainer);

    worldContainer.addChild(mapContainer);
    worldContainer.addChild(entitiesContainer);

    mapContainer.addChild(mapGraphics);
    mapContainer.addChild(propGraphics);
    mapContainer.addChild(pathGraphics);

    root.pivot.set(0.5, 0.5);
    root.x = app.screen.width / 2;
    root.y = app.screen.height / 2;
    app.stage.addChild(root);

    entitiesContainer.sortableChildren = true;

    if (this.displayMode != 'default') {
      this.resizeObserver = new ResizeObserver((entries) => {
        let box = entries[0].contentBoxSize[0] || entries[0].contentBoxSize;
        let width = box.inlineSize;
        let height = box.blockSize;

        this.containerDimensions = { width, height };

        if (this.displayMode == 'center') {
          this.rendererDimensions = {
            x: this.anchorX * (width - this.canvasSize.width),
            y: this.anchorY * (height - this.canvasSize.height),
            width: this.canvasSize.width,
            height: this.canvasSize.height,
          };
        } else {
          const widthRatio = width / this.canvasSize.width;
          const heightRatio = height / this.canvasSize.height;
          const useWidth = this.displayMode == 'fit' ? widthRatio <= heightRatio : widthRatio > heightRatio;
          if (useWidth) {
            const ratio = this.canvasSize.height / this.canvasSize.width;
            this.rendererDimensions = {
              x: 0,
              y: this.anchorY * (height - ratio * width),
              width: width,
              height: ratio * width,
            };
          } else {
            const ratio = this.canvasSize.width / this.canvasSize.height;
            this.rendererDimensions = {
              x: this.anchorX * (width - ratio * height),
              y: 0,
              width: ratio * height,
              height: height,
            };
          }
        }
      }).observe(canvasContainer);
    }

    this.drawLayout(this.layoutData);
    this.drawProps(this.layoutData.entities);
    this.resizeRenderer(this.rendererDimensions);

    app.ticker.add((delta) => {
      this.mapTick(delta);
    });

    // This marks us initialized
    this.pixiApp = app;

    this.$emit('ready');
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
    if (this.pixiApp) {
      this.pixiApp.destroy(true, true);
      this.pixiApp = null;
    }
  },
  methods: {
    drawLayout(layout) {
      let walls = layout.entities.filter(e => e.type == 'wall');
      if (walls && walls.length) {
        this.drawWalls(walls);
      } else {
        let path = [];
        path.push({x: -0.5 * layout.grid.width, y: 0.5 * layout.grid.length});
        path.push({x: 0.5 * layout.grid.width, y: 0.5 * layout.grid.length});
        path.push({x: 0.5 * layout.grid.width, y: -0.5 * layout.grid.length});
        path.push({x: -0.5 * layout.grid.width, y: -0.5 * layout.grid.length});
        this.drawWalls(path);
      }
    },
    changeZoom(event) {
      this.zoomLevel = event.target.value;
    },
    mapTick (delta) {
      // const worldContainer = this.shortcuts['worldContainer'];
      // const worldRotationOffset = this.shortcuts['worldRotationOffset'];
      const entitiesContainer = this.shortcuts['entitiesContainer'];

      this.updateTriangles(delta, entitiesContainer, this.spectators);

      // Reset the map
      // worldRotationOffset.angle = -this.layoutData.angle;
      // worldContainer.x = -this.layoutData.x * this.mapScale;
      // worldContainer.y = -this.layoutData.y * this.mapScale;
      // worldRotationOffset.scale.set(1, 1);
    },

    setSpectators (spectators) {
      this.spectators = spectators || [];
    },

    setPathPoints (pastPoints, futurePoints) {
      const pathGraphics = this.shortcuts['pathGraphics'];

      pathGraphics.clear();
      if (!pastPoints.length && !futurePoints.length) {
        return;
      }
      let first = pastPoints[0] || futurePoints[0];
      pathGraphics.moveTo(first.x  * this.mapScale, first.y  * this.mapScale);

      pathGraphics.lineStyle(1, 0x03fcfc, 1);
      for (let point of pastPoints) {
        pathGraphics.lineTo(point.x  * this.mapScale, point.y  * this.mapScale);
      }

      pathGraphics.lineStyle(1, 0x005e5e, 1);
      for (let point of futurePoints) {
        pathGraphics.lineTo(point.x  * this.mapScale, point.y  * this.mapScale);
      }
    },

    drawProps (entities) {
      const propGraphics = this.shortcuts['propGraphics'];

      propGraphics.clear();

      for (let entity of entities) {
        let pos = entity.position.split(',');
        let [x, y] = [pos[0] * this.mapScale, pos[1] * this.mapScale];
        if (entity.type.startsWith('chair')) {
          propGraphics.lineStyle(1, 0xFFFFFF, 1);
          const chairSize = 0.89;
          const radius = chairSize * this.mapScale;
          propGraphics.drawRect(x - radius/2, y - radius/2, radius, radius);
          // let [w, h] = [entity.w * this.mapScale / 100, entity.h * this.mapScale / 100];
          // propGraphics.drawRect(x - w/2, y - h/2, w, h);
        } else if (entity.type == 'wheelchair') {
          propGraphics.lineStyle(1, 0xFFFFFF, 1);
          const chairSize = 0.89;
          const radius = chairSize * this.mapScale;
          propGraphics.drawCircle(x, y, radius/2);
        } else if (entity.type == 'columnCylinder') {
          // TODO: Handle angle
          propGraphics.lineStyle(1, 0xFFFFFF, 1);
          let [w, h] = [entity.w * this.mapScale / 100, entity.h * this.mapScale / 100];
          propGraphics.drawEllipse(x, y, w, h);
        } else if (entity.type == 'columnCube') {
          // TODO: Handle angle
          propGraphics.lineStyle(1, 0xFFFFFF, 1);
          let [w, h] = [entity.w * this.mapScale / 100, entity.h * this.mapScale / 100];
          propGraphics.drawRect(x - w/2, y - h/2, w, h);
        } else if (entity.type == 'arch') {
          // TODO: Handle angle
          propGraphics.lineStyle(0, 0xFFFFFF, 1);
          propGraphics.beginFill(0x5e81a8);
          let [w, h] = [entity.w * this.mapScale / 400, entity.h * this.mapScale / 100];
          propGraphics.drawRect(x - w/2, y - h/2, w, h);
          propGraphics.endFill();
        }
      }
    },

    drawWalls (walls) {
      const mapGraphics = this.shortcuts['mapGraphics'];
      mapGraphics.clear();

      let path = [];
      for (let wall of walls) {
        let pos = wall.position.split(',');
        path.push(pos[0] * this.mapScale, pos[1] * this.mapScale);
      }

      mapGraphics.lineStyle(2, 0xFFFFFF, 1);
      mapGraphics.beginFill(0x39424b);
      mapGraphics.drawPolygon(path);
      mapGraphics.endFill();
    },

    resizeRenderer(dimensions) {
      if (!this.pixiApp) {
        return;
      }

      // NOTE: This will create errors in the ResizeObserver if the renderer
      // attempts to resize its parent
      this.pixiApp.renderer.view.style.width = dimensions.width + 'px';
      this.pixiApp.renderer.view.style.height = dimensions.height + 'px';
      this.pixiApp.renderer.view.style.marginLeft = dimensions.x + 'px';
      this.pixiApp.renderer.view.style.marginTop = dimensions.y + 'px';
    },

    updateTriangles(delta, container, spectators, zoomFactor=1) {
      let scale = zoomFactor > 0 ? 1 / zoomFactor : 1;

      // Convert to seconds
      let dataDelta = 1 / this.dataRate;
      let localDelta = delta / 1000;
      let dampFactor = localDelta / dataDelta;

      // Remove all triangles by default, then ignore the ones we find
      let idsToRemove = [];
      for (let id in this.triangles) {
        idsToRemove.push(id);
      }

      for (let spectator of spectators) {
        let id = spectator.id;

        let triangle = this.triangles[id];

        // Null colors are overriden locally
        if (triangle && spectator.color != null && triangle.color != spectator.color) {
          container.removeChild(triangle);
          triangle = null;
          delete this.triangles[id];
        }

        let targetX = spectator.x * this.mapScale;
        let targetY = spectator.y * this.mapScale;
        let targetAngle = spectator.angle;

        if (!triangle) {
          // Create the triangle where it should be
          triangle = this.createTriangle(spectator.color, spectator.id, spectator.z);
          container.addChild(triangle);
          this.triangles[id] = triangle;

          triangle.x = targetX;
          triangle.y = targetY;
          triangle.angle = targetAngle;
        } else {
          // Smooth the data
          triangle.x = triangle.x + (targetX - triangle.x) * clamp(0.3 * Math.abs(targetX - triangle.x) * dampFactor);
          triangle.y = triangle.y + (targetY - triangle.y) * clamp(0.3 * Math.abs(targetY - triangle.y) * dampFactor);
          // Handle 360 degrees lerp (uglyyyyy)
          let startAngle = triangle.angle;
          if (targetAngle - triangle.angle <= -180) {
            startAngle -= 360;
          } else if (targetAngle - triangle.angle >= 180) {
            startAngle += 360;
          }
          triangle.angle = startAngle + (targetAngle - startAngle) * clamp(Math.abs(targetAngle - startAngle) * dampFactor);
        }

        triangle.scale.set(scale, scale);

        let shouldHighlight = id == this.highlightID;
        if (triangle.highlightCircle.visible != shouldHighlight) {
          triangle.highlightCircle.visible = shouldHighlight;
        }

        let index = idsToRemove.indexOf(id);
        if (index >= 0) {
          idsToRemove.splice(index, 1);
        }
      }

      for (let id of idsToRemove) {
        container.removeChild(this.triangles[id]);
        delete this.triangles[id];
      }
    },

    createTriangle(color, id, zIndex) {
      const triangle = new PIXI.Graphics();

      if (!color) {
        color = '#000000';
      }

      // draw triangle
      triangle.color = color;
      triangle.beginFill(parseInt('0x' + color.substring(1)), 1);
      triangle.lineStyle(0);
      const shape = [0, -13, -10, 13, 10, 13];
      triangle.drawPolygon(shape);
      triangle.endFill();

      triangle.zIndex = zIndex || 0;

      // highlight
      triangle.highlightCircle = new PIXI.Graphics();
      triangle.highlightCircle.lineStyle(0);
      triangle.highlightCircle.beginFill(0x999999, 0.5);
      triangle.highlightCircle.drawCircle(0, 0, 25);
      triangle.highlightCircle.endFill();
      triangle.highlightCircle.visible = false;
      triangle.addChild(triangle.highlightCircle);

      if (id) {
        triangle.interactive = true;
        triangle.buttonMode = true;
        triangle.hitArea = new PIXI.Rectangle(-25, -25, 50, 50);
        triangle.name = id;
        triangle.on('pointertap', () => {
          this.$emit('spectator-selected', { id });
        });
      }

      return triangle;
    },
  },
}
</script>
