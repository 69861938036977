<template>
  <div class="wrapper">
    <h2 class="m-4">Dashboard</h2>
    <section class="d-flex m-4">
      <DateTimePicker
        label="From"
        type="date"
        v-model="selectedFromDate"
        @change="convertFromDateToUTC"
        :disabled="showLoading || noServerSession"
      />
      <DateTimePicker
        class="ml-3"
        label="To"
        type="date"
        v-model="selectedToDate"
        @change="convertToDateToUTC"
        :disabled="showLoading || noServerSession"
      />
      <b-button @click="handleSubmit" class="button" variant="dark" :disabled="showLoading || noServerSession"
        >Submit</b-button
      >
      <HintModal ref="hintModal" :message="modalMessage" @ok="createDashboardContent"></HintModal>
    </section>

    <div>
      <HintMessage
        v-if="
          showLoading ||
          noServerSession ||
          showNoData ||
          showDashboardStillCreating ||
          showDatesBeyondToday ||
          showSelectedHint ||
          selectedFromDate === null ||
          selectedToDate === null
        "
        :message="getHintMessage"
        :isLoading="showLoading"
        :type="showNoData ? 'no-data' : ''"
      />

      <div v-if="apiData && apiData.status === 'completed' && apiData.content !== null">
        <div class="m-4" v-for="(items, sectionName) in dashboardTemplate" :key="sectionName">
          <h2>{{ sectionName }}</h2>
          <div class="section-container">
            <div
              v-for="(item, index) in items"
              :key="index"
              :class="
                item.type === 'label'
                  ? 'label-table-group'
                  : item.type === 'pieChart'
                  ? 'section-item pie-chart-item'
                  : 'section-item'
              "
            >
              <!-- :colors="getColors(items)" -->
              <component
                :is="getComponentName(item.type)"
                :title="sectionName"
                :siteId="siteId"
                :startDate="utcFromDate"
                :endDate="utcToDate"
                :apiData="apiData"
                v-bind="item.content"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateTimePicker from '@/components/event-log/DateTimePicker.vue';
import DashboardTable from '@/components/dashboard/DashboardTable.vue';
import DashboardDeviceTable from '@/components/dashboard/DashboardDeviceTable.vue';
import DashboardMetric from '@/components/dashboard/DashboardMetric.vue';
import DashboardPieChart from '@/components/dashboard/DashboardPieChart.vue';
import DashboardBarChart from '@/components/dashboard/DashboardBarChart.vue';
import HintMessage from '@/components/HintMessage.vue';
import HintModal from '@/components/HintModal.vue';
import { dashboardTemplate } from '@/helper/dashboardTemplate.js';
import { BButton } from 'bootstrap-vue';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

export default {
  name: 'DashboardPage',

  components: {
    DateTimePicker,
    DashboardTable,
    DashboardDeviceTable,
    DashboardMetric,
    DashboardPieChart,
    DashboardBarChart,
    BButton,
    HintModal,
    HintMessage,
  },

  data() {
    return {
      dashboardTemplate,
      selectedFromDate: null,
      selectedToDate: null,
      utcFromDate: null,
      utcToDate: null,
      apiData: null,
      modalMessage:
        'A dashboard for this time period has not been created yet. It may take a moment to generate. Start now?',
      showSelectedHint: false,
      showLoading: false,
      noServerSession: false,
      showHintModal: false,
      showDashboardStillCreating: false,
      showNoData: false,
      showDatesBeyondToday: false,
      lastActiveDayFrom: null,
      lastActiveDayTo: null,
    };
  },

  computed: {
    selectedSite() {
      return this.$provider.selection.site;
    },
    siteId() {
      return this.selectedSite?.siteId;
    },
    timeZone() {
      return this.selectedSite?.timeZone;
    },
    getHintMessage() {
      if (this.showLoading) return 'loading';
      if (this.noServerSession) return 'There is no server session';
      if (this.showNoData) return 'No data matched the selected criteria';
      if (this.showDashboardStillCreating)
        return 'Dashboard still generating; please wait until it is generated, then try again';
      if (this.showDatesBeyondToday) return 'Date exceeds limit. Select up to yesterday.';
      if (this.showSelectedHint) return 'Required fields: from, to';
      if (this.selectedFromDate === null || this.selectedToDate === null) return 'Required fields: from, to';
      return '';
    },
  },

  async mounted() {
    const query = this.$route.query;

    if (!query.from && !query.to) {
      await this.fetchLastActiveData();
    }

    if (!this.lastActiveDayFrom && !this.lastActiveDayTo) {
      await this.fetchLastActiveData();
    }

    if (query.from && query.to) {
      this.selectedFromDate = this.createLocalDate(query.from);
      this.selectedToDate = this.createLocalDate(query.to);

      this.utcFromDate = this.convertFromDateToUTC(this.selectedFromDate);
      this.utcToDate = this.convertToDateToUTC(this.selectedToDate);
      await this.handleSubmit();
    }
  },

  methods: {
    getComponentName(type) {
      switch (type) {
        case 'table':
          return 'DashboardTable';
        case 'deviceTable':
          return 'DashboardDeviceTable';
        case 'metric':
          return 'DashboardMetric';
        case 'pieChart':
          return 'DashboardPieChart';
        case 'barChart':
          return 'DashboardBarChart';
        default:
          return null;
      }
    },

    getColors(items) {
      const pieChart = items.find((item) => item.type === 'pieChart');
      const barChart = items.find((item) => item.type === 'barChart');

      const pieChartColors = pieChart?.content?.colors || [];
      const barChartColors = barChart?.content?.colors || [];

      return [...pieChartColors, ...barChartColors];
    },

    createLocalDate(dateString) {
      const [year, month, day] = dateString.split('-').map(Number);
      return new Date(year, month - 1, day, 0, 0, 0);
    },

    convertFromDateToUTC(date) {
      date.setHours(0, 0, 0, 0);
      return fromZonedTime(date, this.timeZone);
    },

    convertToDateToUTC(date) {
      date.setHours(23, 59, 59, 999);
      return fromZonedTime(date, this.timeZone);
    },

    updateQuery() {
      if (this.isBeyondLastDate(this.selectedFromDate, this.lastActiveDayFrom)) return;
      if (this.isBeyondLastDate(this.selectedToDate, this.lastActiveDayTo)) return;

      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

      const query = {
        from: this.selectedFromDate ? this.selectedFromDate.toLocaleDateString('en-CA', options) : undefined,
        to: this.selectedToDate ? this.selectedToDate.toLocaleDateString('en-CA', options) : undefined,
      };

      this.$router.replace({ query }).catch(() => {});
    },

    isBeyondLastDate(selectedDate, lastActiveDate) {
      const selectedInTargetTimeZone = toZonedTime(selectedDate, this.timeZone);
      const lastActiveInTargetTimeZone = toZonedTime(lastActiveDate, this.timeZone);

      if (selectedInTargetTimeZone > lastActiveInTargetTimeZone) {
        return true;
      }

      return false;
    },

    async fetchDashboardContent() {
      this.showSelectedHint = false;
      this.showLoading = true;
      this.showDashboardStillCreating = false;
      this.showNoData = false;
      this.apiData = null;

      try {
        const res = await this.$ynapse.GET('/api/v1/dashboard/get-dashboard-content', {
          siteId: this.siteId,
          startDate: this.utcFromDate,
          endDate: this.utcToDate,
        });

        if (res.status === 202) {
          this.showDashboardStillCreating = true;
          return;
        }

        if (res.data?.message === 'The content is empty.') {
          this.showNoData = true;
          this.showLoading = false;
          this.apiData = res.data;
          return;
        } else {
          this.apiData = res.data;
        }
        return this.apiData;
      } catch (error) {
        console.error('Error fetching dashboard content:', error);
      } finally {
        this.showLoading = false;
      }
    },

    async createDashboardContent() {
      this.showSelectedHint = false;
      this.showLoading = true;
      this.showDashboardStillCreating = false;
      this.showNoData = false;
      this.apiData = null;

      try {
        const res = await this.$ynapse.POST('/api/v1/dashboard/add-dashboard-content', {
          siteId: this.siteId,
          startDate: this.utcFromDate,
          endDate: this.utcToDate,
        });

        if (res.status === 202) {
          this.showDashboardStillCreating = true;
          return;
        }

        if (res.data && res.data.message === 'The content is empty.') {
          this.showNoData = true;
          this.showLoading = false;
          this.apiData = res.data;
        } else {
          this.apiData = res.data;
        }
        return this.apiData;
      } catch (error) {
        console.error('Error creating dashboard content:', error);
      } finally {
        this.showLoading = false;
      }
    },

    async handleSubmit() {
      if (this.isBeyondLastDate(this.selectedFromDate, this.lastActiveDayFrom)) {
        this.showDatesBeyondToday = true;
        return;
      }
      if (this.isBeyondLastDate(this.selectedToDate, this.lastActiveDayTo)) {
        this.showDatesBeyondToday = true;
        return;
      }

      if (this.selectedFromDate === null || this.selectedToDate === null) {
        this.showSelectedHint = true;
        return;
      }

      if (this.selectedToDate < this.selectedFromDate) {
        const temp = this.selectedFromDate;
        this.selectedFromDate = this.selectedToDate;
        this.selectedToDate = temp;
      }

      this.showLoading = true;
      this.showHintModal = false;
      this.apiData = null;
      try {
        this.utcFromDate = this.convertFromDateToUTC(this.selectedFromDate);
        this.utcToDate = this.convertToDateToUTC(this.selectedToDate);

        this.apiData = await this.fetchDashboardContent();

        if (this.apiData === null) {
          this.showHintModal = true;
          this.$refs.hintModal.open(this.modalMessage);
        }
      } catch (error) {
        console.log('Error:', error);
      } finally {
        this.showLoading = false;
        this.showDatesBeyondToday = false;
      }
    },

    // todo with debounce(?): when user re select the site in SitePickerOverlay, it triggers the api twice since it refresh the page
    // Not sure which way is better to solve this problem, it only happens when user re select the site
    async fetchLastActiveData() {
      this.showSelectedHint = false;
      this.showLoading = true;
      this.apiData = null;

      try {
        const res = await this.$ynapse.GET('/api/v1/dashboard/last-active-day', { siteId: this.siteId });

        if (res.data) {
          const { startOfLastDaySiteTime, endOfLastDaySiteTime } = res.data;

          const localFromDate = new Date(startOfLastDaySiteTime);
          const localToDate = new Date(endOfLastDaySiteTime);
          this.lastActiveDayFrom = localFromDate;
          this.lastActiveDayTo = localToDate;

          // last server session in montreal lasts for 28 hours
          const msDifference = localToDate - localFromDate;
          const hoursDifference = msDifference / (1000 * 60 * 60);

          const UTCFromDate = fromZonedTime(localFromDate, this.timeZone);
          const UTCToDate = fromZonedTime(localToDate, this.timeZone);

          this.selectedFromDate = localFromDate;
          this.selectedToDate = localToDate;

          if (hoursDifference > 24) {
            this.utcFromDate = new Date(startOfLastDaySiteTime);
            this.utcToDate = new Date(endOfLastDaySiteTime);
          } else {
            this.utcFromDate = UTCFromDate;
            this.utcToDate = UTCToDate;
          }

          this.apiData = await this.fetchDashboardContent();

          if (this.apiData === null) {
            this.apiData = await this.createDashboardContent();
          }
        } else {
          this.noServerSession = true;
        }
      } catch (error) {
        console.log('Error:', error);
      } finally {
        this.showLoading = false;
      }
    },
  },
  watch: {
    selectedFromDate(newDate) {
      if (this.isBeyondLastDate(newDate, this.lastActiveDayFrom)) {
        this.apiData = null;
        return;
      }
      if (newDate) {
        this.selectedFromDate = newDate;
      }
      this.updateQuery();
      this.apiData = null;
    },
    selectedToDate(newDate) {
      if (this.isBeyondLastDate(newDate, this.lastActiveDayTo)) {
        this.apiData = null;
        return;
      }
      if (newDate) {
        this.selectedToDate = newDate;
      }

      this.updateQuery();
      this.apiData = null;
    },
  },
};
</script>

<style>
.button {
  margin-left: 8px;
  font-size: 14px;
}

.alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1050;
}

.section-container {
  display: flex;
  flex-wrap: wrap;
}

.label-table-group {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}

.pie-chart-item {
  flex: 1 1 auto;
}

@media (min-width: 1024px) {
  .section-item {
    flex: 1 1 50%;
  }

  .label-table-group {
    flex: 1 1 100%;
  }

  .pie-chart-item {
    flex: 1 1 20%;
  }
}
</style>
