<template>
  <div
    :class="`circle bg-${variant}`"
    v-b-tooltip="event.label || event.type"
    :style="{'z-index': 5 + event.severity}"
    @click="$emit('select')"
  />
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';

export default {
  name: 'EventCircle',
  props: { event: Object },
  computed: {
    variant () {
      if (this.event.severity >= 4) {
        return 'danger';
      } else if (this.event.severity >= 3) {
        return 'bad';
      } else if (this.event.severity >= 2) {
        return 'warning';
      } else if (this.event.severity >= 1) {
        return 'white';
      } else {
        return 'light-grey';
      }
    }
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
};
</script>

<style scoped>
.circle {
  display: inline-block;
  border-radius: 50%;
  padding: 0;
  border: solid 1px black;
  opacity: 1;
}
</style>
