<template>
  <div>
    <div class="monitoring-speed-control">
      <div class="mx-4">
        <TimelineControlButton
          :icon="isPlaying ? 'pause' : 'play'"
          :isEnabled="isPlaying"
          @click="togglePlay"
        />
        <TimelineControlButton
          :isEnabled="playSpeed == 3"
          @click="setPlaySpeed(3)"
        >
          3x
        </TimelineControlButton>
        <TimelineControlButton
          :isEnabled="playSpeed == 10"
          @click="setPlaySpeed(10)"
        >
          10x
        </TimelineControlButton>
      </div>
      <div class="d-flex flex-grow-1">
        <TimelineControlButton icon="backward" @click="previousEvent" />
        <v-select
          v-model="selectedEvent"
          :options="eventKeyframes"
          :clearable="false"
          class="mr-3 flex-grow-1"
          placeholder="Select an event"
          style="min-width: 160px"
          @input="selectEvent"
        />
        <TimelineControlButton icon="forward" @click="nextEvent" />
      </div>

      <TimelineControlButton
        v-if="live"
        :isEnabled="followLive"
        @click="toggleLive"
      >
        LIVE
      </TimelineControlButton>
      <div v-else style="width: 80px" />
    </div>

    <div v-if="startDate" class="mt-2">
      <div class="d-flex justify-content-between text-content">
        <span>{{ startDate.toLocaleTimeString('en-US', { timeZone }) }}</span>
        <span>{{
          new Date(currentTime).toLocaleTimeString('en-US', { timeZone })
        }}</span>
        <span>{{
          live ? 'LIVE' : endDate.toLocaleTimeString('en-US', { timeZone })
        }}</span>
      </div>

      <div class="input-range-container">
        <input
          ref="timeTrack"
          class="time-track w-100"
          type="range"
          :value="currentTime"
          :min="startDate.getTime()"
          :max="live ? Date.now() : endDate.getTime()"
          @input="dragTime"
        />
        <div class="circles-container">
          <EventCircle
            v-for="(event, index) in eventKeyframes"
            :key="index"
            :style="calculateEventCirclePosition(event)"
            class="event-circle"
            :event="event"
            @select="selectEvent(event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Clock } from 'synapse-vue';
import TimelineControlButton from '@/components/monitoring/TimelineControlButton.vue';
import vSelect from 'vue-select';
import EventCircle from '@/components/EventCircle.vue';

export default {
  name: 'ExperienceTimeline',
  mixins: [Clock(100, 'updateTimeline')],
  components: { TimelineControlButton, vSelect, EventCircle },
  props: {
    startDate: Date,
    endDate: Date,
    time: Number,
    live: Boolean,
    timeZone: String,
    events: Array,
  },
  model: { prop: 'time', event: 'time' },
  data() {
    return {
      currentTime: this.live ? Date.now() : this.startDate.getTime(),
      isPlaying: false,
      playSpeed: 1,
      followLive: !!this.live,
      selectedEventId: null,
      selectedEvent: null,
      inputWidth: 0,

      playbackData: {
        startRealTime: null,
        startPlaybackTime: null,
      },
    };
  },
  mounted() {
    this.updateInputWidth();
    window.addEventListener('resize', this.updateInputWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateInputWidth);
  },
  computed: {
    eventKeyframes() {
      if (!this.events) {
        return [];
      }
      return this.events.map((ev) => {
        return Object.assign(
          {
            label: `[${ev.source == 'System' ? 'System' : ev.user}] ${new Date(
              ev.time,
            ).toLocaleTimeString('en-US', { timeZone: this.timeZone })} - ${
              ev.type
            }`,
          },
          ev,
        );
      });
    },
  },
  watch: {
    currentTime(value) {
      this.$emit('time', value);
    },
  },
  methods: {
    beginPlayback() {
      this.playbackData.startRealTime = Date.now();
      this.playbackData.startPlaybackTime =
        this.currentTime || this.startDate.getTime();
    },
    togglePlay() {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        this.beginPlayback();
      } else {
        this.followLive = false;
        this.playSpeed = 1;
      }
    },
    setPlaySpeed(speed) {
      this.beginPlayback();
      this.isPlaying = true;
      this.followLive = false;
      if (this.playSpeed == speed) {
        this.playSpeed = 1;
      } else {
        this.playSpeed = speed;
      }
    },
    previousEvent() {
      this.isPlaying = false;
      this.followLive = false;
      if (this.selectedEventId == null) {
        this.selectedEventId = this.events.length - 1;
      } else if (this.selectedEventId > 0) {
        this.selectedEventId--;
      }
      this.seekCurrentEventID();
    },
    seekCurrentEventID() {
      this.isPlaying = false;
      this.followLive = false;

      let ev = this.events[this.selectedEventId];
      if (ev) {
        this.currentTime = new Date(ev.time).getTime();
        this.selectedEvent = this.eventKeyframes[this.selectedEventId];
      }
    },
    selectEvent(ev) {
      this.selectedEventId = this.eventKeyframes.indexOf(ev);
      this.seekCurrentEventID();
      if (ev.user) {
        this.$emit('spectator-selected', {id: ev.user});
      }
    },
    nextEvent() {
      this.isPlaying = false;
      this.followLive = false;
      if (this.selectedEventId == null) {
        this.selectedEventId = 0;
      } else if (this.selectedEventId < this.events.length - 1) {
        this.selectedEventId++;
      }
      this.seekCurrentEventID();
    },
    toggleLive() {
      this.followLive = !this.followLive;
      this.isPlaying = this.followLive;
      this.playSpeed = 1;
    },
    dragTime(ev) {
      this.isPlaying = false;
      this.currentTime = +ev.target.value;
      this.updateSelectedEvent();
    },
    updateTimeline() {
      if (!this.isPlaying) {
        return;
      }
      const now = Date.now();
      const elapsed = now - this.playbackData.startRealTime;
      this.currentTime =
        this.playbackData.startPlaybackTime + elapsed * this.playSpeed;
      const max = this.live ? Date.now() : this.endDate.getTime();
      if (!this.followLive && this.currentTime > max) {
        this.playSpeed = 1;
        this.isPlaying = false;
      }
      this.updateSelectedEvent();
    },
    updateSelectedEvent() {
      if (this.eventKeyframes.length == 0) {
        return;
      }
      let id = 0;
      while (
        id < this.eventKeyframes.length &&
        new Date(this.eventKeyframes[id].time).getTime() <= this.currentTime
      ) {
        id++;
      }
      if (id <= this.eventKeyframes.length) {
        this.selectedEventId = id - 1;
        this.selectedEvent = this.eventKeyframes[id - 1];
      }
    },
    updateInputWidth() {
      if (this.$refs.timeTrack) {
        this.inputWidth = this.$refs.timeTrack.clientWidth;
      }
    },
    calculateEventCirclePosition(event) {
      if (!this.inputWidth) return {};

      const minTime = this.startDate.getTime();
      const maxTime = this.live ? Date.now() : this.endDate.getTime();
      const eventTime = new Date(event.time).getTime();
      const positionPercentage =
        ((eventTime - minTime) / (maxTime - minTime)) * 100;
      const positionPx = (this.inputWidth * positionPercentage) / 100;

      return {
        left: `calc(${positionPx}px - 7.5px)`,
      };
    },
  },
};
</script>

<style scoped>
.monitoring-speed-control {
  display: flex;
  justify-content: space-between;
}

.time-track:hover {
  cursor: pointer;
}

.time-track {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 2px;
  background: var(--borders);
  outline: none;
  transition: opacity 0.2s;
  position: relative;
  z-index: 1;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 6px;
    height: 32px;
    background: var(--white);
    cursor: pointer;
    border-radius: 0;
    position: relative;
    z-index: 3;
  }

  &::-moz-range-thumb {
    width: 6px;
    height: 32px;
    background: var(--white);
    cursor: pointer;
    border-radius: 0;
    position: relative;
    z-index: 3;
  }
}

.input-range-container {
  position: relative;
}

.event-circle {
  position: absolute;
  top: 11px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}
</style>
