<template>
  <div>this is admin page</div>
</template>

<script>
export default {
  name: 'AdminPage',
  components: {},

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
</style>
