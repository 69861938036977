<template>
  <div class="user-search">
    <label class="mr-2 label">{{ searchType }}</label>
    <div class="custom-input d-flex" @click.self="$refs['input'].focus()">
      <div class="tags-container">
        <div
          class="tag"
          v-for="(tag, index) in tags"
          :key="index"
          variant="secondary"
        >
          {{ tag }}
          <button @click="deleteTag(index)" class="delete-button">x</button>
        </div>

        <input
          ref="input"
          v-model="inputValue"
          :style="{ width: 20 + 6 * inputValue.length + 'px' }"
          @input="handleInput"
          @keyup.enter="addTag"
          @keydown.delete="deleteLastTag"
          class="input-container"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserSearch',

  props: {
    searchType: {
      type: String,
      default: '',
    },
    value: [],
  },

  model: {
    prop: 'value',
    event: 'tags-input',
  },

  data() {
    return {
      inputValue: '',
      tags: this.value || [],
    };
  },

  watch: {
    tags(newTags) {
      this.$emit('tags-input', newTags);
      this.inputValue = '';
    },
  },

  methods: {
    addTag() {
      if (this.inputValue.trim() !== '') {
        this.tags.push(this.inputValue.trim());
        this.inputValue = '';
      }
    },

    handleInput() {
      this.$emit('current-input', this.inputValue);
    },

    deleteTag(index) {
      this.tags.splice(index, 1);
      this.inputValue = '';
      this.$emit('tags-input', this.tags);
      this.$emit('current-input', this.inputValue);
    },

    deleteLastTag() {
      if (this.inputValue === '' && this.tags.length > 0) {
        this.deleteTag(this.tags.length - 1);
      }
    },
  },
};
</script>

<style scoped>
.user-search {
  display: flex;
  align-items: center;
}

.label {
  font-size: 14px;
}

.custom-input {
  background-color: var(--black);
  border: 1px solid var(--borders);
  border-radius: 4px;
  padding: 2px;
  font-size: 12px;
  width: 100%;
  min-width: 160px;
  cursor: text;
}

.input-container {
  border: none;
  outline: none;
  padding: 2px;
  background-color: black;
  color: white;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.tag {
  display: flex;
  background-color: var(--dark-grey);
  color: var(--light-grey);
  border-radius: 2px;
  padding: 2px;
  align-items: baseline;
}

.delete-button {
  background-color: var(--dark-grey);
  color: var(--light-grey);
  border: none;
}
</style>
