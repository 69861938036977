<template>
  <header class="header" v-if="selectedSite">
    <div class="header-left">
      <SiteStatusColor
        :statusRoute="statusRoute"
        width="30px"
        height="30px"
      />
      <SelectedSite
        @toggle="$emit('toggleOverlay')"
        :selectedSite="selectedSite"
      />
    </div>

    <div class="header-right">
      <LocalTime :timeZone="selectedSite.timeZone" />
      <SearchBar :selectedSite="selectedSite" />
      <UserInfo />
    </div>
  </header>
</template>

<script>

import SiteStatusColor from '@/components/navbar/SiteStatusColor.vue';
import SelectedSite from '@/components/navbar/SelectedSite.vue';
import LocalTime from '@/components/navbar/LocalTime.vue';
import SearchBar from '@/components/navbar/SearchBar.vue';
import UserInfo from '@/components/navbar/UserInfo.vue';

export default {
  name: 'NavBar',

  components: {
    SiteStatusColor,
    SelectedSite,
    UserInfo,
    LocalTime,
    SearchBar,
  },

  computed: {
    statusRoute() {
      if (this.selectedSite && this.selectedSite.servers.length) {
        return `/sites/${this.selectedSite.servers[0].id}/status`;
      }
      return null;
    },
    selectedSite() {
      return this.$provider.selection.site;
    },
  },
};
</script>

<style scoped>
.header {
  background-color: var(--black);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--white);
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
}
</style>
