<template>
  <div class="metric-category-wrapper">
    <h3 class="mx-4">> {{ categoryName.replace(/\./g, ' > ') }}</h3>

    <div class="metric-wrapper">
      <MetricStatus
        v-for="metric in metrics"
        :key="`${metric.name}_${metric.route}`"
        :metric="metric"
        :route="metric.route"
        :categoryName="categoryName"
      />
    </div>
  </div>
</template>

<script>
import MetricStatus from '@/components/metric-status/MetricStatus.vue';

export default {
  name: 'MetricCategoryWrapper',
  components: { MetricStatus },
  props: {
    metrics: {
      type: Array,
    },
    categoryName: {
      type: String,
    },
  },
};
</script>

<style scoped>
.metric-category-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.metric-wrapper {
  display: flex;
  flex-wrap: wrap;
}
</style>
