<template>
  <b-modal
    ref="myModal"
    @ok="confirmAction"
    @cancel="cancelAction"
    content-class="border border-borders rounded"
    hide-header
    footer-bg-variant="container"
    body-bg-variant="container"
    footer-text-variant="header"
    body-text-variant="header"
    centered
    :ok-only="okOnly"
    :hide-footer="!localShowButtons"
  >
    {{ message || localMessage }}
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';

export default {
  name: 'HintModal',
  components: {
    BModal,
  },

  props: {
    message: String,
    okOnly: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      localMessage: '',
      localShowButtons: this.showButtons,
    };
  },

  methods: {
    open(message, showButtons = true) {
      this.localMessage = message;
      this.localShowButtons = showButtons;
      this.$refs.myModal.show();
    },

    close() {
      this.$refs.myModal.hide();
    },

    confirmAction() {
      this.$emit('ok');
    },

    cancelAction() {
      this.$emit('cancel');
    },
  },

  watch: {
    showButtons(newVal) {
      this.localShowButtons = newVal;
    },
  },
};
</script>
