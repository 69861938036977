<template>
  <div class="condition d-flex w-100 align-items-center">
    <b-form-input
      class="filter-input"
      v-model="field"
      trim
      placeholder="Field"
    />
    <v-select
      class="filter-operator"
      v-model="operator"
      :options="operatorOptions"
      :clearable="false"
      :filterable="false"
      placeholder="Operator"
    />
    <b-form-input
      v-if="!ignoreValue"
      class="filter-input"
      v-model="value"
      trim
      placeholder="Value"
    />
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

const operatorOptions = [
  {
    id: '=',
    label: 'equal to',
  },
  {
    id: '!=',
    label: 'not equal to',
  },
  {
    id: '>',
    label: 'greater than',
  },
  {
    id: '>=',
    label: 'greater or equal',
  },
  {
    id: '<',
    label: 'less than',
  },
  {
    id: '<=',
    label: 'less or equal',
  },
  {
    id: '&',
    label: 'contains',
  },
  {
    id: '!&',
    label: 'does not contain',
  },
  {
    id: '?',
    label: 'is null',
    noValue: true,
  },
  {
    id: '+',
    label: 'is not null',
    noValue: true,
  },
];

export default {
  name: 'FilterCondition',
  components: {
    BFormInput,
    vSelect,
  },
  props: {
    condition: Object,
  },
  // model: { prop: 'condition', event: 'change' },
  data() {
    return {
      field: this.condition.field || '',
      operator: operatorOptions.find(i => i.id == this.condition.operator),
      value: this.condition.value || '',

      operatorOptions,
    };
  },

  computed: {
    ignoreValue () {
      return this.operator && this.operator.noValue;
    },
    isValid () {
      return this.field != ''
        && this.operator != null
        && (this.value != '' || this.operator.noValue);
    },
  },
  watch: {
    field: 'updateValue',
    operator: 'updateValue',
    value: 'updateValue',
  },
  methods: {
    updateValue () {
      this.$emit('change', {
        field: this.field,
        operator: this.operator ? this.operator.id : '',
        value: this.ignoreValue ? '' : this.value,
        isValid: this.isValid,
      });
    }
  },
};
</script>

<style scoped>
.condition * {
  font-size: 12px;
  flex-basis: 0;
}

.filter-operator {
  --vs-selected-color: var(--white);
  --vs-font-size: 12px;
  --vs-line-height: 1rem;
  flex-grow: 4;
}

.filter-input {
  background-color: var(--black);
  color: var(--white);
  border-radius: 4px;
  height: 2rem;
  border-color: var(--borders);
  flex-grow: 2;
}

.filter-input::placeholder {
  color: var(--light-grey);
}
</style>
