import { render, staticRenderFns } from "./InventoryPage.vue?vue&type=template&id=458f7825&scoped=true"
import script from "./InventoryPage.vue?vue&type=script&lang=js"
export * from "./InventoryPage.vue?vue&type=script&lang=js"
import style0 from "./InventoryPage.vue?vue&type=style&index=0&id=458f7825&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "458f7825",
  null
  
)

export default component.exports