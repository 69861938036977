<template>
  <div id="app" class="d-flex flex-column">
    <AuthProvider
      @auth-verified="authenticating = false"
      @auth-lost="authenticating = true; showOverlay = false;"
    />
    <DataProvider
      @busy="fetchingData = true"
      @ready="fetchingData = false"
    />

    <div v-if="authenticating" class="auth-overlay">
      <div class="loader">
        <b-spinner
          variant="light"
          style="width: 10rem; height: 10rem"
        />
      </div>
    </div>

    <NavBar @toggleOverlay="showOverlay = !showOverlay"/>

    <SitePickerOverlay
      v-if="showOverlay"
      @close="showOverlay = false"
      @site-selected="showOverlay = false"
      :title="siteOverviewTitle"
      :showCloseButton="true"
      ref="siteWithTitleComponent"
    />
    <main v-else class="d-flex flex-grow-1" style="overflow: hidden;">
      <SidebarMenu />
      <router-view style="overflow: auto;"> </router-view>
    </main>
  </div>
</template>

<script>
import AuthProvider from '@/components/providers/AuthProvider.vue';
import DataProvider from '@/components/providers/DataProvider.vue';
import NavBar from '@/components/navbar/NavBar.vue';
import SidebarMenu from '@/components/SidebarMenu.vue';
import SitePickerOverlay from '@/components/site-overview/SitePickerOverlay.vue';
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'App',
  components: {
    AuthProvider,
    DataProvider,
    NavBar,
    SidebarMenu,
    SitePickerOverlay,
    BSpinner,
  },
  data() {
    return {
      authenticating: true,
      fetchingData: false,

      showOverlay: false,
      siteOverviewTitle: 'Switch to another server',
    };
  },
};
</script>

<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
  background-color: var(--mainBg);
  color: var(--white);
  letter-spacing: 1px;
  font-family: 'Phi-Regular', sans-serif;
}
</style>

<style scoped>
.auth-overlay {
  background-color: #00000088;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  text-align: center;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>
