<template>
  <div>
    <b-alert
      :show="alertMessage != null"
      dismissible
      fade
      @dismissed="alertMessage = null"
    >
      {{alertMessage}}
    </b-alert>
  </div>
</template>

<script>
import Vue from 'vue';
import { BAlert } from 'bootstrap-vue'
import { API_ONE_DEVICE, API_ALL_DEVICE_SESSIONS } from '@/helper/api.js';
import { mapState } from 'vuex';

export default {
  components: {
    BAlert
  },
  created () {
    Vue.prototype.$provider = this;
  },
  data () {
    return {
      alertMessage: null,
      pendingRequests: 0,
      selection: {
        site: null,
        systemMetric: null,
        deviceData: null,
        deviceSession: null,
        monitoringDate: null,
        serverSession: null,
      },
      cache: {
        profile: null,
        sites: null,
        metrics: null,
        metricsMap: {},
        eventTypes: null,
        eventTypesMap: {},
        devices: null,
        devicesMap: {},
        devicesLocalMap: {},
        serverSessionData: null,
        deviceSessions: null,
      }
    }
  },
  computed: {
    ...mapState(['authentication']),

    busy () {
      return this.pendingRequests > 0;
    },

    siteDataReady () {
      return this.selection.site != null
        && this.cache.metrics != null
        && this.cache.eventTypes != null
        && this.cache.devices != null;
    },
  },
  watch: {
    pendingRequests (value, old) {
      if (old == 0 && value > 0) {
        this.$emit('busy');
      } else if (old > 0 && value == 0) {
        this.$emit('ready');
      }
    },

    'authentication.status' (value) {
      if (value == 'offline') {
        this.resetCache();
      } else if (value == 'online') {
        this.initCache();
      }
    },

    'selection.site' (value) {
      this.selection.systemMetric = null;
      this.selection.deviceData = null;
      this.selection.monitoringDate = null;
      this.selection.serverSession = null;

      this.cache.metrics = null;
      this.cache.eventTypes = null;
      this.cache.devices = null;

      if (value != null) {
        this.fetchMetrics();
        this.fetchEventTypes();
        this.fetchDevices();
      }
    },

    'selection.deviceData' () {
      this.selection.deviceSession = null;
      this.cache.deviceSessions = null;
    },

    'selection.serverSession' (value) {
      this.cache.serverSessionData = null;

      if (value != null) {
        this.fetchServerSessionData();
      }
    },

    'cache.metrics' (metrics) {
      this.cache.metricsMap = {};
      if (!metrics) {
        return;
      }
      for (let metric of metrics) {
        this.cache.metricsMap[metric._id] = {
          name: metric.name,
          type: metric.type,
          unit: metric.unit,
          isSystem: metric.isSystem,
        };
      }
    },

    'cache.eventTypes' (eventTypes) {
      this.cache.eventTypesMap = {};
      if (!eventTypes) {
        return;
      }
      for (let eventType of eventTypes) {
        this.cache.eventTypesMap[eventType._id] = {
          name: eventType.name,
          severity: eventType.severity,
          source: eventType.source,
        };
      }
    },

    'cache.devices' (devices) {
      this.cache.devicesMap = {};
      this.cache.devicesLocalMap = {};
      if (!devices) {
        return;
      }
      for (let device of devices) {
        this.cache.devicesMap[device._id] = device;
        this.cache.devicesLocalMap[device.localId] = device;
      }
    },
  },
  methods: {
    showMessage (message) {
      this.alertMessage = message;
    },

    hideMessage () {
      this.alertMessage = null;
    },

    resetCache () {
      this.cache.profile = null;
      this.cache.sites = null;
      this.selection.site = null;
    },

    initCache () {
      this.fetchProfile();
      this.fetchSites();
    },

    async fetchProfile () {
      this.pendingRequests++;
      return this.$ynapse.GET('/api/v1/profile').then((res) => {
        this.cache.profile = res.data;
      }).finally(() => {
        this.pendingRequests--;
      });
    },

    async fetchSites () {
      this.pendingRequests++;
      return this.$ynapse.GET('/api/v1/sites').then(res => {
        this.cache.sites = res.data;
        return res.data;
      }).finally(() => {
        this.pendingRequests--;
      });
    },

    selectSite (projectName, siteName) {
      let site = this.cache.sites.find(site => {
        return site.projectName == projectName && site.name == siteName;
      });
      if (site) {
        this.selection.site = {
          siteId: site.id,
          siteName: site.name,
          projectName: site.projectName,
          timeZone: site.timezone,
          servers: site.servers || [],
        };
        return true;
      } else {
        this.selection.site = null;
        return false;
      }
    },

    async fetchMetrics () {
      if (!this.selection.site) {
        return Promise.reject('No selected site for metrics');
      }
      this.pendingRequests++;
      return this.$ynapse.GET('/api/v1/metrics', {
        projectName: this.selection.site.projectName
      }).then(res => {
        this.cache.metrics = res.data;
        return res.data;
      }).finally(() => {
        this.pendingRequests--;
      });
    },

    selectMetric(metricName) {
      if (!this.selection.site) {
        throw new Error('No selected site for metric');
      }
      if (!this.cache.metrics) {
        throw new Error('Metrics not ready');
      }
      this.selection.systemMetric = this.cache.metrics.find(m => {
        return m.name == metricName;
      });
      return this.selection.systemMetric;
    },

    async fetchEventTypes () {
      if (!this.selection.site) {
        return Promise.reject('No selected site for event types');
      }
      this.pendingRequests++;
      return this.$ynapse.GET('/api/v1/eventtypes', {
        projectName: this.selection.site.projectName
      }).then(res => {
        this.cache.eventTypes = res.data;
        return res.data;
      }).finally(() => {
        this.pendingRequests--;
      });
    },

    async fetchDevices () {
      if (!this.selection.site) {
        return Promise.reject('No selected site for devices');
      }
      this.pendingRequests++;
      return this.$ynapse.GET('/api/v1/devices', {
        siteId: this.selection.site.siteId
      }).then(res => {
        this.cache.devices = res.data;
        return res.data;
      }).finally(() => {
        this.pendingRequests--;
      });
    },

    async fetchAndSelectDevice (deviceLocalId) {
      if (!this.selection.site) {
        return Promise.reject('No selected site for device');
      }
      this.pendingRequests++;
      return this.$ynapse.GET(API_ONE_DEVICE, {
        siteId: this.selection.site.siteId,
        serverId: this.selection.site.servers.length ? this.selection.site.servers[0].id : null,
        deviceLocalId,
      }).then(res => {
        this.selection.deviceData = res.data;
        return res.data;
      }).finally(() => {
        this.pendingRequests--;
      });
    },

    async fetchDeviceSessions (startDate, endDate) {
      if (!this.selection.deviceData) {
        return Promise.reject('No selected device for sessions');
      }
      this.pendingRequests++;
      return this.$ynapse.GET(API_ALL_DEVICE_SESSIONS, {
        deviceId: this.selection.deviceData.device._id,
        startDate,
        endDate,
      }).then(res => {
        this.cache.deviceSessions = res.data;
        return res.data;
      }).finally(() => {
        this.pendingRequests--;
      });
    },

    async selectDeviceSession (deviceSession) {
      if (deviceSession.startDate) {
        this.selection.deviceSession = deviceSession;
        return deviceSession;
      } else if (deviceSession.localId) {
        if (!this.selection.deviceData) {
          return Promise.reject('No selected device for session');
        }
        this.pendingRequests++;
        return this.$ynapse.GET('/api/v1/device/session', {
          deviceId: this.selection.deviceData.device._id,
          localId: deviceSession.localId
        }).then(res => {
          if (res.data.length > 1) {
            throw new Error(`Duplicate local ID ${deviceSession.localId}`);
          }
          this.selection.deviceSession = res.data[0];
          return this.selection.deviceSession;
        }).finally(() => {
          this.pendingRequests--;
        });
      }
    },

    async selectServerSession (serverSession) {
      if (serverSession.startDate) {
        this.selection.serverSession = serverSession;
        return serverSession;
      } else if (serverSession.localId) {
        if (!this.selection.site) {
          return Promise.reject('No selected site for session');
        }
        this.pendingRequests++;
        return this.$ynapse.GET('/api/v1/serverSessions', {
          siteId: this.selection.site.siteId,
          localId: serverSession.localId
        }).then(res => {
          if (res.data.length > 1) {
            throw new Error(`Duplicate local ID ${serverSession.localId}`);
          }
          this.selection.serverSession = res.data[0];
          return this.selection.serverSession;
        }).finally(() => {
          this.pendingRequests--;
        });
      }
    },

    async fetchServerSessionData () {
      if (!this.selection.site) {
        return Promise.reject('No selected site for session data');
      }
      if (!this.selection.serverSession) {
        return Promise.reject('No selected server session for data');
      }
      this.pendingRequests++;
      return this.$ynapse.GET('/api/v1/serverSession', {
        siteId: this.selection.site.siteId,
        serverSessionId: this.selection.serverSession._id,
        layoutId: this.selection.serverSession.layoutId,
      }).then(res => {
        this.cache.serverSessionData = res.data;
        return res.data;
      }).finally(() => {
        this.pendingRequests--;
      });
    },
  },
}
</script>
