<template>
  <div>
    <select
      v-model="selectedDateOption"
      class="date-filter-select"
      @change="updateSelectedDateOption"
    >
      <option
        v-for="option in options"
        :value="option.value"
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <div v-if="isDateRangeSelected">
      <DateTimePicker
        type="date"
        v-model="selectedFromDate"
        @input="handleDateRangeChange"
        class="date-time-width"
        placeholder="From"
      />
      <DateTimePicker
        type="date"
        v-model="selectedToDate"
        @input="handleDateRangeChange"
        class="date-time-width"
        placeholder="To"
      />
    </div>
  </div>
</template>

<script>
import DateTimePicker from '@/components/event-log/DateTimePicker.vue';

export default {
  name: 'DateTimeFilter',
  components: {
    DateTimePicker,
  },
  data() {
    return {
      options: [
        { label: 'Last hour', value: 'lastHour' },
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'Last 2 days', value: 'last2days' },
        { label: 'Last 5 days', value: 'last5days' },
        { label: 'Date Range', value: 'dateRange' },
      ],
      selectedDateOption: 'last5days',
      selectedFromDate: null,
      selectedToDate: null,
    };
  },

  computed: {
    isDateRangeSelected() {
      return this.selectedDateOption === 'dateRange';
    },
  },

  mounted() {
    const { startDate, endDate } = this.calculateDates();
    this.handleDateRangeSelected({ from: startDate, to: endDate });
  },

  methods: {
    updateSelectedDateOption() {
      this.$emit('date-option-updated', this.selectedDateOption);
      this.selectedFromDate = null;
      this.selectedToDate = null;
      this.handleDateRangeChange();
    },

    handleDateRangeChange() {
      if (this.isDateRangeSelected) {
        this.$emit('date-range-updated', {
          from: this.selectedFromDate,
          to: this.selectedToDate,
        });
      }
    },

    handleDateRangeSelected(dateRange) {
      this.selectedFromDate = dateRange.from;
      this.selectedToDate = dateRange.to;
      this.$emit('date-range-updated', {
        from: this.selectedFromDate,
        to: this.selectedToDate,
      });
    },

    calculateDates(selectedDateOption) {
      const timeZone = this.$provider.selection.site.timeZone;
      const now = new Date().toLocaleString('en-US', { timeZone });

      const startOfToday = new Date(now);
      startOfToday.setHours(0, 0, 0, 0);

      let startDate = new Date(startOfToday);
      let endDate = new Date(now);

      let lastHourStart;

      switch (selectedDateOption) {
        case 'lastHour':
          lastHourStart = new Date(now);
          lastHourStart.setHours(lastHourStart.getHours() - 1);
          startDate = lastHourStart;
          break;
        case 'today':
          break;
        case 'yesterday':
          startDate.setDate(startOfToday.getDate() - 1);
          startDate.setHours(0, 0, 0, 0);
          endDate.setDate(startOfToday.getDate() - 1);
          endDate.setHours(23, 59, 59, 999);
          break;
        case 'last2days':
          startDate.setDate(startOfToday.getDate() - 1);
          break;
        case 'last5days':
          startDate.setDate(startOfToday.getDate() - 4);
          break;
        default:
          startDate.setDate(startOfToday.getDate() - 4);
          break;
      }

      return { startDate, endDate };
    },
  },

  watch: {
    selectedDateOption(newVal) {
      if (!this.isDateRangeSelected) {
        const { startDate, endDate } = this.calculateDates(newVal);
        this.$emit('date-range-updated', { from: startDate, to: endDate });
      }
    },
  },
};
</script>

<style scoped>
.date-filter-select {
  background-color: var(--black);
  border: 1px solid var(--light-grey);
  color: var(--white);
  padding: 6px;
  margin: 10px;
  border-radius: 10px;
}

.date-time-width {
  width: 127px;
}
</style>
