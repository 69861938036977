<template>
  <div
    v-if="statusRoute && siteStatus"
    class="circle"
    :style="{
      backgroundColor: siteStatus.color,
      width: width,
      height: height,
    }"
  ></div>
</template>

<script>
import { SynapseWatcher } from 'synapse-vue';

export default {
  name: 'SiteStatusColor',

  mixins: [
    SynapseWatcher([
      {
        routeProp: 'statusRoute',
        dataName: 'siteStatus',
      },
    ]),
  ],

  props: {
    width: String,
    height: String,
  },
};
</script>

<style scoped>
.circle {
  border-radius: 50%;
}
</style>
