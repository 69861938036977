<template>
  <div style="position: relative">
    <input
      class="search"
      type="text"
      v-model="searchText"
      placeholder="  Search..."
      @input="search"
    />

    <div v-if="showDropdown" class="bg-panel rounded text-center dropdown">
      <div v-if="delay || busy || requestAgain">
        <b-spinner variant="light" class="my-2" />
      </div>
      <table v-else-if="searchResults.length" class="w-100">
        <tr
          v-for="(obj, index) in searchResults"
          :key="index"
          @click="selectItem"
        >
          <td v-if="obj.device">
            <router-link
              :to="{name: 'one-device', params: {deviceId: obj.device.localId}}"
              class="d-flex justify-content-between"
            >
              <span>
                {{ obj.device.type }}
                <span
                  v-for="(text, index) in highlightSearchResult(obj.device.name)"
                  :key="index" :class="{'search-highlight': text == searchText}"
                >
                  {{ text }}
                </span>
              </span>
              <span>{{ printActivity(obj.device) }}</span>
            </router-link>
          </td>
          <td v-else-if="obj.spectator">
            <router-link
              :to="{name: 'one-user', params: {sessionId: obj.spectator.sessionLocalId, userId: obj.spectator.name}}"
              class="d-flex justify-content-between"
            >
                <span>
                  Spectator
                  <span
                    v-for="(text, index) in highlightSearchResult(obj.spectator.name)"
                    :key="index" :class="{'search-highlight': text == searchText}"
                  >{{ text }}</span>
                </span>
              <span>{{ printActivity(obj.spectator) }}</span>
            </router-link>
          </td>
          <td v-else>Unexpected document</td>
        </tr>
      </table>
      <div v-else-if="searchText.length >= 3">No results</div>
      <div v-else-if="searchText.length > 0">
        Please type at least 3 characters to search
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue';

export default {
  name: 'SearchBar',
  components: { BSpinner },
  props: {
    selectedSite: Object,
  },
  data() {
    return {
      searchText: '',
      delay: null,
      busy: false,
      requestAgain: false,
      searchResults: [],
      showDropdown: true,
    };
  },

  methods: {
    search() {
      if (this.busy) {
        this.requestAgain = true;
        return;
      }
      this.searchResults = [];
      this.showDropdown = true;
      if (this.searchText.length >= 3) {
        if (this.delay) {
          clearTimeout(this.delay);
        }
        this.delay = setTimeout(() => {
          this.delay = null;
          this.query();
        }, 250);
      } else if (this.delay) {
        clearTimeout(this.delay);
        this.delay = null;
      }
    },

    query() {
      if (this.busy || this.searchText.length < 3) {
        return;
      }
      this.busy = true;
      this.searchResults = [];
      this.$ynapse
        .GET('/api/v1/search', {
          siteId: this.$provider.selection.site.siteId,
          searchText: this.searchText,
        })
        .then((res) => {
          this.searchResults = res.data;
        })
        .finally(() => {
          this.busy = false;
          if (this.requestAgain) {
            this.requestAgain = false;
            this.query();
          }
        });
    },

    highlightSearchResult(content) {
      let parts = content.split(this.searchText);
      for (let i = 0; i < Math.floor(parts.length / 2); i += 2) {
        parts.splice(i+1, 0, this.searchText);
      }
      return parts.filter(text => text != '');
    },

    printActivity(obj) {
      if (obj.active) {
        return 'Active';
      } else if (obj.lastDate) {
        let diff = (new Date() - new Date(obj.lastDate)) / 1000;
        let unit = 's';
        if (diff > 60) {
          diff /= 60;
          unit = 'm';
        }
        if (diff > 60) {
          diff /= 60;
          unit = 'h';
        }
        if (diff > 24) {
          diff /= 24;
          unit = 'd';
        }
        return `${diff.toFixed(0)}${unit} ago`;
      } else {
        return 'Unused';
      }
    },

    selectItem() {
      this.showDropdown = false;
      this.searchText = '';
    },

    handleClickOutside(event) {
      if (this.$el && !this.$el.contains(event.target) && this.showDropdown) {
        this.showDropdown = false;
        this.searchText = '';
      }
    },
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.search-highlight {
  color: var(--black);
  background-color: var(--orange);
  font-weight: bold;
}

.dropdown {
  position: absolute;
  right: 0px;
  width: 300%;
  top: 2rem;
  z-index: 99;
}
</style>
