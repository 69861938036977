<template>
  <div>
    <h2 class="m-4">System Metrics</h2>
    <div class="metric-category-wrapper">
      <MetricCategoryWrapper
        v-for="(metrics, categoryName) in metricsByCategory"
        :key="categoryName"
        :metrics="metrics"
        :categoryName="categoryName"
      />
    </div>
  </div>
</template>

<script>
import { SynapseWatcher } from 'synapse-vue';
import MetricCategoryWrapper from '@/components/metric-status/MetricCategoryWrapper.vue';

export default {
  name: 'SystemMetricsPage',

  components: {
    MetricCategoryWrapper,
  },

  mixins: [
    SynapseWatcher([
      {
        routeDynamic: 'metricsRoute',
        dataName: 'liveMetrics',
        isArray: true,
        entryIDKey: 'id',
        sorting: {
          key: 'id',
        },
      },
    ]),
  ],

  computed: {
    selectedSite() {
      return this.$provider.selection.site;
    },

    metricsRoute() {
      if (this.selectedSite && this.selectedSite.servers.length) {
        return `/sites/${this.selectedSite.servers[0].id}/metrics`
      }
      return null;
    },

    systemMetrics() {
      let metrics = [];
      // Get metrics from cache
      if (this.$provider.cache.metrics) {
        for (let metric of this.$provider.cache.metrics) {
          if (metric.isSystem) {
            let split = metric.name.split('.');
            let title = split.pop();
            let category = split.join('.');
            metrics.push({
              _id: metric._id,
              name: metric.name,
              category,
              title,
              type: metric.type,
              unit: metric.unit,
              route: null,
            });
          }
        }
      }
      // Add data from live metrics
      for (let metric of this.liveMetrics) {
        let baseMetric = metrics.find((m) => m.name == metric.id);
        if (baseMetric) {
          baseMetric.route = metric.route;
        }
      }
      return metrics;
    },

    categoryTree() {
      let tree = {
        name: '',
        children: {},
        count: 0,
        metrics: [],
      };
      for (let metric of this.systemMetrics) {
        let split = metric.category.split('.');
        let obj = tree;
        let path = [];
        for (var key of split) {
          path.push(key);
          obj = obj.children;
          if (!obj[key]) {
            obj[key] = {
              name: path.join('.'),
              children: {},
              count: 0,
              metrics: [],
            };
          }
          obj[key].count++;
          obj = obj[key];
        }
        obj.metrics.push(metric);
      }
      return tree;
    },

    metricsByCategory() {
      let obj = {};
      let metrics = this.unwindMetricTree(this.categoryTree);
      for (let metric of metrics) {
        if (!obj[metric.category]) {
          obj[metric.category] = [];
        }
        obj[metric.category].push(metric);
      }
      return obj;
    },
  },

  methods: {
    unwindMetricTree(tree) {
      let leaves = Object.values(tree.children).sort((a, b) => {
        if (a.count != b.count) {
          return a.count - b.count;
        } else if (a.name < b.name) {
          return -1;
        } else {
          return 1;
        }
      });
      let metrics = tree.metrics.slice();
      for (let child of leaves) {
        metrics = metrics.concat(this.unwindMetricTree(child));
      }
      return metrics;
    },
  },
};
</script>

<style scoped>
.metric-category-wrapper {
  display: flex;
  flex-wrap: wrap;
}
</style>
