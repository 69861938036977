var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('h2',{staticClass:"m-4"},[_vm._v("Dashboard")]),_c('section',{staticClass:"d-flex m-4"},[_c('DateTimePicker',{attrs:{"label":"From","type":"date","disabled":_vm.showLoading || _vm.noServerSession},on:{"change":_vm.convertFromDateToUTC},model:{value:(_vm.selectedFromDate),callback:function ($$v) {_vm.selectedFromDate=$$v},expression:"selectedFromDate"}}),_c('DateTimePicker',{staticClass:"ml-3",attrs:{"label":"To","type":"date","disabled":_vm.showLoading || _vm.noServerSession},on:{"change":_vm.convertToDateToUTC},model:{value:(_vm.selectedToDate),callback:function ($$v) {_vm.selectedToDate=$$v},expression:"selectedToDate"}}),_c('b-button',{staticClass:"button",attrs:{"variant":"dark","disabled":_vm.showLoading || _vm.noServerSession},on:{"click":_vm.handleSubmit}},[_vm._v("Submit")]),_c('HintModal',{ref:"hintModal",attrs:{"message":_vm.modalMessage},on:{"ok":_vm.createDashboardContent}})],1),_c('div',[(
        _vm.showLoading ||
        _vm.noServerSession ||
        _vm.showNoData ||
        _vm.showDashboardStillCreating ||
        _vm.showDatesBeyondToday ||
        _vm.showSelectedHint ||
        _vm.selectedFromDate === null ||
        _vm.selectedToDate === null
      )?_c('HintMessage',{attrs:{"message":_vm.getHintMessage,"isLoading":_vm.showLoading,"type":_vm.showNoData ? 'no-data' : ''}}):_vm._e(),(_vm.apiData && _vm.apiData.status === 'completed' && _vm.apiData.content !== null)?_c('div',_vm._l((_vm.dashboardTemplate),function(items,sectionName){return _c('div',{key:sectionName,staticClass:"m-4"},[_c('h2',[_vm._v(_vm._s(sectionName))]),_c('div',{staticClass:"section-container"},_vm._l((items),function(item,index){return _c('div',{key:index,class:item.type === 'label'
                ? 'label-table-group'
                : item.type === 'pieChart'
                ? 'section-item pie-chart-item'
                : 'section-item'},[_c(_vm.getComponentName(item.type),_vm._b({tag:"component",attrs:{"title":sectionName,"siteId":_vm.siteId,"startDate":_vm.utcFromDate,"endDate":_vm.utcToDate,"apiData":_vm.apiData}},'component',item.content,false))],1)}),0)])}),0):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }