<template>
  <div class="metric-status" @click="handleClick">
    <div class="metric-title">{{ metric.title }}</div>
    <div class="metric-container">
      <div class="metric-value">
        {{
          lastStatus
            ? lastStatus.text || lastStatus.value || 'none'
            : 'No recent data'
        }}
      </div>
      <div class="metric-chart">
        <ChartLine
          :data="computedChartData.data"
          :options="computedChartData.options"
        />
      </div>
    </div>
    <div class="metric-info" v-if="lastStatus && lastStatus.value != null">
      {{ lastStatus.value }}{{ metric.unit || '' }}
    </div>
  </div>
</template>

<script>
import { SynapseWatcher } from 'synapse-vue';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line as ChartLine } from 'vue-chartjs';
import { enUS } from 'date-fns/locale';
import { toZonedTime } from 'date-fns-tz';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export default {
  name: 'MetricStatus',
  components: {
    ChartLine,
  },

  props: {
    categoryName: String,
    metric: Object,
    graphContent: Object,
  },

  mixins: [
    SynapseWatcher([
      {
        routeProp: 'route',
        dataName: 'liveData',
        isArray: true,
        sorting: {
          key: 'time',
          reverse: true,
        },
      },
    ]),
  ],

  computed: {
    metricData() {
      return this.liveData || this.graphContent;
    },

    lastStatus() {
      return this.metricData[0];
    },

    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        parsing: {
          xAxisKey: 'time',
          yAxisKey: 'value',
        },
        animations: {
          numbers: false, // TEMP fix for bad animations
        },

        interaction: {
          intersect: false,
          mode: 'nearest',
        },

        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title(context) {
                if (context[0].raw.isNow) {
                  return 'Now';
                } else {
                  return new Date(context[0].parsed.x).toLocaleTimeString();
                }
              },
            },
          },
        },
        scales: {
          x: {
            display: false,
            type: 'time',
            time: {
              minUnit: 'minute',
            },
          },
          y: {
            display: false,
            min: this.metric.type == 'boolean' ? 0 : null,
            max: this.metric.type == 'boolean' ? 10 : null,
          },
        },
        adapters: {
          date: {
            locale: enUS,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
      };
    },

    computedChartData() {
      if (this.metricData.length === 0) {
        return {
          data: {
            labels: [],
            datasets: [{ data: [] }],
          },
          options: this.chartOptions,
        };
      }

      const validData = this.metricData.slice(0, 20);
      const formattedData = validData
        .map((m) => {
          if (this.metric.type == 'string') {
            return {
              value: m.value === null ? 1 : m.value, // it's value: 1 before
              time: this.formatTZ(m.time),
              color: m.color,
            };
          } else if (this.metric.type == 'boolean') {
            return {
              value: m.value == null ? 0 : m.value ? 5 : 1,
              time: this.formatTZ(m.time),
              color: m.color,
            };
          } else {
            return {
              value: m.value,
              time: this.formatTZ(m.time),
              color: m.color,
            };
          }
        })
        .reverse();

      const firstData = formattedData[0];
      const lastData = formattedData[formattedData.length - 1];
      // Show the current value at 10% of the graph's size
      formattedData.push({
        value: lastData.value,
        time: lastData.time.getTime() + 0.1 * (lastData.time - firstData.time),
        color: lastData.color,
        isNow: true,
      });

      if (this.metric.type === 'string') {
        const chartDataString = {
          labels: formattedData.map((m) => m.time),
          datasets: [
            {
              data: formattedData,
              segment: {
                borderColor: (ctx) => {
                  return ctx.p1.raw.color;
                },
              },
              type: 'line',
              borderWidth: 2,
            },
          ],
        };
        return {
          data: chartDataString,
          options: this.chartOptions,
        };
      } else if (this.metric.type === 'boolean') {
        const chartDataBool = {
          labels: formattedData.map((m) => m.time),
          datasets: [
            {
              data: formattedData,
              borderWidth: 0,
              segment: {
                backgroundColor: (ctx) => {
                  return ctx.p1.raw.color;
                },
              },
              stepped: 'after',
              fill: true,
            },
          ],
        };

        return {
          data: chartDataBool,
          options: this.chartOptions,
        };
      } else {
        return {
          data: {
            labels: formattedData.map((m) => m.time),
            datasets: [
              {
                data: formattedData,
                segment: {
                  borderColor: (ctx) => {
                    return ctx.p1.raw.color;
                  },
                },
                type: 'line',
                borderWidth: 2,
              },
            ],
          },
          options: this.chartOptions,
        };
      }
    },
  },

  methods: {
    formatTZ(time) {
      return toZonedTime(time, this.$provider.selection.site.timeZone);
    },

    handleClick() {
      this.$router.push({
        name: 'one-metric',
        params: {
          metricName: this.metric.name,
        },
      });

      this.$provider.selection.systemMetric = this.metric;
    },
  },
};
</script>

<style scoped>
.metric-status {
  max-width: 290px;
  width: 100%;
  padding: 6px;
  margin: 20px 0 20px 20px;
  border-radius: 10px;
  background-color: var(--container);
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    box-shadow: 5px 5px 10px var(--light-grey);
    cursor: pointer;
  }
}

.metric-title {
  margin: 10px;
  color: var(--header);
}

.metric-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 10px;
  font-size: 20px;
  color: #bfe7bd;
  justify-content: space-between;
}

.metric-value {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.metric-chart {
  width: 100px;
  height: 60px;
}

.metric-info {
  margin: 10px;
  color: var(--secondary);
}
</style>
