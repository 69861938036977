<template>
  <div class="filter-search">
    <label class="mr-1 label">{{ label }} </label>
    <button class="filter-input" @click="showDropdown = !showDropdown">
      <span>{{ filter }}</span>
      <font-awesome-icon :icon="iconName"/>
    </button>
    <div v-if="showDropdown" class="bg-panel rounded dropdown">
      <div
        class="d-flex align-items-center mb-1"
        v-for="(condition, index) in conditions"
        :key="`${index}_${updateCount}`"
      >
        <FilterCondition
          :condition="condition"
          @change="updateCondition(index, $event)"
        />
        <div class="cursor-pointer ml-2 h-100" @click="deleteCondition(index)">
          <font-awesome-icon :icon="['fas', 'close']" />
        </div>
      </div>
      <span
        v-if="conditions.length == 0 || conditionsValid"
        class="cursor-pointer text-link"
        @click="conditions.push({})"
      >
        Add a condition
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FilterCondition from './FilterCondition.vue';

export default {
  name: 'FilterSearch',
  components: {
    FontAwesomeIcon,
    FilterCondition,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: [],
  },
  data() {
    return {
      conditions: this.value,
      updateCount: 0,
      filter: '',
      showDropdown: false,
    };
  },
  computed: {
    iconName() {
      return this.showDropdown ? ['fas', 'angle-up'] : ['fas', 'angle-down'];
    },

    conditionsValid() {
      return this.conditions.every(c => c.isValid);
    }
  },

  mounted () {
    this.updateConditions(false);
  },

  methods: {
    updateCondition (index, data) {
      Vue.set(this.conditions, index, data);
      this.updateConditions(true);
    },

    deleteCondition (index) {
      this.conditions.splice(index, 1);
      this.updateCount++; // Force the view to update again
      this.updateConditions(true);
    },

    updateConditions (emit) {
      let filters = []
      for (let condition of this.conditions) {
        if (condition.isValid) {
          filters.push(`${condition.field}${condition.operator}${condition.value}`);
        }
      }
      this.filter = filters.join(', ');

      if (emit) {
        this.$emit('input', this.conditions);
      }
    },
  },
};
</script>

<style scoped>
.filter-search {
  display: flex;
  align-items: center;
  position: relative;
}

.label {
  font-size: 14px;
}

.filter-input {
  width: 100%;
  min-width: 180px;
  height: 2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: var(--black);
  color: var(--white);
  border: 1px solid var(--borders);
  border-radius: 4px;
  font-size: 12px;
}

.dropdown {
  padding: 5px;
  position: absolute;
  right: 0px;
  top: 2rem;
  /* left: 50%;
  margin-right: -50%;
  transform: translate(-50%, 0); */
  width: 150%;
  z-index: 99;

  border: 1px solid var(--borders);
}
</style>
