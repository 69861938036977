<template>
  <div v-if="apiData && apiData.content" class="bar-chart">
    <Bar :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);
import { Bar } from 'vue-chartjs';

export default {
  name: 'DashboardBarChart',
  components: {
    Bar,
  },
  props: {
    values: Object,
    colors: Array,
    apiData: Object,
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        indexAxis: 'y',
        scales: {
          x: {
            ticks: {
              stepSize: 2000,
              color: '#fff',
            },
          },
          y: {
            ticks: {
              color: '#fff',
            },
          },
        },
        plugins: {
          legend: {
            display: false,
            labels: {
              usePointStyle: true,
            },
          },
        },
      },
    };
  },

  methods: {
    evaluateExpression(value) {
      if (value.startsWith('#') && value.endsWith('#')) {
        const expression = value.slice(1, -1);
        return this.apiData.content[expression] || 0;
      }
      return value;
    },

    generateChartData() {
      if (!this.apiData || !this.apiData.content) {
        return;
      }
      const labels = Object.keys(this.values);
      const data = labels.map((label) =>
        this.evaluateExpression(this.values[label]),
      );

      this.chartData = {
        labels,
        datasets: [
          {
            data,
            backgroundColor: this.colors,
            borderWidth: 0,
            barThickness: 8,
          },
        ],
      };
    },
  },

  watch: {
    apiData: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.content) {
          this.generateChartData();
        }
      },
    },
  },
};
</script>

<style scoped>
.bar-chart {
  width: 600px;
}
</style>
