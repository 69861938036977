<template>
  <div>
    <b-alert
      :show="alertMessage != null"
      dismissible
      fade
      @dismissed="alertMessage = null"
    >
      {{alertMessage}}
    </b-alert>
  </div>
</template>

<script>
import Vue from 'vue';
import { BAlert } from 'bootstrap-vue'
import { mapState, mapMutations } from 'vuex';

export default {
  components: {
    BAlert
  },
  created () {
    Vue.prototype.$auth = this;
  },
  data () {
    return {
      alertMessage: null,

      authVerified: false,
    }
  },
  computed: {
    ...mapState(['authentication']),

    isInLogin () {
      return this.$route.name == 'login';
    },
  },
  watch: {
    authVerified(value) {
      if (value) {
        this.$emit('auth-verified');
      } else {
        this.$emit('auth-lost');
      }
    },
  },
  mounted () {
    // Watch connectivity changes
    this.$ynapse.connectivity.watch((isRegistered, isConnected) => {
      if (!isRegistered) {
        if (isConnected) {
          if (!this.authVerified) {
            // Either a first connection attempt, or a reconnection
            let storedToken = this.authentication.token;
            if (storedToken) {
              this.$ynapse.socket.send(`reauth;${storedToken}`);
            } else {
              console.log('???');
            }
          }
        } else {
          if (!this.isInLogin) {
            this.authVerified = false;
            this.attemptReconnection();
          }
        }
      } else {
        this.alertMessage = null;
      }
    });

    // Watch server identity acknowledgements
    this.$ynapse.routing.addListener('ack', (params) => {
      if (params[0] == '0') {
        // Failure
        this.$ynapse.socket.disconnect();
        if (this.isInLogin) {
          this.setAuthOffline(params[1] || 'Unknown error');
        } else {
          this.alertMessage = params[1] || 'Unknown error';
          this.setAuthOffline();
          this.redirectToLogin();
        }
      } else {
        // Logged in
        this.setAuthToken(params[1]);
        this.setAuthOnline();
      }
      if (!this.authVerified) {
        this.authVerified = true;
      }
    });

    // Before any navigation occurs, ensure we are properly connected, or go to login
    this.$router.beforeEach((to, from, next) => {
      if (this.$ynapse.connectivity.isOnline() || to.name == 'login') {
        next();
      } else {
        let targetRoute = { name: 'login' };
        if (to.name != 'site-overview' && to.name != 'login') {
          targetRoute.query = {
            redirectTo: to.path,
            query: to.query ? JSON.stringify(to.query) : undefined,
          };
        }
        next(targetRoute);
      }
    });

    // Initial connection attempt
    if (!this.$ynapse.connectivity.isOnline()) {
      this.attemptReconnection();
    }
  },
  methods: {
    ...mapMutations([
      'setAuthToken',
      'setAuthOffline',
      'setAuthConnecting',
      'setAuthOnline',
    ]),

    login(username, password) {
      if (this.authentication.status == 'connecting') {
        return;
      }

      this.setAuthConnecting();
      this.$ynapse.socket.connect((success) => {
        if (success) {
          // TODO: Handle response timeout?
          this.$ynapse.socket.send(`auth;${username};${password}`);
        } else {
          this.setAuthOffline('Connection failed');
        }
      });
    },

    attemptReconnection() {
      let storedToken = this.authentication.token;
      if (storedToken) {
        // Found a stored token, attempt to resume previous session
        this.setAuthConnecting();
        this.$ynapse.socket.connect((success) => {
          // On success, the socket watcher will behave appropriately, so we only need to watch for failure here
          if (!success) {
            // Server is unreachable
            let redirected = this.redirectToLogin();
            if (redirected) {
              this.alertMessage = 'Connection with the server lost, please login again';
            } else {
              this.alertMessage = 'Failed to connect to the server';
            }
            this.setAuthToken(null); // Clear the existing token to avoid an infinite reconnection loop
            this.setAuthOffline();
            this.authVerified = true;
          }
        });
      } else {
        // No saved token, go straight to login
        this.redirectToLogin();
        this.setAuthOffline();
        this.authVerified = true;
      }
    },

    redirectToLogin() {
      if (this.isInLogin) {
        return false;
      }

      let targetRoute = { name: 'login' };
      if (this.$route.path != '/') {
        targetRoute.query = {
          redirectTo: this.$route.path,
          query: this.$route.query ? JSON.stringify(this.$route.query) : undefined,
        };
      }
      this.$router.push(targetRoute);
      return true;
    },

    logout() {
      this.$ynapse.socket.send('logout');
      this.$ynapse.socket.disconnect();
      this.setAuthToken(null);
      this.setAuthOffline();
      this.$router.push({ name: 'login' });
      this.alertMessage = 'You have logged out successfully';
    },
  },
}
</script>
