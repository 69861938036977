<template>
  <div class="select d-flex">
    <label
      class="select-label"
      :style="{ 'margin-right': computedLabelMarginRight }"
      >{{ selectType }}</label
    >
    <v-select
      multiple
      v-model="selected"
      :options="processedOptions"
      class="w-100"
      :placeholder="placeholder"
      :style="{ 'min-width': computedMinWidth }"
      :selectable="selectable"
      @input="updateValue"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'SelectInput',
  components: {
    vSelect,
  },

  props: {
    marginRight: {
      type: String,
      default: '10px',
    },
    selectType: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: String,
    minWidth: String,
    selectable: Function,
  },

  data() {
    return {
      selected: this.value.map((entry) => this.processEntry(entry)),
    };
  },

  computed: {
    processedOptions() {
      let array = [];
      for (let option of this.options) {
        array.push(this.processEntry(option));
      }
      return array;
    },

    computedMinWidth() {
      return this.minWidth || '200px';
    },

    computedLabelMarginRight() {
      return this.marginRight;
    },
  },

  watch: {
    value(newVal) {
      this.selected = newVal;
    },
  },

  methods: {
    processEntry(value) {
      if (typeof value == 'string' || value.label) {
        return value;
      } else {
        // Construct a label from the data we have
        let obj = Object.assign({}, value);
        obj.label = obj.details ? `${obj.name} (${obj.details})` : obj.name;
        return obj;
      }
    },

    updateValue(val) {
      // CHECK FOR DUPLICATE
      // IF OK:
      this.$emit('input', val);
      // ELSE:
      // MODIFY this.selected TO REMOVE DUPLICATE
    },
  },
};
</script>

<style scoped>
.select {
  align-items: baseline;
  font-size: 12px;
}

.select-label {
  font-size: 14px;
}
</style>
