<template>
  <button
    class="control-button"
    :class="{
      'control-button-enabled': isEnabled,
    }"
    @click="(ev) => $emit('click', ev)"
  >
    <font-awesome-icon v-if="icon != null" :icon="['fas', icon]" />
    <slot />
  </button>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fas);

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    isEnabled: Boolean,
    icon: String,
  },
};
</script>

<style scoped>
.control-button {
  background-color: var(--black);
  color: var(--white);
  border-radius: 4px;
  margin-right: 6px;
  height: 30px;
}

.control-button-enabled {
  /* background-color: var(--primary); */
  color: var(--primary);
}
</style>
