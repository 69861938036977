<template>
  <div class="d-flex justify-content-between mx-4">
    <section class="mr-4">
      <div class="text-subheader mb-2">Device Stats</div>
      <table class="device-table" v-if="deviceData">
        <tr>
          <th>Status</th>
          <td>{{ deviceData.lastSeen == 'now' ? 'Online' : 'Offline' }}</td>
        </tr>
        <tr>
          <th>First seen</th>
          <td>
            {{
              new Date(deviceData.device.firstDate).toLocaleString('en-US', {
                timeZone,
              })
            }}
          </td>
        </tr>
        <tr>
          <th>Last seen</th>
          <td>
            {{
              Date.parse(deviceData.lastSeen)
                ? new Date(deviceData.lastSeen).toLocaleString('en-US', {
                    timeZone,
                  })
                : deviceData.lastSeen
            }}
          </td>
        </tr>
        <tr>
          <th>Number of uses</th>
          <td>{{deviceData.device.stats.totalRuns}}</td>
        </tr>
        <tr>
          <th>Runs without incidents</th>
          <td>
            {{
              (deviceData.device.stats.runsWithoutIncident * 100).toFixed(2)
            }}%
          </td>
        </tr>
      </table>
    </section>
    <section>
      <div class="text-subheader mb-2">Device Data</div>
      <table v-if="deviceData && deviceData.misc" class="device-table">
        <tr v-for="key in deviceProperties" :key="key">
          <th>{{ key }}</th>
          <td>{{ deviceData.misc[key] }}</td>
        </tr>
      </table>
      <table v-else-if="deviceData" class="device-table">
        <tr>
          <th>MAC</th>
          <td>{{ deviceData.device.macAddress }}</td>
        </tr>
      </table>
    </section>
  </div>
</template>

<script>
export default {
  name: 'OneDeviceInfo',

  props: {
    deviceData: Object,
    timeZone: String,
  },

  computed: {
    deviceProperties() {
      let props = [];
      if (this.deviceData && this.deviceData.misc) {
        for (let key in this.deviceData.misc) {
          if (
            key != 'id' &&
            key != 'name' &&
            key != 'type' &&
            key != 'lastSeen' &&
            key != 'isOnline' &&
            key != 'token'
          ) {
            props.push(key);
          }
        }
      }
      return props;
    },
  },
};
</script>

<style scoped>
.device-table {
  font-size: 12px;
}
</style>
