<template>
  <section>
    <div class="text-subheader mb-2">{{ notesHeader }}</div>

    <div class="d-flex">
      <textarea
        type="text"
        placeholder="Enter a comment here"
        class="input-note w-100"
        v-model="noteContent"
        @keyup.enter="submitNote"
      ></textarea>

      <div class="inline-block button-container">
        <b-button @click.prevent="submitNote" class="button" variant="dark">
          {{ editMode ? 'Save' : 'Submit' }}
        </b-button>

        <b-button
          v-if="editMode"
          @click.prevent="cancelEdit"
          class="button mt-1"
          variant="light"
        >
          Cancel
        </b-button>
      </div>
    </div>

    <div class="comment-wrapper">
      <div v-for="comment in allComments" :key="comment._id" class="hint-area">
        {{ comment.timeElapsed }} ago, {{ comment.accountName }} wrote:
        <font-awesome-icon
          v-if="comment.accountId === profileId"
          :icon="['fas', 'trash']"
          @click="openModal(comment)"
          class="icon trash-icon mx-2"
          :class="{ disabled: editMode }"
        />
        <font-awesome-icon
          v-if="comment.accountId === profileId"
          :icon="['fas', 'pen']"
          @click="startEditing(comment)"
          class="icon edit-icon"
          :class="{ disabled: editMode }"
        />
        <span class="message">« {{ comment.text }} » </span>
      </div>
    </div>

    <HintModal
      ref="hintModal"
      @ok="deleteComment"
      :message="modalMessage"
    ></HintModal>
  </section>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import HintModal from './HintModal.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { updateTimeElapsed } from '@/helper/utils';

library.add(fas);

export default {
  name: 'CommentInput',
  components: { BButton, FontAwesomeIcon, HintModal },

  props: {
    notesHeader: String,
    resourceId: String,
    resourceType: String,
  },

  data() {
    const { profile } = this.$provider?.cache || {};
    const { id: profileId, name: profileName } = profile || {};

    return {
      noteContent: '',
      allComments: null,
      selectedComment: null,
      profileId,
      profileName,
      timeElapsed: '',
      modalMessage: 'Are you sure you want to delete this comment?',
      isEditing: false,
      editMode: false,
    };
  },

  watch: {
    resourceId(newValue) {
      this.fetchAllComments(newValue);
    },
  },

  computed: {
    requestData() {
      const { noteContent, resourceId, resourceType } = this;
      const data = {
        text: noteContent.trim(),
      };
      data[`${resourceType}Id`] = resourceId;
      return data;
    },
  },

  methods: {
    async submitNote() {
      if (!this.noteContent.trim()) return;
      const now = new Date();
      let endpoint;

      if (this.editMode) {
        endpoint = `/api/v1/${
          this.resourceType === 'device'
            ? 'edit-device-comment'
            : 'edit-experience-comment'
        }/${this.selectedComment._id}`;
      } else {
        endpoint = `/api/v1/${
          this.resourceType === 'device'
            ? 'add-device-comment'
            : 'add-experience-comment'
        }`;
      }

      try {
        if (this.editMode) {
          await this.$ynapse.HTTP(endpoint, {
            method: 'put',
            data: {
              text: this.noteContent.trim(),
              latestUpdate: now,
            },
          });

          this.selectedComment.text = this.noteContent.trim();
          this.editMode = false;
        } else {
          await this.$ynapse.POST(endpoint, this.requestData);
        }

        this.fetchAllComments(this.resourceId);
        this.noteContent = '';
      } catch (error) {
        console.error('Error:', error);
      }
    },

    openModal(comment) {
      if (this.editMode) return;
      this.selectedComment = comment;
      this.$refs.hintModal.open();
    },

    async deleteComment() {
      const endpoint = `/api/v1/delete-${
        this.resourceType === 'device' ? 'device' : 'experience'
      }-comment/${this.selectedComment._id}`;

      try {
        await this.$ynapse.HTTP(endpoint, {
          method: 'delete',
        });

        this.selectedComment = null;
        this.timeElapsed = '';
        this.fetchAllComments(this.resourceId);
      } catch (error) {
        console.error('Error deleting comment:', error);
      }
    },

    async fetchAllComments(resourceId) {
      if (!resourceId) return;

      const endpoint = `/api/v1/all-${
        this.resourceType === 'device' ? 'device' : 'experience'
      }-comments/${resourceId}`;

      try {
        const res = await this.$ynapse.GET(endpoint, {});
        if (res.status === 200 && res.data.length > 0) {
          this.allComments = res.data.map((comment) => ({
            ...comment,
            timeElapsed: updateTimeElapsed(
              comment.latestUpdate ? comment.latestUpdate : comment.date,
            ),
          }));
        } else {
          this.allComments = [];
        }
      } catch (error) {
        console.error('Error fetching comments:', error);
        this.allComments = [];
      }
    },

    startEditing(comment) {
      this.editMode = true;
      this.selectedComment = comment;
      this.noteContent = this.selectedComment.text;
    },

    cancelEdit() {
      this.editMode = false;
      this.selectedComment = null;
      this.noteContent = '';
    },
  },

  mounted() {
    this.fetchAllComments(this.resourceId);
    this.timeElapsedInterval = setInterval(() => {
      this.allComments.forEach((comment) => {
        comment.timeElapsed = updateTimeElapsed(comment.date);
      });
    }, 60000);
  },

  beforeDestroy() {
    clearInterval(this.timeElapsedInterval);
  },
};
</script>

<style scoped>
.device-note-desc {
  font-size: 12px;
  color: var(--light-grey);
}

.input-note {
  border: 1px solid var(--light-grey);
  border-radius: 4px;
  background-color: var(--dark-grey);
  height: 86px;
  width: 240px;
  padding: 10px;
  line-height: 1.5;
  position: relative;
  color: var(--white);
  resize: none;
}

.input-note:focus {
  outline: none;
  color: var(--white);
}

.comment-wrapper {
  float: right;
  height: 250px;
  overflow: auto;
  margin-top: 10px;
}

.hint-area {
  font-size: 14px;
  width: 360px;
  margin-bottom: 20px;
}

.message {
  display: block;
  word-wrap: break-word;
  max-height: 180px;
  overflow-y: auto;
}

.button-container {
  /* width: 80px; */
  flex-direction: column;
  display: flex;
}

.button {
  /* width: 54px; */
  margin-left: 4px;
  font-size: 12px;

  /* width: 56px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon:hover {
  cursor: pointer;
  opacity: 0.8;
}

.trash-icon {
  color: var(--danger);
}

.edit-icon {
  color: var(--warning);
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>
