<template>
  <div :class="{ 'radio-picker': true, 'disabled-style': isDisabled }">
    <label class="radio-label">{{ label }}: </label>
    <b-form-radio-group
      v-model="selected"
      :options="options"
      :disabled="isDisabled"
      class="radio-group"
    ></b-form-radio-group>
  </div>
</template>

<script>
import { BFormRadioGroup } from 'bootstrap-vue';

export default {
  name: 'RadioPicker',
  components: {
    BFormRadioGroup,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  watch: {
    selected(newVal) {
      if (newVal === null || newVal === '') {
        this.$emit('input', null);
      } else {
        this.$emit('input', newVal);
      }
    },
    isDisabled(newVal) {
      if (newVal) {
        this.selected = null;
      } else {
        this.selected = 'none';
      }
    },
  },
};
</script>

<style>
.radio-label {
  font-size: 14px;
  margin-right: 10px;
}

.radio-picker {
  display: flex;
}

.radio-group {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.disabled-style {
  color: var(--light-grey);
}

/* with <style scoped> the three below css won't apply */

.custom-control-input:disabled ~ .custom-control-label {
  color: darkgrey;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: darkgrey;
  border-color: darkgrey;
}

/* radio picker outer circle unchecked */
.radio-picker .custom-control-input ~ .custom-control-label::before {
  width: 12px;
  height: 12px;
  align-items: center;
}

/* radio-picker outer circle checked mode */
.radio-picker .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #2094fa;
  border-color: #2094fa;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
</style>
